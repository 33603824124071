import { TextField, IconButton, Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useState } from "react";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const ImageInput = ({
  image,
  onChange,
  removeImage,
  helperText,
  placeholder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPreview, setPreviewOpen] = useState(false);
  const [placementPreview, setPlacementPreview] = useState();
  const { t } = useCustomTranslation("Créer un emplacement");

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPreviewOpen((prev) => placementPreview !== newPlacement || !prev);
    setPlacementPreview(newPlacement);
  };

  return (
    <div className="mb-4 mt-4 w-full flex space-x-2">
      {image ? (
        <div className="">
          <IconButton onClick={handleClick("right")}>
            <ImageSearchIcon />
          </IconButton>
          <Popper
            sx={{ zIndex: 1200 }}
            open={openPreview}
            anchorEl={anchorEl}
            placement={placementPreview}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="w-[450px] h-[300px] border border-black p-4 flex-1 flex flex-col">
                  <div>Appeçu de l'image</div>
                  <div className="flex flex-1">
                    <img
                      className="w-[400px] h-[250px] object-contain"
                      src={URL.createObjectURL(image)}
                    />
                  </div>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 space-x-2">
          <div className="border outline-gray-500 outline-dashed rounded-md w-full">
            <TextField
              placeholder={image ? image.name : placeholder}
              variant="standard"
              type="text"
              fullWidth
              sx={{
                input: { cursor: "pointer" },
              }}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: (
                  <IconButton component="label">
                    <FileUploadOutlinedIcon />
                    <input
                      styles={{ display: "none" }}
                      type="file"
                      hidden
                      onChange={onChange}
                      id="imageInput"
                    />
                  </IconButton>
                ),
              }}
            />
          </div>
          <Button
            variant="contained"
            color="error"
            disabled={image ? false : true}
            onClick={removeImage}
          >
            {t("create_location_deleteimage_button")}
          </Button>
        </div>
        <label className="text-sm text-gray-500">{helperText}</label>
      </div>
    </div>
  );
};

export default ImageInput;
