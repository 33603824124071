import { Button } from "@mui/material";
import { useApiClientQuery } from "../../hooks/useApiClient";
import { getPageFromName } from "../../route_utils";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import {
  dateTimeReadableSimple,
  currencyFormatter,
  formatDateTime,
} from "../../utils";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import {
  PAYMENT_TYPE,
  PROMISE_TYPE,
  STATUS_TYPE,
} from "../../enums/PromiseEnums";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import { Dialog, DialogTitle, DialogContent, Chip } from "@mui/material";
import CreatePromise from "./CreatePromise";
import PredefinedPeriods from "../../widgets/CustomDatePicker/PredefinedPeriod";
import dayjs from "dayjs";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

export const PromisesList = () => {
  const navigate = useNavigate();
  const { t } = useCustomTranslation("Promises List");
  const [openModal, setOpenModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const authContext = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const [tableFilter, setTableFilter] = useState({
    organization_id: authContext.authContext.accessToken.organization_id,
    email: "",
    name: "",
    phone: "",
    created_at: "",
    campaign_name: "",
    payment_type: "",
    promise_type: "",
    promise_type: "",
    given_amount: "",
    promise_amount: "",
    status: "",
    start_date: dayjs(new Date()),
    end_date: dayjs(new Date()),
    start_time: dayjs().startOf("day").format("HH:mm"),
    end_time: dayjs().endOf("day").format("HH:mm"),
  });

  const columns = [
    {
      field: "email",
      headerName: t("email"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "name",
      headerName: t("name"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "phone",
      headerName: t("phone"),
      sortable: true,
      numberSort: true,
    },
    {
      field: "created_at",
      headerName: t("created_at"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "campaign_name",
      headerName: t("campaign_name"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "payment_type",
      headerName: t("payment_type"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "promise_type",
      headerName: t("promise_type"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "given_amount",
      headerName: t("given_amount"),
      sortable: true,
      numberSort: true,
    },
    {
      field: "promise_amount",
      headerName: t("promise_amount"),
      sortable: true,
      numberSort: true,
    },
    {
      field: "status",
      headerName: t("status"),
      sortable: false,
      numberSort: false,
      renderCell: (params) => {
        let statusLabel;
        let chipColor;
        let chipVariant = "contained";
        let customStyles = {};

        switch (params.value) {
          case STATUS_TYPE.IN_PROGRESS:
            statusLabel = t("in_progress");
            chipColor = "primary";
            break;
          case STATUS_TYPE.ABANDONED:
            statusLabel = t("abandoned");
            chipColor = "error";
            chipVariant = "outlined";
            break;
          case STATUS_TYPE.CLOSED:
            statusLabel = t("closed");
            chipColor = "error";
            break;
          case STATUS_TYPE.OPEN:
            statusLabel = t("open");
            chipColor = "success";
            break;
          default:
            statusLabel = t("unknown_status");
            chipColor = "default";
        }

        return (
          <Chip label={statusLabel} color={chipColor} variant={chipVariant} />
        );
      },
    },
  ];

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setSelectedPeriod(value);
  };

  const setpersonalizedDates = (startDate, endDate, starttime, endtime) => {
    setTableFilter({
      ...tableFilter,
      start_date: startDate,
      end_date: endDate,
      start_time: starttime.format("HH:mm"),
      end_time: endtime.format("HH:mm"),
    });
  };

  const filters = {
    email: (
      <CustomTextField
        value={tableFilter.email || ""}
        onChange={(e) => changeTableFilter("email", e.target.value)}
        clearFilter={() => clearTableFilter("email")}
      />
    ),
    name: (
      <CustomTextField
        value={tableFilter.name || ""}
        onChange={(e) => changeTableFilter("name", e.target.value)}
        clearFilter={() => clearTableFilter("name")}
      />
    ),
    phone: (
      <CustomTextField
        value={tableFilter.phone || ""}
        onChange={(e) => changeTableFilter("phone", e.target.value)}
        clearFilter={() => clearTableFilter("phone")}
      />
    ),
    created_at: (
      <PredefinedPeriods
        selectedPeriod={selectedPeriod}
        handlePeriodChange={handlePeriodChange}
        setSelectedPeriod={setSelectedPeriod}
        setpersonalizedDates={setpersonalizedDates}
      />
    ),
    campaign_name: (
      <CustomTextField
        value={tableFilter.campaign_name || ""}
        onChange={(e) => changeTableFilter("campaign_name", e.target.value)}
        clearFilter={() => clearTableFilter("campaign_name")}
      />
    ),
    promise_type: (
      <CustomSelect
        value={tableFilter.promise_type}
        onChange={(e) => {
          changeTableFilter("promise_type", e.target.value);
        }}
        options={[
          { value: "", label: t("all_text") },
          {
            value: PROMISE_TYPE.ONE_TIME_PAYMENT,
            label: t("one_time_payment"),
          },
          {
            value: PROMISE_TYPE.SPLIT_PAYMENT,
            label: t("split_payment"),
          },
        ]}
      />
    ),
    payment_type: (
      <CustomSelect
        value={tableFilter.payment_type}
        onChange={(e) => {
          changeTableFilter("payment_type", e.target.value);
        }}
        options={[
          { value: "", label: t("all_text") },
          {
            value: PAYMENT_TYPE.CASH,
            label: t("cash"),
          },
          {
            value: PAYMENT_TYPE.CHEQUE,
            label: t("cheque"),
          },
          {
            value: PAYMENT_TYPE.BANK_TRANSFER,
            label: t("bank_transfer"),
          },
          {
            value: PAYMENT_TYPE.CREDIT_CARD,
            label: t("credit_card"),
          },
        ]}
      />
    ),
    given_amount: (
      <CustomTextField
        value={tableFilter.given_amount || ""}
        onChange={(e) => changeTableFilter("given_amount", e.target.value)}
        clearFilter={() => clearTableFilter("given_amount")}
      />
    ),
    promise_amount: (
      <CustomTextField
        value={tableFilter.promise_amount || ""}
        onChange={(e) => changeTableFilter("promise_amount", e.target.value)}
        clearFilter={() => clearTableFilter("promise_amount")}
      />
    ),
    status: (
      <CustomSelect
        value={tableFilter.status}
        onChange={(e) => {
          changeTableFilter("status", e.target.value);
        }}
        options={[
          { value: "", label: t("all_text") },
          {
            value: STATUS_TYPE.IN_PROGRESS,
            label: t("in_progress"),
          },
          {
            value: STATUS_TYPE.ABANDONED,
            label: t("abandoned"),
          },
          {
            value: STATUS_TYPE.CLOSED,
            label: t("closed"),
          },
          {
            value: STATUS_TYPE.OPEN,
            label: t("open"),
          },
        ]}
      />
    ),
  };

  const campaignsQuery = useApiClientQuery(
    () =>
      api_endpoints.getPromises(
        authContext.authContext.accessToken.organization_id,
        tableFilter.campaign_name,
        tableFilter.name,
        tableFilter.email,
        tableFilter.phone,
        tableFilter.promise_amount,
        tableFilter.status,
        tableFilter.payment_type,
        tableFilter.promise_type,
        tableFilter.given_amount,
        formatDateTime(tableFilter.start_date, tableFilter.start_time),
        formatDateTime(tableFilter.end_date, tableFilter.end_time)
      ),
    {
      queryKey: [
        "view_promise",
        tableFilter,
        authContext.authContext.accessToken.organization_id,
        refreshKey,
      ],
    },
    (data) => {
      if (data) {
        const formattedData = data.map((promise) => ({
          id: promise._id,
          email: promise.donor_profile.email,
          name: `${promise.donor_profile?.first_name || ""} ${
            promise.donor_profile?.last_name || ""
          }`.trim(),
          phone: promise.donor_profile?.phone,
          created_at: dateTimeReadableSimple(promise.created_at),
          campaign_name: promise.campaign_data?.name,
          payment_type: t(promise.payment_type),
          promise_type: t(promise.promise_type),
          given_amount: currencyFormatter(promise.given_amount),
          promise_amount: currencyFormatter(promise.promise_amount),
          status: promise.status,
        }));

        setRows([...formattedData]);
      } else {
        setRows([]);
      }
    }
  );

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  // Handle modal open and close
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setRefreshKey((prev) => prev + 1); // Trigger refetch after delay
    }, 900);
  };

  const selectPromise = (e) => {
    navigate(getPageFromName("Promesse").path, {
      state: { promise_id: e.id },
    });
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{t("add_promise_button")}</DialogTitle>
        <DialogContent>
          <CreatePromise onClose={handleCloseModal} />
        </DialogContent>
      </Dialog>
      <div className="flex justify-end">
        <Button variant="contained" onClick={handleOpenModal}>
          {t("add_promise_button")}
        </Button>
      </div>
      <div className="container-white">
        <DataGrid
          columns={columns}
          rows={rows}
          filters={filters}
          loading={campaignsQuery.isFetching}
          showDetails={selectPromise}
        />
      </div>
    </div>
  );
};
