import TimezoneAutocomplete from "../../widgets/TimezoneAutocomplete/TimezoneAutocomplete";
import styles from "./CreateLocation.module.css";
import { getPageFromName } from "../../route_utils";
import { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import api_endpoints from "../../api";
import { useNavigate } from "react-router-dom";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import ImageInput from "../../widgets/ImageInput/ImageInput";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const CreateLocation = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const { t } = useCustomTranslation("Créer un emplacement");

  const [formData, setFormData] = useState({
    organization: authContext.authContext.accessToken.organization_id,
    location_name: "",
    address: "",
    phone: "",
    person_responsible: "",
  });

  const getOrganizationTimezoneQuery = useApiClientQuery(
    () =>
      api_endpoints.getOrganizationCountryTimezone(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        `create_location_countryTimezoneQuery_${authContext.authContext.accessToken.organization_id}`,
      ],
    }
  );

  const getUsersQuery = useApiClientQuery(
    () =>
      api_endpoints.getUsers(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        `create_location_getUsersQuery_${authContext.authContext.accessToken.organization_id}`,
      ],
    },
    (data) => {
      if (!formData.person_responsible) {
        changeFormData("person_responsible", data.users[0]._id);
      }
    }
  );

  const createLocationMutation = useApiClientMutation(
    api_endpoints.createLocation,
    [
      `create_locationData_${authContext.authContext.accessToken.organization_id}`,
    ],
    (data) => {
      handleSuccessfulMutation(data);
    }
  );

  const handleCreateLocation = async (e) => {
    e?.preventDefault();

    const data = setRequestData();

    createLocationMutation.mutate(data);
  };

  const setRequestData = () => {
    const data = new FormData();

    if (image) data.append("file", image, image.name);

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    return data;
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    document.getElementById("imageInput").value = null;
    setImage(null);
  };

  const handleSuccessfulMutation = (successMessage) => {
    setMessageSuccessAlert(successMessage);
    setShowSuccessAlert(true);
    navigate(getPageFromName("Emplacements").path);
  };

  return (
    <AccessControl allowedPermissions={["is_super_user"]}>
      <LoadingContainer
        loading={getUsersQuery.isFetching}
        error={getUsersQuery.isError}
      >
        <form
          id="publish_campaign_form"
          onSubmit={handleCreateLocation}
          autoComplete="off"
        >
          <div className={`${styles.details_container} mt-4`}>
            <div className="text-lg">{t("create_location_header")}</div>
            <div className="[&>div]:flex [&>div]:items-center [&>div]:mt-4 [&>div]:space-x-4">
              <div>
                <TextField
                  fullWidth
                  placeholder={t("create_location_location_name")}
                  label={t("create_location_location_name")}
                  required
                  value={formData.location_name}
                  onInput={(e) =>
                    changeFormData("location_name", e.target.value)
                  }
                />
                <TimezoneAutocomplete
                  timezone={formData.timezone} // Send only the timezone ID
                  changeTimezone={
                    (timezoneId) =>
                      setFormData((prev) => ({ ...prev, timezone: timezoneId })) // Update the timezone directly
                  }
                  label={t("create_location_select_timezone")}
                  queryData={getOrganizationTimezoneQuery.data} // Pass the data for timezone options
                  selectFirstOption={true}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  placeholder={t("create_location_location_address")}
                  label={t("create_location_location_address")}
                  required
                  value={formData.address}
                  onInput={(e) => changeFormData("address", e.target.value)}
                />
                <TextField
                  fullWidth
                  placeholder={t("create_location_location_phone")}
                  label={t("create_location_location_phone")}
                  required
                  value={formData.phone}
                  onInput={(e) => changeFormData("phone", e.target.value)}
                />
              </div>
              <div>
                <FormControl fullWidth required>
                  <InputLabel>{t("create_location_manager")}</InputLabel>
                  <Select
                    fullWidth
                    value={formData.person_responsible}
                    required
                    label={t("create_location_manager")}
                    onChange={(e) =>
                      changeFormData("person_responsible", e.target.value)
                    }
                  >
                    {getUsersQuery.data?.users?.length > 0 &&
                      getUsersQuery.data.users.map((user) => (
                        <MenuItem value={user._id} key={user._id}>
                          <div className="flex flex-col">
                            <div className="text-lg font-semibold">{`${user.last_name} ${user.first_name}`}</div>
                            <div>{user.email}</div>
                          </div>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <AccessControl allowedPermissions={["is_super_user"]}>
                <div>
                  <ImageInput
                    image={image}
                    onChange={handleSelectImage}
                    removeImage={removeImage}
                    placeholder={t("create_location_splashscreen")}
                    helperText={t("create_location_imageformattext")}
                  />
                </div>
              </AccessControl>
            </div>
          </div>
          <div className="flex mt-4 justify-end space-x-4">
            <CancelButton
              disabled={createLocationMutation.isPending}
              label={t("create_location_cancel_button")}
            />
            <ButtonLoading
              loading={createLocationMutation.isPending}
              type="submit"
              variant="contained"
            >
              {t("create_location_create_button")}
            </ButtonLoading>
          </div>
        </form>
      </LoadingContainer>
    </AccessControl>
  );
};

export default CreateLocation;
