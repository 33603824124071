import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import React, { useState, useContext, Fragment } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { useHiddenView } from "../../context/HiddenViewContext/HiddenViewContext";
import HiddenViewContainer from "../HiddenViewContainer/HiddenViewContainer";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const CollapsibleTree = ({ pageRoot }) => {
  const [open, setOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const hiddenView = useHiddenView();
  const { t } = useCustomTranslation("Sidebar");

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  // if root contains only 1 page then we show it as a list item
  if (pageRoot.pages.length === 1) {
    return (
      <HiddenViewContainer show={!pageRoot.super_user || !hiddenView.hidden}>
        <ListItem disablePadding key={pageRoot.pages[0].name}>
          <ListItemButton
            disabled={
              pageRoot.pages[0].requiresOrg &&
              !authContext.authContext.accessToken.organization_id
            }
            onClick={() => navigateTo(pageRoot.pages[0].path)}
          >
            <div className="mr-2">{pageRoot.icon}</div>
            {t(pageRoot.pages[0].label)}
          </ListItemButton>
        </ListItem>
      </HiddenViewContainer>
    );
  }

  // if root contains more than 1 page we show a collapsible tree of links
  return (
    <>
      <HiddenViewContainer show={!pageRoot.super_user || !hiddenView.hidden}>
        <ListItem disablePadding>
          <ListItemButton
            disabled={
              pageRoot.requiresOrg &&
              !authContext.authContext.accessToken.organization_id
            }
            onClick={() => toggleCollapse()}
          >
            <div className="mr-2">{pageRoot.icon}</div>
            {t(pageRoot.label)}
            <div className="ml-auto">
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </div>
          </ListItemButton>
        </ListItem>
      </HiddenViewContainer>
      <Collapse in={open}>
        {pageRoot.pages.map((page, index) => {
          let canShowItem = false;
          if (!page) return <Fragment key={index}></Fragment>;

          if (!page.showInDrawer) return <Fragment key={page.name} />;

          // case for if the user is a super user
          if (authContext.authContext.accessToken.is_super_user) {
            canShowItem = !(page.super_user && hiddenView.hidden);
          }
          // case for if the user is not a super user
          else {
            canShowItem = !page.super_user;
          }

          return (
            <HiddenViewContainer key={page.name} show={canShowItem}>
              <List component={"div"} disablePadding key={page.label + index}>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => navigateTo(page.path)}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderRightWidth: 1,
                      background: "#F8F8F8",
                      marginRight: 2,
                      marginTop: "-8px",
                      marginBottom: "-8px",
                    }}
                  />
                  <div className="text-gray-600">{t(page.label)}</div>
                </ListItemButton>
              </List>
            </HiddenViewContainer>
          );
        })}
      </Collapse>
    </>
  );
};

export default CollapsibleTree;
