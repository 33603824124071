import { TextField } from "@mui/material";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useEffect, useState } from "react";
import api_endpoints from "../../api";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const CloverConfiguration = () => {
  var popupWindow = null;
  const [loadingData, setLoadingData] = useState(true);
  const [loadingDataError, setLoadingDataError] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [generatingToken, setGeneratingToken] = useState(false);
  const [clientID, setClientID] = useState("");
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const { t } = useCustomTranslation("Paramètres Clover");

  useEffect(() => {
    fetchClientID();
  }, []);

  const fetchClientID = async () => {
    setLoadingData(true);
    try {
      const response = await api_endpoints.getClientID();

      if (response.status === 200) {
        setClientID(response.data.client_id);
      } else {
        setLoadingDataError(true);
      }
    } catch (e) {
      setLoadingDataError(true);
      console.error(e);
    }
    setLoadingData(false);
  };

  const saveClientID = async () => {
    setSavingForm(true);
    try {
      const response = await api_endpoints.setClientID(clientID);

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
      }
    } catch (e) {}
    setSavingForm(false);
  };

  const centeredPopup = (url, winName, w, h, scroll) => {
    let LeftPosition = window.screen.width ? (window.screen.width - w) / 2 : 0;
    let TopPosition = window.screen.height ? (window.screen.height - h) / 2 : 0;
    let settings =
      "height=" +
      h +
      ",width=" +
      w +
      ",top=" +
      TopPosition +
      ",left=" +
      LeftPosition +
      ",scrollbars=" +
      scroll +
      ",resizable";
    popupWindow = window.open(url, winName, settings);
    return popupWindow;
  };

  const handleGenerateToken = async () => {
    setGeneratingToken(true);
    const popup = centeredPopup(
      `${process.env.REACT_APP_SERVER_HOSTNAME}:${process.env.REACT_APP_SERVER_PORT}/clover/cloverAuthenticate`,
      "cloverauthentication",
      "1000",
      "600",
      "yes"
    );

    setInterval(() => {
      if (popup.closed) {
        setGeneratingToken(false);
      }
    }, 1000);
  };

  return (
    <LoadingContainer loading={loadingData} error={loadingDataError}>
      <div className="container-white flex-col space-y-4 mt-4">
        <TextField
          label={t("clover_settings_client_id")}
          fullWidth
          required
          value={clientID}
          onInput={(e) => setClientID(e.target.value)}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
        />
        {/* <ButtonLoading
            loading={generatingToken}
            variant="contained"
            color="warning"
            fullWidth
            onClick={handleGenerateToken}
          >
            Générer un Jeton d'accèss
          </ButtonLoading> */}
      </div>
      <div className="mt-4 justify-end flex space-x-4">
        <CancelButton label={t("clover_settings_cancel_button")} />
        <ButtonLoading
          loading={savingForm}
          variant="contained"
          onClick={saveClientID}
        >
          {t("clover_settings_create_button")}
        </ButtonLoading>
      </div>
    </LoadingContainer>
  );
};

export default CloverConfiguration;
