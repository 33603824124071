import { getPageFromName, PAGES } from "../../route_utils";
import FormController from "../../widgets/FormController/FormController";
import { TextField } from "@mui/material";
import api_endpoints from "../../api";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useState } from "react";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const CreateSuperUser = () => {
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const { t } = useCustomTranslation("Créer un super utilisateur");

  const handleSubmit = async (e, formData) => {
    e.preventDefault();

    try {
      setLoading(true);

      let response = await api_endpoints.createSuperUser(
        formData.first_name,
        formData.last_name,
        formData.email
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        let otpResponse = await api_endpoints.createUserOTP(
          formData.email,
          false,
          true,
          false
        );

        if (otpResponse.status === 200) {
          setOTPCode(otpResponse.data.otp);
          setModalOpen(true);
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    navigate(getPageFromName("Super utilisateurs").path);
  };

  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { maxHeight: 435 } }}
        maxWidth="sm"
        open={modalOpen}
      >
        <DialogTitle>{t("create_superuser_otp_dialog_title")}</DialogTitle>
        <DialogContent>
          <div className="flex flex-1 flex-col space-y-4">
            <h1>{t("create_superuser_otp_dialog_message")}</h1>
            <strong className="w-full text-center text-7xl font-semibold">
              {OTPCode}
            </strong>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained" color="error">
            {t("create_superuser_otp_close_button")}
          </Button>
        </DialogActions>
      </Dialog>
      <FormController
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
        }}
      >
        {({ value, handleChange, getFormFields }) => (
          <form onSubmit={(e) => handleSubmit(e, getFormFields())}>
            <div className="container-white flex-col mt-4">
              <div className="flex flex-1 space-y-6 flex-col">
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  variant="outlined"
                  label={t("create_superuser_firstname")}
                  value={value.first_name}
                  name="first_name"
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  label={t("create_superuser_lastname")}
                  autoComplete="off"
                  value={value.last_name}
                  name="last_name"
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  label={t("create_superuser_email")}
                  autoComplete="off"
                  value={value.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-1 mt-2 justify-end space-x-4">
              <CancelButton
                disabled={loading}
                label={t("create_superuser_cancel_button")}
              />
              <ButtonLoading
                loading={loading}
                variant="contained"
                type="submit"
              >
                {t("create_superuser_create_button")}
              </ButtonLoading>
            </div>
          </form>
        )}
      </FormController>
    </>
  );
};

export default CreateSuperUser;
