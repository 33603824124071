import { useTranslation as originalUseTranslation } from "react-i18next";

export const useCustomTranslation = (namespace, options = {}) => {
  const { i18n } = originalUseTranslation(); // Access i18n instance

  // Check if the namespace exists
  const namespaceExists = i18n.hasResourceBundle(i18n.language, namespace);

  // If the namespace is missing, fall back to returning the key itself
  const safeNamespace = namespaceExists ? namespace : undefined;

  return originalUseTranslation(safeNamespace, {
    useSuspense: true, // Keep Suspense enabled
    ...options,
    defaultValue: (key) => key, // Return the key itself if the translation is missing
  });
};
