import styles from "./EditCampaign.module.css";
import { getPageFromName, PAGES } from "../../route_utils";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import React, { useEffect, useContext, useState, useRef } from "react";
import SwitchWrapper from "../SwitchWrapper/SwitchWrapper";
import { useNavigate, useLocation } from "react-router-dom";
import api_endpoints from "../../api";
import { numberWithCommas } from "../../utils";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Divider,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "dayjs/locale/fr-ca";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import AuthContext from "../../context/AuthContext/AuthContext";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import ImageInputV2 from "../../widgets/ImageInput/ImageInputV2";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const EditCampaign = () => {
  const authContext = useContext(AuthContext);
  const [organizationLocales, setOrganizationLocales] = useState([]);
  const [countryLocales, setCountryLocales] = useState([]);
  const [combinedLocales, setCombinedLocales] = useState([]);
  const [selectedLocale, setSelectedLocale] = useState("");
  const [localeTranslationData, setLocaleTranslationData] = useState({});
  const [campaignTranslations, setCampaignTranslations] = useState({});
  const [translationInitialized, setTranslationInitialized] = useState(false);
  const printRef = useRef(null);
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [formData, setFormData] = useState({});
  const [presetKioskModalOpen, setPresetKioskModalOpen] = useState(false);
  const [presetAmount, setPresetAmount] = useState("");
  const [presetAmountExists, setPresetAmountExists] = useState(false);
  const [presetAmountBelowMin, setPresetAmountBelowMin] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [locations, setLocations] = useState([]);
  const [image, setImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPreview, setPreviewOpen] = useState(false);
  const [placementPreview, setPlacementPreview] = useState();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const { t } = useCustomTranslation("Créer une campagne");
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    fetchCampaignData();
  }, [locations]);

  useEffect(() => {
    if (countryLocales.length > 0) {
      const combined = [...countryLocales, ...organizationLocales];
      setCombinedLocales(combined);
      setSelectedLocale(countryLocales[0].id); // Assuming each locale has an 'id' property
    }
  }, [organizationLocales, countryLocales]);

  useEffect(() => {
    if (formData && selectedLocale) {
      const translationData = localeTranslationData[selectedLocale];
      if (translationData) {
        const { campaign_name, campaign_description } = translationData;
        if (!campaign_name) {
          setCampaignTranslations((prevTranslations) => ({
            ...prevTranslations,
            [selectedLocale]: {
              campaign_name: formData.name || "",
              campaign_description: formData.description || "",
            },
          }));
        } else {
          setCampaignTranslations((prevTranslations) => ({
            ...prevTranslations,
            [selectedLocale]: {
              campaign_name: campaign_name,
              campaign_description: campaign_description,
            },
          }));
        }
      } else {
        setCampaignTranslations((prevTranslations) => ({
          ...prevTranslations,
          [selectedLocale]: {
            campaign_name: formData.name || "",
            campaign_description: formData.description || "",
          },
        }));
      }
    }
  }, [translationInitialized]);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      await fetchLocations();
      await fetchOrganizationLocales();
      await fetchCountryLocales();
      await fetchLocaleTranslations(state.campaign_id);
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setLoadingError(true);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (
      formData.has_duration &&
      (formData.date_start > formData.date_end ||
        formData.date_start === "" ||
        formData.date_end === "")
    ) {
      setDateError(true);
      return;
    }

    const data = setRequestData();
    const campaignName =
      campaignTranslations[selectedLocale]?.campaign_name || "";
    data.set("name", campaignName);
    try {
      let response = await api_endpoints.editCampaign(data);

      if (response.status === 200) {
        const translationsResponse =
          await api_endpoints.editCampaignTranslations(
            state.campaign_id,
            campaignTranslations
          );
        if (translationsResponse.status === 200) {
          setMessageSuccessAlert(response.data);
          setShowSuccessAlert(true);
          navigate(getPageFromName("Campagnes").path);
        } else {
          console.error("Failed to update translations:", translationsResponse);
        }
      }
    } catch (e) {}

    setSubmitting(false);
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const addPresetAmount = () => {
    if (!presetAmount) return;

    if (formData.kiosk_preset.includes(parseFloat(presetAmount).toFixed(2))) {
      setPresetAmountExists(true);
      return;
    }

    if (
      parseFloat(formData.donation_minimum) &&
      parseFloat(formData.donation_minimum) > parseFloat(presetAmount)
    ) {
      setPresetAmountBelowMin(true);
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      kiosk_preset: [
        ...prevState.kiosk_preset,
        parseFloat(presetAmount).toFixed(2),
      ].sort(compareNumbers),
    }));
    setPresetKioskModalOpen(false);
    setPresetAmountBelowMin(false);
  };

  const fetchCampaignData = async () => {
    try {
      let response = await api_endpoints.getCampaign(state.campaign_id);

      if (response.status === 200) {
        const data = response.data.campaign;
        data.location = data.location._id;
        setFormData(data);
      }
    } catch (e) {
      setLoadingError(true);
    }
  };

  const fetchLocations = async () => {
    setLoading(true);
    try {
      let response = await api_endpoints.getLocations(
        authContext.authContext.accessToken.organization_id,
        null
      );

      if (response.status === 200) {
        setLocations(response.data);
        await fetchCampaignData();
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }
    setLoading(false);
  };

  const fetchOrganizationLocales = async () => {
    setLoading(true);
    try {
      let response = await api_endpoints.getOrganizationLocale(
        authContext.authContext.accessToken.organization_id
      );

      if (response.status === 200) {
        const selected = response.data.map((locale) => ({
          id: locale.locale._id,
          label: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
        }));
        setOrganizationLocales(selected);
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }
    setLoading(false);
  };

  const fetchCountryLocales = async () => {
    setLoading(true);

    try {
      const organizationResponse = await api_endpoints.getOrganization(
        authContext.authContext.accessToken.organization_id
      );

      if (
        organizationResponse.status === 200 &&
        organizationResponse.data.country
      ) {
        const countryId = organizationResponse.data.country._id;
        const countryLocalesResponse = await api_endpoints.getCountryLocales(
          countryId
        );

        if (countryLocalesResponse.status === 200) {
          const selected = countryLocalesResponse.data.map((locale) => ({
            id: locale.locale._id,
            label: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
          }));

          setCountryLocales(selected);
        } else {
          console.error(
            "Failed to fetch country locales:",
            countryLocalesResponse
          );
        }
      } else {
        console.error(
          "Failed to fetch organization or country data:",
          organizationResponse
        );
      }
    } catch (error) {
      console.error("Error fetching country locales:", error);
    }

    setLoading(false);
  };

  const fetchLocaleTranslations = async (campaignId) => {
    try {
      const response = await api_endpoints.getCampaignTranslations(campaignId);
      if (response.status === 200) {
        const translations = response.data.reduce((acc, translation) => {
          acc[translation.locale_id] = {
            campaign_name: translation.campaign_name,
            campaign_description: translation.campaign_description,
          };
          return acc;
        }, {});
        setLocaleTranslationData(translations);
        setTranslationInitialized(true);
      }
    } catch (error) {
      console.error("Error fetching locale translations:", error);
    }
  };

  const compareNumbers = (a, b) => {
    return a - b;
  };

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const setRequestData = () => {
    const data = new FormData();

    if (image) data.append("file", image, image.name);

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    return data;
  };

  const handleRemovePresetKiosk = (preset) => {
    setFormData((prevState) => ({
      ...prevState,
      kiosk_preset: prevState.kiosk_preset.filter((item) => item !== preset),
    }));
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPreviewOpen((prev) => placementPreview !== newPlacement || !prev);
    setPlacementPreview(newPlacement);
  };

  const removeImage = () => {
    document.getElementById("imageInput").value = null;
    setImage(null);
  };

  const changelocale = (localeId) => {
    setSelectedLocale(localeId);
    if (localeTranslationData[localeId]) {
      const { campaign_name, campaign_description } =
        localeTranslationData[localeId];
      setFormData((prevState) => ({
        ...prevState,
        name: campaign_name || prevState.name,
        description: campaign_description || prevState.description,
      }));
      setCampaignTranslations((prevTranslations) => ({
        ...prevTranslations,
        [localeId]: {
          campaign_name:
            campaign_name || prevTranslations[localeId]?.campaign_name || "",
          campaign_description:
            campaign_description ||
            prevTranslations[localeId]?.campaign_description ||
            "",
        },
      }));
    }
  };

  return (
    <LoadingContainer loading={loading} error={loadingError}>
      <Dialog
        fullWidth
        open={presetKioskModalOpen}
        onClose={() => {
          setPresetKioskModalOpen(false);
          setPresetAmountExists(false);
        }}
        style={{ "--width": "30vw" }}
      >
        <DialogTitle>{t("create_campaign_addCash_dialogue_title")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={true}
            fullWidth
            value={presetAmount}
            onInput={(e) => {
              setPresetAmount(e.target.value);
              setPresetAmountExists(false);
            }}
            type="number"
            placeholder={t("create_campaign_amount")}
            label={t("create_campaign_amount")}
            FormHelperTextProps={{ sx: { color: "error.main" } }}
            helperText={
              presetAmountExists
                ? t("create_campaign_alread_exists_amount")
                : presetAmountBelowMin
                ? t("create_campaign_lessThanMinAmount_text")
                : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addPresetAmount}>
            {t("add_text")}
          </Button>
        </DialogActions>
      </Dialog>
      <form
        id="publish_campaign_form"
        onSubmit={handleSubmit}
        autoComplete="off"
        enctype="multipart/form-data"
      >
        <div className="container-white flex-col space-y-4 mt-4">
          {/* Hidden Print Preview Component (for printing only) */}

          <div className="flex flex-1 flex-col space-y-4">
            <div>
              <FormControl fullWidth>
                <InputLabel required>
                  {" "}
                  {t("create_campaign_location_choice")}
                </InputLabel>
                <Select
                  value={formData?.location}
                  label={t("create_campaign_location_choice")}
                  onChange={(e) => changeFormData("location", e.target.value)}
                  fullWidth
                  required
                >
                  {locations.map((location) => (
                    <MenuItem key={location._id} value={location._id}>
                      <div className="flex flex-col">
                        <label className="text-lg font-semibold">
                          {location.location_name}
                        </label>
                        <label>{location.address}</label>
                        <label>{location.phone}</label>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <ImageInputV2
                defaultImage={formData.image_url}
                image={image}
                onChange={handleSelectImage}
                removeImage={removeImage}
                removeDefaultImage={() => changeFormData("image_url", "")}
                placeholder={t("create_campaign_imagepreview")}
                helperText={t("create_campaign_imagerequirements")}
              />
            </div>
            <Divider />
            <div className="flex flex-1 flex-col space-y-4">
              <div>
                <FormControl fullWidth>
                  <InputLabel required>
                    {t("create_campaign_language_choice")}
                  </InputLabel>
                  <Select
                    value={selectedLocale}
                    label={t("create_campaign_language_choice")}
                    onChange={(e) => changelocale(e.target.value)}
                    fullWidth
                    required
                  >
                    {combinedLocales.map((locale) => (
                      <MenuItem key={locale.id} value={locale.id}>
                        <div className="flex flex-col">{locale.label}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex items-end space-x-4">
              <TextField
                fullWidth
                placeholder={t("create_campaign_name")}
                label={t("create_campaign_name")}
                required
                value={
                  campaignTranslations[selectedLocale]?.campaign_name || ""
                }
                onChange={(e) => {
                  // Update the translation for the selected locale
                  setCampaignTranslations((prevTranslations) => ({
                    ...prevTranslations,
                    [selectedLocale]: {
                      ...prevTranslations[selectedLocale],
                      campaign_name: e.target.value,
                    },
                  }));
                }}
              />
              <SwitchWrapper
                label={t("create_campaign_duration")}
                style={{ width: "100%" }}
                className="space-y-2"
                checked={formData.has_duration}
                onChange={(e) =>
                  changeFormData("has_duration", e.target.checked)
                }
                required={formData.has_duration}
              >
                <div className="flex space-x-2 justify-between w-full">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr-ca"
                  >
                    <DatePicker
                      value={dayjs(formData.date_start)}
                      onChange={(value) =>
                        changeFormData("date_start", value.$d.getTime())
                      }
                      label={t("create_campaign_start")}
                      sx={{ width: "100%" }}
                      slotProps={
                        dateError
                          ? {
                              textField: {
                                error: dateError,
                                helperText: "Dates invalides",
                                required: formData.has_duration,
                              },
                            }
                          : {
                              textField: {
                                error: false,
                                required: formData.has_duration,
                              },
                            }
                      }
                    />
                    <DatePicker
                      value={dayjs(formData.date_end)}
                      onChange={(value) =>
                        changeFormData("date_end", value.$d.getTime())
                      }
                      label={t("create_campaign_end")}
                      sx={{ width: "100%" }}
                      slotProps={
                        dateError
                          ? {
                              textField: {
                                error: dateError,
                                helperText: "Dates invalides",
                                required: formData.has_duration,
                              },
                            }
                          : {
                              textField: {
                                error: false,
                                required: formData.has_duration,
                              },
                            }
                      }
                    />
                  </LocalizationProvider>
                </div>
              </SwitchWrapper>
            </div>
            <div className="flex flex-row space-x-4">
              <SwitchWrapper
                label={t("create_campaign_objective")}
                style={{ width: "100%" }}
                checked={formData.has_objective}
                onChange={(e) => {
                  changeFormData("has_objective", e.target.checked);
                }}
                required={formData.has_objective}
              >
                <TextField
                  label={t("create_campaign_objectivetext")}
                  type="number"
                  fullWidth
                  value={formData.objective}
                  onInput={(e) => {
                    changeFormData("objective", e.target.value);
                  }}
                  required={formData.has_objective}
                />
              </SwitchWrapper>
              <SwitchWrapper
                style={{ marginLeft: 30, width: "100%" }}
                label={t("create_campaign_minamount")}
                checked={formData.has_minimum_donation}
                onChange={(e) => {
                  changeFormData("has_minimum_donation", e.target.checked);
                }}
                required={formData.has_minimum_donation}
              >
                <TextField
                  label={t("create_campaign_no_min")}
                  fullWidth
                  type="number"
                  value={formData.donation_minimum}
                  onInput={(e) => {
                    changeFormData("donation_minimum", e.target.value);
                  }}
                  required={formData.has_minimum_donation}
                />
              </SwitchWrapper>
            </div>
            <div>
              <TextField
                placeholder={t("create_campaign_description")}
                label={t("create_campaign_description")}
                fullWidth
                multiline
                rows={5}
                value={
                  campaignTranslations[selectedLocale]?.campaign_description ||
                  ""
                }
                onChange={(e) => {
                  setCampaignTranslations((prevTranslations) => ({
                    ...prevTranslations,
                    [selectedLocale]: {
                      ...prevTranslations[selectedLocale],
                      campaign_description: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>
          <Divider />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.enabled}
                  value={formData.enabled}
                  onChange={(e) => changeFormData("enabled", e.target.checked)}
                />
              }
              label={t("create_campaign_active")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.can_donate_anonymously}
                  value={formData.can_donate_anonymously}
                  onChange={(e) =>
                    changeFormData("can_donate_anonymously", e.target.checked)
                  }
                />
              }
              label={t("create_campaign_anonymous_donations")}
            />
            {/* {authContext.authContext.accessToken.organization_id ===
            "65f34e519569270c8bc0be4b" ? ( */}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.is_fundraiser}
                    value={formData.is_fundraiser}
                    onChange={(e) =>
                      changeFormData("is_fundraiser", e.target.checked)
                    }
                  />
                }
                label={t("is_fundraiser")}
              />
              <Tooltip title={t("info_qr_code")}>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
            {/* ) : (
              <></>
            )} */}
          </FormGroup>
          <Divider />
          <div className={styles.amount_presets_container}>
            <label>{t("create_campaign_preset_donations")}</label>
            <div className="gap-4 flex items-center">
              {formData.kiosk_preset?.map((preset, index) =>
                preset ? (
                  <div className={styles.kiosk_preset} key={preset + index}>
                    <SlIconButton
                      className="flex-[0] !justify-end z-10 text-red-500"
                      name="trash"
                      label="Remove preset"
                      onClick={() => handleRemovePresetKiosk(preset)}
                    />
                    <div className={styles.preset_amount}>
                      <SlIcon library="boxicons" name="bx-dollar" />
                      {numberWithCommas(parseFloat(preset).toFixed(2))}
                    </div>
                  </div>
                ) : (
                  <React.Fragment key={preset + index}></React.Fragment>
                )
              )}
              <Button
                variant="contained"
                onClick={() => setPresetKioskModalOpen(true)}
                className="flex flex-col text-center w-[123px] h-[110px]"
                sx={{
                  padding: 7,
                }}
              >
                {t("create_campaign_addamount")}
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-4 space-x-4 flex pb-4 justify-end">
          <CancelButton
            disabled={submitting}
            label={t("create_campaign_cancel_button")}
          />
          <ButtonLoading loading={submitting} variant="contained" type="submit">
            {t("edit_campaign_button")}
          </ButtonLoading>
        </div>
      </form>
    </LoadingContainer>
  );
};

export default EditCampaign;
