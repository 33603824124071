import styles from "./ManageLocations.module.css";
import { getPageFromName } from "../../route_utils";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import AddIcon from "@mui/icons-material/Add";
import { NavigationButton } from "../../widgets/NavigationButton/NavigationButton";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import axios from "axios";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const ManageLocations = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [locationDialog, setLocationDialog] = useState(null);
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);
  const { t } = useCustomTranslation("Emplacements");
  const [tableFilter, setTableFilter] = useState({
    organization_id: authContext.authContext.accessToken.organization_id,
    location_name: "",
    person_responsible: "",
  });
  const columns = [
    {
      field: "location_name",
      headerName: t("locations_table_name"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "person_responsible",
      headerName: t("locations_table_person_responsible"),
      sortable: true,
      numberSort: false,
    },
  ];
  const filters = {
    location_name: (
      <CustomTextField
        value={tableFilter.location_name || ""}
        onChange={(e) => changeTableFilter("location_name", e.target.value)}
        clearFilter={() => clearTableFilter("location_name")}
      />
    ),
    person_responsible: (
      <CustomTextField
        value={tableFilter.person_responsible || ""}
        onChange={(e) =>
          changeTableFilter("person_responsible", e.target.value)
        }
        clearFilter={() => clearTableFilter("person_responsible")}
      />
    ),
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter, authContext.authContext.accessToken.organization_id]);

  const changeTableFilter = (option, value) => {
    setTableFilter({ ...tableFilter, [option]: value });
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchLocations(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  const showLocationDetails = (e) => {
    navigate(getPageFromName("Modifier l'emplacement").path, {
      state: { location_name: e.row.location_name },
    });
  };

  const fetchLocations = async () => {
    setLoading(true);

    try {
      let response = await api_endpoints.getLocations(
        tableFilter.organization_id,
        tableFilter.location_name,
        tableFilter.person_responsible
      );

      if (response.status === 200) {
        setLoading(false);
        return response.data.map((location) => ({
          id: location._id,
          location_name: location.location_name,
          person_responsible: `${location.person_responsible.last_name} ${location.person_responsible.first_name}`,
        }));
      }
    } catch (e) {
      setLoadingError(true);
    }

    setLoading(false);
  };

  return (
    <>
      <div className={"flex justify-end"}>
        <NavigationButton
          startIcon={<AddIcon />}
          text={t("locations_table_addlocation_button")}
          path={getPageFromName("Créer un emplacement").path}
        />
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        fullWidth
      >
        <DialogTitle>Détails de l'emplacement</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <h3 style={{ marginBottom: 0 }}>ID : {locationDialog?._id}</h3>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Emplacement :</span>{" "}
              {locationDialog?.location_name}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Adresse :</span>{" "}
              {locationDialog?.address}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Personne responsable :</span>{" "}
              {locationDialog?.person_responsible[0].last_name}{" "}
              {locationDialog?.person_responsible[0].first_name}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Numéro de téléphone :</span>{" "}
              {locationDialog?.phone}
            </label>
          </div>
        </DialogContent>
      </Dialog>
      <LoadingContainer error={loadingError}>
        <>
          <div className={styles.header}></div>
          <div>
            <div className={styles.table_container_body}>
              <DataGrid
                columns={columns}
                rows={rows}
                showDetails={showLocationDetails}
                filters={filters}
                loading={loading}
              />
            </div>
          </div>
        </>
      </LoadingContainer>
    </>
  );
};

export default ManageLocations;
