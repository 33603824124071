import { Button, Divider, Chip } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoIcon from "@mui/icons-material/Info";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation } from "react-router-dom";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import CustomDatePicker from "../../widgets/CustomDatePicker/CustomDatePicker";
import AddPromiseAmount from "./AddPromiseAmount";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useApiClientQuery } from "../../hooks/useApiClient";
import api_endpoints from "../../api";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import { PAYMENT_TYPE, STATUS_TYPE } from "../../enums/PromiseEnums";
import {
  dateTimeReadableSimple,
  formatDateTime,
  currencyFormatter,
} from "../../utils";
import EditPromise from "./EditPromise";

export const PromiseDetails = () => {
  const [rows, setRows] = useState([]);
  const { state } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [openEditPromiseModal, setOpenEditPromiseModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [refreshPromiseDataKey, setRefreshPromiseDataKey] = useState(0);
  const [tableFilter, setTableFilter] = useState({
    promise_id: state.promise_id,
    amount: "",
    donation_date: "",
    inserted_by: "",
    payment_type: "",
  });
  const { t } = useCustomTranslation("Promise Details");

  const columns = [
    {
      field: "amount",
      headerName: t("amount"),
      sortable: true,
      numberSort: true,
    },
    {
      field: "donation_date",
      headerName: t("donation_date"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "payment_type",
      headerName: t("payment_type"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "inserted_by",
      headerName: t("inserted_by"),
      sortable: true,
      numberSort: false,
    },
  ];

  const filters = {
    amount: (
      <CustomTextField
        value={tableFilter.amount || ""}
        onChange={(e) => changeTableFilter("amount", e.target.value)}
        clearFilter={() => clearTableFilter("amount")}
      />
    ),
    donation_date: (
      <CustomDatePicker
        value={tableFilter.donation_date}
        onChange={(newValue) =>
          setTableFilter((prevValue) => ({
            ...prevValue,
            donation_date: newValue,
          }))
        }
        clearFilter={() => clearTableFilter("donation_date")}
      />
    ),
    payment_type: (
      <CustomSelect
        value={tableFilter.payment_type}
        onChange={(e) => {
          changeTableFilter("payment_type", e.target.value);
        }}
        options={[
          { value: "", label: t("all_text") },
          {
            value: PAYMENT_TYPE.CASH,
            label: t("cash"),
          },
          {
            value: PAYMENT_TYPE.CHEQUE,
            label: t("cheque"),
          },
          {
            value: PAYMENT_TYPE.BANK_TRANSFER,
            label: t("bank_transfer"),
          },
          {
            value: PAYMENT_TYPE.CREDIT_CARD,
            label: t("credit_card"),
          },
        ]}
      />
    ),
    inserted_by: (
      <CustomTextField
        value={tableFilter.inserted_by || ""}
        onChange={(e) => changeTableFilter("inserted_by", e.target.value)}
        clearFilter={() => clearTableFilter("inserted_by")}
      />
    ),
  };

  const getPromiseQuery = useApiClientQuery(
    () => api_endpoints.getPromise(state.promise_id),
    {
      queryKey: ["view_promise", state.promise_id, refreshPromiseDataKey],
      enabled: !!state.promise_id, // Query runs only if promise_id is truthy
    }
  );

  const promiseTransactionsQuery = useApiClientQuery(
    () =>
      api_endpoints.getCampaignPromiseTransactions(
        state.promise_id,
        tableFilter.amount,
        tableFilter.donation_date
          ? formatDateTime(tableFilter.donation_date)
          : null,
        tableFilter.inserted_by,
        tableFilter.payment_type
      ),
    {
      queryKey: ["view_promise_transactions", tableFilter, refreshKey],
    },
    (data) => {
      const formattedData = (data || []).map((transaction) => ({
        id: transaction._id,
        amount: currencyFormatter(transaction.amount),
        donation_date: dateTimeReadableSimple(
          transaction.paymentDetails?.date_of_donation ??
            transaction.paymentDetails?.date_of_transfer
        ),
        payment_type: t(transaction.paymentType),
        inserted_by: `${transaction.paymentDetails?.user?.first_name || ""} ${
          transaction.paymentDetails?.user?.last_name || ""
        }`.trim(),
      }));
      setRows(formattedData);
    }
  );

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  // Handle modal open and close
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setRefreshKey((prev) => prev + 1); // Trigger refetch after delay
    }, 300); // Adjust delay as needed
    setTimeout(() => {
      setRefreshPromiseDataKey((prev) => prev + 1); // Trigger refetch after delay
    }, 1000);
  };

  const handleEditPromiseOpenModal = () => {
    setOpenEditPromiseModal(true);
  };

  const handleCloseEditPromiseModal = () => {
    setOpenEditPromiseModal(false);

    setTimeout(() => {
      setRefreshPromiseDataKey((prev) => prev + 1); // Trigger refetch after delay
    }, 1000);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Dialog
        open={openEditPromiseModal}
        onClose={handleCloseEditPromiseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("edit_promise")}</DialogTitle>
        <DialogContent>
          <EditPromise
            onClose={handleCloseEditPromiseModal}
            promise_id={state.promise_id}
            promise_amount={getPromiseQuery.data?.[0]?.promise_amount}
            status={getPromiseQuery.data?.[0]?.status}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("add_promise_button")}</DialogTitle>
        <DialogContent>
          <AddPromiseAmount
            onClose={handleCloseModal}
            promise_id={state.promise_id}
            payment_type={getPromiseQuery.data?.[0]?.payment_type}
          />
        </DialogContent>
      </Dialog>
      <h1 className="text-3xl font-semibold">
        {t("fundraiser_for_campaign", {
          campaign: getPromiseQuery.data?.[0]?.campaign?.name || "",
        })}
      </h1>
      <div className="flex flex-1 gap-x-4">
        <div className="[&>div]:space-x-2 [&>div]:text-xl space-y-1 w-1/3">
          <div className="!text-2xl font-semibold">
            <EmailOutlinedIcon />
            <label>
              {getPromiseQuery.data?.[0]?.donor_profile?.email || ""}
            </label>
          </div>
          <div>
            <PersonOutlinedIcon />
            <label>
              {getPromiseQuery.data?.[0]?.donor_profile
                ? `${getPromiseQuery.data[0].donor_profile.last_name || ""} ${
                    getPromiseQuery.data[0].donor_profile.first_name || ""
                  }`.trim()
                : ""}
            </label>
          </div>
          <div>
            <PhoneOutlinedIcon />
            <label>
              {getPromiseQuery.data?.[0]?.donor_profile?.phone || ""}
            </label>
          </div>
          <div>
            <WorkOutlineOutlinedIcon />
            <label>
              {getPromiseQuery.data?.[0]?.donor_profile?.adresse || ""}
            </label>
          </div>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="space-y-2">
          {/* Promised Amount - Bigger & Bolder */}
          <div className="flex justify-between  text-2xl font-bold">
            <div className="flex  gap-2">
              <VolunteerActivismIcon />
              <label>{t("promised_amount")}:</label>
            </div>
            <span className="text-left">
              {currencyFormatter(
                getPromiseQuery.data?.[0]?.promise_amount || 0
              )}
            </span>
          </div>

          {/* Other Fields - Standard Size */}
          {[
            {
              icon: <VolunteerActivismIcon />,
              label: t("given_amount"),
              value: currencyFormatter(
                getPromiseQuery.data?.[0]?.given_amount || 0
              ),
            },
            {
              icon: <CalendarMonthIcon />,
              label: t("due_date"),
              value: getPromiseQuery.data?.[0]?.due_date
                ? formatDateTime(getPromiseQuery.data?.[0]?.due_date)
                : "none",
            },
            {
              icon: <InfoIcon />,
              label: t("status"),
              value: (
                <Chip
                  label={
                    t(getPromiseQuery.data?.[0]?.status) || t("unknown_status")
                  }
                  color={
                    {
                      [STATUS_TYPE.IN_PROGRESS]: "primary",
                      [STATUS_TYPE.ABANDONED]: "error",
                      [STATUS_TYPE.CLOSED]: "error",
                      [STATUS_TYPE.OPEN]: "success",
                    }[getPromiseQuery.data?.[0]?.status] || "default"
                  }
                  variant={
                    getPromiseQuery.data?.[0]?.status === STATUS_TYPE.ABANDONED
                      ? "outlined"
                      : "contained"
                  }
                />
              ),
            },
            {
              icon: <ReceiptLongIcon />,
              label: t("type_of_promise"),
              value: (
                <>
                  {t(getPromiseQuery.data?.[0]?.promise_type) || ""}
                  {getPromiseQuery.data?.[0]?.payment_frequency && (
                    <span className="font-bold ml-2">
                      {` (${t(getPromiseQuery.data?.[0]?.payment_frequency)})`}
                    </span>
                  )}
                </>
              ),
            },
            {
              icon: <ReceiptLongIcon />,
              label: t("type_of_payment"),
              value: t(getPromiseQuery.data?.[0]?.payment_type) || "",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="flex justify-between text-xl font-semibold space-x-4"
            >
              <div className="flex  gap-4">
                {item.icon}
                <label>{item.label}:</label>
              </div>
              <span className="text-right">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          startIcon={<EditIcon />}
          variant="contained"
          onClick={handleEditPromiseOpenModal}
        >
          {t("edit_promise")}
        </Button>
      </div>
      <Divider />
      <div className="flex justify-end">
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleOpenModal}
        >
          {t("add_an_amount")}
        </Button>
      </div>
      <div className="container-white">
        <DataGrid
          columns={columns}
          rows={rows}
          filters={filters}
          loading={promiseTransactionsQuery.isFetching}
        />
      </div>
    </div>
  );
};
