import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./ManageUsers.module.css";
import { getPageFromName } from "../../route_utils";
import { useNavigate } from "react-router-dom";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import { numberWithCommas } from "../../utils";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import Chip from "@mui/material/Chip";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { NavigationButton } from "../../widgets/NavigationButton/NavigationButton";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import CustomDatePicker from "../../widgets/CustomDatePicker/CustomDatePicker";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import dayjs from "dayjs";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const ManageUsers = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [usersCount, setUsersCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);
  const { t } = useCustomTranslation("Utilisateurs");
  const [tableFilter, setTableFilter] = useState({
    first_name: "",
    last_name: "",
    email: "",
    created_at: "",
    is_verified: null,
    is_active: null,
  });

  const columns = [
    {
      field: "first_name",
      headerName: t("user_table_firstname"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "last_name",
      headerName: t("user_table_lastname"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "email",
      headerName: t("user_table_email"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "created_at",
      headerName: t("user_table_creationdate"),
      sortable: true,
      numberSort: false,
      renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
      field: "is_verified",
      headerName: t("user_table_verified_filter"),
      sortable: true,
      numberSort: false,
      renderCell: (params) => (
        <Chip
          label={
            params.value
              ? t("user_table_verified")
              : t("user_table_not_verified")
          }
          color={params.value ? "success" : "error"}
          variant="contained"
        />
      ),
    },
    {
      field: "is_active",
      headerName: t("user_table_status"),
      sortable: true,
      numberSort: false,
      renderCell: (params) => (
        <Chip
          label={
            params.value ? t("user_table_active") : t("user_table_not_active")
          }
          color={params.value ? "success" : "error"}
          variant="contained"
        />
      ),
    },
  ];

  const filters = {
    first_name: (
      <CustomTextField
        value={tableFilter.first_name || ""}
        onChange={(e) => changeTableFilter("first_name", e.target.value)}
        clearFilter={() => clearTableFilter("first_name")}
      />
    ),
    last_name: (
      <CustomTextField
        value={tableFilter.last_name || ""}
        onChange={(e) => changeTableFilter("last_name", e.target.value)}
        clearFilter={() => clearTableFilter("last_name")}
      />
    ),
    email: (
      <CustomTextField
        value={tableFilter.email || ""}
        onChange={(e) => changeTableFilter("email", e.target.value)}
        clearFilter={() => clearTableFilter("email")}
      />
    ),
    created_at: (
      <CustomDatePicker
        value={tableFilter.created_at}
        onChange={(newValue) => changeTableFilter("created_at", newValue)}
        clearFilter={() => clearTableFilter("created_at")}
      />
    ),
    is_verified: (
      <CustomSelect
        value={tableFilter.is_verified}
        onChange={(e) => {
          changeTableFilter("is_verified", e.target.value);
        }}
        options={[
          { value: null, label: t("user_table_all_option") },
          { value: true, label: t("user_table_yes_option") },
          { value: false, label: t("user_table_no_option") },
        ]}
      />
    ),

    is_active: (
      <CustomSelect
        value={tableFilter.is_active}
        onChange={(e) => {
          changeTableFilter("is_active", e.target.value);
        }}
        options={[
          { value: null, label: t("user_table_all_option") },
          { value: true, label: t("user_table_active") },
          { value: false, label: t("user_table_not_active") },
        ]}
      />
    ),
  };

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter, authContext.authContext.accessToken.organization_id]);

  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchUsers(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  const fetchUserOTP = async (selectedRow) => {
    var rowData = selectedRow.row;
    if (rowData.is_verified === false) {
      try {
        let otpResponse = await api_endpoints.checkUserOTP(rowData.email);
        if (otpResponse.status === 200) {
          setOTPCode(otpResponse.data.otp);
          setModalOpen(true);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const created_at = formatDate(tableFilter.created_at);
      let response = await api_endpoints.getUsers(
        authContext.authContext.accessToken.organization_id,
        tableFilter.first_name,
        tableFilter.last_name,
        tableFilter.email,
        created_at,
        tableFilter.is_active,
        tableFilter.is_verified
      );

      if (response.status === 200) {
        setUsersCount(response.data.usersCount);
        setLoading(false);
        return response.data.users.map((user) => ({
          id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          created_at: user.created_at,
          is_verified: user.is_verified,
          is_active: user.is_active,
        }));
      }
    } catch (e) {}
  };

  const selectUser = (e) => {
    navigate(getPageFromName("Paramètres utilisateur").path, {
      state: { user_email: e.email },
    });
  };

  const changeTableFilter = (option, value) => {
    setTableFilter({ ...tableFilter, [option]: value });
  };

  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { maxHeight: 435 } }}
        maxWidth="sm"
        open={modalOpen}
      >
        <DialogTitle>{t("user_otp_dialog_heading")}</DialogTitle>
        <DialogContent>
          <div className="flex flex-1 flex-col space-y-4">
            <h1>{t("user_otp_message")}</h1>
            <strong className="w-full text-center text-7xl font-extrabold">
              {OTPCode}
            </strong>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained">
            {t("user_otp_close_button")}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={"flex justify-end"}>
        <NavigationButton
          startIcon={<AddIcon />}
          text={t("user_button_create_user")}
          path={getPageFromName("Créer un utilisateur").path}
        />
      </div>

      <div className={`${styles.header} mt-4} mt-4`}>
        <div className={styles.left_header}>
          {loading ? (
            t("user_page_loading")
          ) : (
            <>
              {t("user_page_number_of_users", {
                count: numberWithCommas(usersCount),
              })}
            </>
          )}
        </div>
      </div>
      <div>
        <div className="flex flex-1 h-full mt-5 flex-col">
          <LoadingContainer loading={loading} error={loadingError}>
            <DataGrid
              columns={columns}
              rows={rows}
              showDetails={fetchUserOTP}
              filters={filters}
              loading={loading}
            />
          </LoadingContainer>
        </div>
      </div>
    </>
  );
};

export default ManageUsers;
