import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import AuthContext from "../../context/AuthContext/AuthContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";
import api_endpoints from "../../api";
import { NumericFormat } from "react-number-format";
import { formatDateTime } from "../../utils";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import {
  PAYMENT_TYPE,
  PROMISE_TYPE,
  PAYMENT_FREQUENCY,
} from "../../enums/PromiseEnums";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const CreatePromise = ({ onClose }) => {
  const { t } = useCustomTranslation("Create Promise");
  const [emailError, setEmailError] = useState(false);
  const [acceptCash, setAcceptCash] = useState(true);
  const [dueDateError, setDueDateError] = useState(false);
  const authContext = useContext(AuthContext);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  const [formData, setFormData] = useState({
    donor_id: "",
    campaign_id: "",
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    email: "",
    zip_code: "",
    payment_type: PAYMENT_TYPE.CASH,
    promise_type: PROMISE_TYPE.ONE_TIME_PAYMENT,
    promise_amount: "",
    payment_frequency: "",
    due_date: "",
  });

  const campaignsQuery = useApiClientQuery(
    () =>
      api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true //is_fundraiser = true
      ),
    {
      queryKey: [
        "campaigns_data",
        authContext.authContext.accessToken.organization_id,
      ],
    }
  );

  const donorsQuery = useApiClientQuery(
    () =>
      api_endpoints.getDonors(
        null,
        null,
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        "donors_data",
        authContext.authContext.accessToken.organization_id,
      ],
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
      setEmailError(!emailPattern.test(value));
    }

    if (name === "payment_type") {
      setFormData((prevValue) => ({
        ...prevValue,
        frequency: null,
        due_date: null,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (emailError) {
      console.error("Invalid email address");
      return;
    }

    if (
      formData.promise_type === PROMISE_TYPE.ONE_TIME_PAYMENT &&
      !formData.due_date
    ) {
      setDueDateError(true);
      return; // Stop further execution if validation fails
    }

    // Your submit logic
    const promiseData = {
      donor_id: formData.donor_id,
      campaign_id: formData.campaign_id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: formData.phone,
      address: formData.address,
      email: formData.email,
      zip_code: formData.zip_code,
      promise_amount: formData.promise_amount.replace(/[^0-9.]/g, "") ?? 0, // Ensure promise_amount is a number
      given_amount: 0, // Default value as per your backend schema
      due_date: formData.due_date ? formatDateTime(formData.due_date) : null, // Handle date conversion
      promise_type: formData.promise_type, // Map to promise_type
      payment_type: formData.payment_type, // Map to payment_type
      // payment_frequency:
      //   formData.promise_type === PROMISE_TYPE.RECURRING
      //     ? formData.payment_frequency
      //     : null, // Only set frequency if donation is recurring
    };

    createPromiseMutation.mutate(promiseData);
  };

  const createPromiseMutation = useApiClientMutation(
    api_endpoints.createPromise,
    [`view_promise`],
    (data) => {
      setMessageSuccessAlert(data.message);
      setShowSuccessAlert(true);
      onClose();
    }
  );

  const handleDonorChange = (_, value) => {
    setFormData((prev) => ({
      ...prev,
      donor_id: value ? value._id : "", // store only the donor id
    }));
  };

  const handleCampaignChange = (_, value) => {
    setFormData((prev) => ({
      ...prev,
      campaign_id: value ? value._id : "",
    }));
    if (value && value.can_donate_with_cash) {
      setAcceptCash(true); // Set acceptCash to true if can_donate_with_cash is true
    } else {
      setAcceptCash(false); // Set acceptCash to false if can_donate_with_cash is false
      setFormData((prev) => ({
        ...prev,
        payment_type: PAYMENT_TYPE.CREDIT_CARD,
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-4 my-4">
        <Autocomplete
          options={donorsQuery.data?.filter(
            (donor) => donor && donor.email && donor.email.trim() !== ""
          )}
          name="donor"
          onChange={handleDonorChange}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => <TextField {...params} label="Donor" />}
        />{" "}
        <Autocomplete
          options={campaignsQuery.data?.filter(
            (campaign) =>
              campaign && campaign.name && campaign.name.trim() !== ""
          )}
          name="campaign_id"
          onChange={handleCampaignChange}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Campaign" required />
          )}
        />{" "}
      </div>
      {!formData.donor_id && (
        <>
          {" "}
          <div className="grid grid-cols-2 gap-4">
            {/* Use Tailwind Grid for first row */}
            <TextField
              fullWidth
              label={t("first_name")}
              required
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label={t("last_name")}
              required
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              label={t("phone")}
              required
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              label={t("address")}
              required
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 my-4">
            <TextField
              label={t("email")}
              required
              error={emailError}
              helperText={emailError ? "Invalid email address" : ""}
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              label={t("zip_code")}
              required
              name="zip_code"
              value={formData.zip_code}
              onChange={handleChange}
            />
          </div>
        </>
      )}
      <div className="grid grid-cols-1 gap-4 my-4">
        {" "}
        {/* Zip Code and Payment Type in same row */}
        <FormControl fullWidth required>
          <InputLabel>{t("payment_type")}</InputLabel>
          <Select
            name="payment_type"
            value={formData.payment_type}
            onChange={handleChange}
            label={t("payment_type")}
          >
            <MenuItem value={PAYMENT_TYPE.CREDIT_CARD}>
              {t("credit_card")}
            </MenuItem>
            <MenuItem value={PAYMENT_TYPE.CHEQUE}>{t("cheque")}</MenuItem>
            <MenuItem value={PAYMENT_TYPE.BANK_TRANSFER}>
              {t("bank_transfer")}
            </MenuItem>
            {acceptCash && (
              <MenuItem value={PAYMENT_TYPE.CASH}>{t("cash")}</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>

      <div className="grid grid-cols-2 gap-4 my-4">
        {" "}
        {/* Donation Type and Due Date in same row */}
        <FormControl fullWidth required>
          <InputLabel>{t("promise_type")}</InputLabel>
          <Select
            name="promise_type"
            value={formData.promise_type}
            onChange={handleChange}
            label={t("promise_type")}
          >
            <MenuItem value="one_time_payment">
              {t("one_time_payment")}
            </MenuItem>
            <MenuItem value={PROMISE_TYPE.SPLIT_PAYMENT}>
              {t("split_payment")}
            </MenuItem>
            {/* <MenuItem value={PROMISE_TYPE.RECURRING}>
                      {t("recurring")}
                    </MenuItem> */}
          </Select>
        </FormControl>
        {formData.promise_type === PROMISE_TYPE.ONE_TIME_PAYMENT && (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="fr-ca"
          >
            <DatePicker
              value={formData.due_date}
              onChange={(newValue) => {
                setFormData((prevValue) => ({
                  ...prevValue,
                  due_date: newValue,
                }));
                setDueDateError(false);
              }}
              label={t("due_date")}
              sx={{ width: "100%" }}
              minDate={dayjs(new Date())}
              slotProps={{
                textField: {
                  error: dueDateError,
                  required: true,
                },
              }}
            />
          </LocalizationProvider>
        )}
        {formData.promise_type === PROMISE_TYPE.RECURRING && (
          <FormControl fullWidth required>
            <InputLabel>{t("frequency")}</InputLabel>
            <Select
              name="payment_frequency"
              value={formData.payment_frequency}
              onChange={handleChange}
              label={t("frequency")}
            >
              <MenuItem value={PAYMENT_FREQUENCY.DAILY}>{t("daily")}</MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.WEEKLY}>
                {t("weekly")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.EVERY_2_WEEKS}>
                {t("every_2_weeks")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.MONTHLY}>
                {t("monthly")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.EVERY_2_MONTHS}>
                {t("every_2_months")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.QUARTERLY}>
                {t("quarterly")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.EVERY_4_MONTHS}>
                {t("every_4_months")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.BIMONTHLY}>
                {t("bimonthly")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.SEMI_ANNUALLY}>
                {t("semi_annually")}
              </MenuItem>
              <MenuItem value={PAYMENT_FREQUENCY.ANNUALLY}>
                {t("annually")}
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
      <div>
        <NumericFormat
          fullWidth
          value={formData.promise_amount}
          onChange={handleChange}
          customInput={TextField}
          thousandSeparator
          valueIsNumericString
          decimalScale={2}
          required
          name="promise_amount"
          prefix="$ "
          label={t("promise_amount")}
        />
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <Button variant="outlined" color="error" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          loading={createPromiseMutation.isPending}
        >
          {t("save")}
        </Button>
      </div>
    </form>
  );
};

export default CreatePromise;
