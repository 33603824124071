import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./context/AuthContext/AuthProvider";
import { SuccessAlertProvider } from "./context/SuccessAlertContext/SuccessAlertContext";
import "./index.css";
import { ThemeProvider } from "@mui/material/styles";
import { colors } from "./material-ui-utils/colors";
import { HiddenViewProvider } from "./context/HiddenViewContext/HiddenViewContext";
import { LoadingProvider } from "./context/LoadingContext/LoadingContext";
import "./Translation/i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoadingProvider>
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={colors}>
          <SuccessAlertProvider>
            <HiddenViewProvider>
              <App />
            </HiddenViewProvider>
          </SuccessAlertProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  </LoadingProvider>
);
