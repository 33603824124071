import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Step,
  Stepper,
  StepLabel,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import { validCurrencyAmount, validEmail, validName } from "../../utils";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const steps = [
  "import_donation_step_instruction",
  "import_donation_step_select_donation_file",
  "import_donation_step_inprogress",
  "import_donation_step_completed",
];

const requiredFields = ["nom", "prenom", "courriel", "montant"];

const SelectFileStep = ({
  setCanNextStep,
  setDataToImport,
  setSelectedCampaign,
  selectedCampaign,
}) => {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const [file, setFile] = useState(null);
  const [dataPreview, setDataPreview] = useState([]);
  const [processingData, setProcessingData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [errorReport, setErrorReport] = useState({});
  const [messages, setMessage] = useState({
    warning: [],
    error: [],
  });
  const { t } = useCustomTranslation("Import Donation Popup");

  useEffect(() => {
    setCanNextStep(false);
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (file) loadFile();
  }, [file]);

  useEffect(() => {
    if (file && selectedCampaign) {
      setCanNextStep(true);
    }
  }, [file, selectedCampaign]);

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const response = await api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id
      );

      if (response.status === 200) {
        setCampaigns(response.data);
      }
    } catch (e) {
      setLoadingError(true);
      console.error(e);
    }
    setLoading(false);
  };

  const addMessage = (type, message) => {
    const newMessage = { ...messages };
    newMessage[type].push(message);
    setMessage(newMessage);
  };

  const clearMessage = () => {
    setMessage({
      warning: [],
      error: [],
    });
  };

  const clearErrorReport = () => {
    setErrorReport({});
  };

  const removeFile = () => {
    document.getElementById("fileinput").value = null;
    setFile(null);
  };

  const handleSelectFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      var re = /(\.csv|\.xls|\.xlsx)$/i;

      if (!re.exec(e.target.files[0].name)) {
        alert("Extension du fichié par supporté");
      } else {
        clearMessage();
        clearErrorReport();
        setFile(e.target.files[0]);
      }
    }
  };

  const loadFile = () => {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const fileData = e.target.result;

      const workbook = XLSX.read(
        fileData,
        { type: "binary" },
        { dateNF: "dd/mm/yyyy" }
      );

      if (workbook.SheetNames.length > 1)
        addMessage(
          "warning",
          t(
            "import_donation_step_loadfile_campaign_severalExcelSheets_detected_error_text"
          )
        );

      const result = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { raw: false }
      );

      if (
        !hasValidColumns(result[0]) ||
        Object.keys(result[0]).length !== requiredFields.length
      ) {
        addMessage(
          "error",
          t(
            "import_donation_step_loadfile_campaign_invalidFileFormat_detected_error_text"
          )
        );
        removeFile();
        return;
      }

      const validationResult = hasValidData(result);

      if (Object.keys(validationResult).length > 0) {
        addMessage(
          "error",
          t(
            "import_donation_step_loadfile_campaign_invalidCells_detected_error_text"
          )
        );
        setErrorReport(validationResult);
        removeFile();
        return;
      }

      setDataToImport(result);
      setDataPreview(sanitizePreviewData(result));
    };

    fileReader.readAsBinaryString(file);
  };

  const sanitizePreviewData = (result) => {
    const returnData = [];
    let dataToUse = [...result];
    dataToUse = dataToUse.splice(0, 30);

    for (const row of dataToUse) {
      const rowData = {};

      for (const [key, value] of Object.entries(row)) {
        const cleanedKey = sanitizeKeyName(key);

        if (cleanedKey == "nom") {
          rowData["nom"] = value;
        } else if (cleanedKey == "prenom") {
          rowData["prenom"] = value;
        } else if (cleanedKey == "courriel") {
          rowData["courriel"] = value;
        } else if (cleanedKey == "montant") {
          rowData["montant"] = value;
        }
      }

      returnData.push(rowData);
    }

    return returnData;
  };

  const sanitizeKeyName = (key) => {
    return key
      .toLowerCase()
      .trim()
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const hasValidData = (sheetData) => {
    setProcessingData(true);
    const invalidValues = {};

    for (const row of sheetData) {
      for (const [key, value] of Object.entries(row)) {
        const cleanedKey = sanitizeKeyName(key);

        if (cleanedKey == "nom" && !validName(value)) {
          if (!invalidValues["nom"]) invalidValues["nom"] = [];

          invalidValues["nom"].push(value);
        } else if (cleanedKey == "prenom" && !validName(value)) {
          if (!invalidValues["prenom"]) invalidValues["prenom"] = [];

          invalidValues["prenom"].push(value);
        } else if (cleanedKey == "courriel" && !validEmail(value)) {
          if (!invalidValues["courriel"]) invalidValues["courriel"] = [];

          invalidValues["courriel"].push(value);
        } else if (cleanedKey == "montant" && !validCurrencyAmount(value)) {
          if (!invalidValues["montant"]) invalidValues["montant"] = [];

          invalidValues["montant"].push(value);
        }
      }
    }

    setProcessingData(false);
    return invalidValues;
  };

  const hasValidColumns = (firstRowSheetData) => {
    let validCount = 0;

    for (const [key, value] of Object.entries(firstRowSheetData)) {
      for (const field of requiredFields) {
        if (field == sanitizeKeyName(key)) {
          validCount++;
          break;
        }
      }
    }

    return validCount === requiredFields.length ? true : false;
  };

  const handleCampaignSelect = (e) => {
    setSelectedCampaign(e.target.value);
  };

  return (
    <div className="space-y-4">
      <LoadingContainer loading={loading} error={loadingError}>
        <div className="flex flex-col space-y-4">
          <FormControl fullWidth className="mb-">
            <InputLabel required>
              {t("import_donation_step_loadfile_campaign_choice_text")}
            </InputLabel>
            <Select
              value={selectedCampaign._id}
              label={t("import_donation_step_loadfile_campaign_choice_text")}
              onChange={handleCampaignSelect}
              fullWidth
              required
              defaultValue={""}
            >
              {campaigns.map((campaign) => (
                <MenuItem key={campaign._id} value={campaign._id}>
                  <div className="flex flex-col">{campaign.name}</div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <label>
              {t("import_donation_step_loadfile_campaign_fileguide_text1")}{" "}
              <strong>.xlsx</strong>, <strong>.xls</strong>{" "}
              {t("import_donation_step_loadfile_campaign_fileguide_text2")}{" "}
              <strong>.csv</strong>
            </label>
            <div>
              <Button variant="contained" component="label">
                {t("import_donation_step_loadfile_campaign_chooseFile_text")}
                <input
                  type="file"
                  hidden
                  accept=".xlsx, .xls, .csv"
                  onChange={handleSelectFile}
                  id="fileinput"
                />
              </Button>
              <label className="ml-4 text-gray-500">
                {file?.name
                  ? file.name
                  : t(
                      "import_donation_step_loadfile_campaign_nofile_selected_text"
                    )}
              </label>
            </div>
          </div>
        </div>
        <div>
          {messages.warning.length > 0 ? (
            <div>
              <div
                className="w-fit px-4 text-white"
                style={{ backgroundColor: `${theme.palette.warning.main}` }}
              >
                {t("import_donation_step_loadfile_campaign_warning_text")}
              </div>
              <div className={`flex flex-1 flex-col`}>
                <ul className="ml-4 list-inside list-disc">
                  {messages.warning.map((message, index) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
          {messages.error.length > 0 ? (
            <div className="mt-4">
              <div
                className="w-fit px-4 text-white"
                style={{ backgroundColor: `${theme.palette.error.main}` }}
              >
                {t("import_donation_step_loadfile_campaign_error_text")}
              </div>
              <div className="flex flex-1 flex-col">
                <ul className="ml-4 list-inside list-disc">
                  {messages.error.map((message, index) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Divider />
        <div className="flex flex-1 flex-col items-center justify-center">
          {!file && Object.keys(errorReport).length === 0
            ? t("import_donation_step_loadfile_campaign_noOverview_text")
            : ""}
          {Object.keys(dataPreview).length > 0 ? (
            <div>
              {t("import_donation_step_loadfile_campaign_partialOverview_text")}
              <table className="mt-4 w-full table-fixed [&_*]:w-full">
                <thead className="[&_*]:break-words">
                  <tr className="[&>th]:border [&>th]:border-black [&>th]:pl-3">
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Courriel</th>
                    <th>Montant</th>
                  </tr>
                </thead>
                <tbody className="flex-col [&_*]:break-words">
                  {dataPreview?.map((row, index) => {
                    return (
                      <tr
                        key={row["nom"] + index}
                        className="[&>td]:border [&>td]:border-black [&>td]:pl-3"
                      >
                        <td>{row.nom}</td>
                        <td>{row.prenom}</td>
                        <td>{row.courriel}</td>
                        <td>{row.montant}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
          <LoadingContainer
            message={"Validation des données en cours"}
            loading={processingData}
          />
          {Object.keys(errorReport).length > 0 ? (
            <div className="flex w-full flex-1 flex-col">
              <div
                className="w-fit px-4 text-white"
                style={{ backgroundColor: `${theme.palette.error.main}` }}
              >
                {t(
                  "import_donation_step_loadfile_campaign_errordCells_values_error_text"
                )}{" "}
              </div>
              {errorReport?.nom?.length > 0 ? (
                <div className="flex flex-1 flex-col">
                  <label className="text-lg font-semibold">
                    {t("import_donation_step_loadfile_campaign_column_text")}{" "}
                    Nom:
                  </label>
                  {errorReport.nom.map((field, index) => (
                    <ul
                      className="ml-10 list-inside list-disc"
                      key={field + index}
                    >
                      <li>{field}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {errorReport?.prenom?.length > 0 ? (
                <div className="flex flex-1 flex-col">
                  <label className="text-lg font-semibold">
                    {t("import_donation_step_loadfile_campaign_column_text")}{" "}
                    Prénom:
                  </label>
                  {errorReport.prenom.map((field, index) => (
                    <ul
                      className="ml-10 list-inside list-disc"
                      key={field + index}
                    >
                      <li>{field}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {errorReport?.courriel?.length > 0 ? (
                <div className="flex flex-1 flex-col">
                  <label className="text-lg font-semibold">
                    {t("import_donation_step_loadfile_campaign_column_text")}{" "}
                    Courriel:
                  </label>
                  {errorReport.courriel.map((field, index) => (
                    <ul
                      className="ml-10 list-inside list-disc"
                      key={field + index}
                    >
                      <li>{field}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {errorReport?.montant?.length > 0 ? (
                <div className="flex flex-1 flex-col">
                  <label className="text-lg font-semibold">
                    {t("import_donation_step_loadfile_campaign_column_text")}{" "}
                    Montant:
                  </label>
                  {errorReport.montant.map((field, index) => (
                    <ul
                      className="ml-10 list-inside list-disc"
                      key={field + index}
                    >
                      <li>{field}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </LoadingContainer>
    </div>
  );
};

const InstructionsStep = () => {
  const { t } = useCustomTranslation("Import Donation Popup");
  return (
    <div className="flex flex-1 flex-col justify-center space-y-4 px-32">
      <p>{t("import_donation_step_instruction_guide1")}</p>
      <ul className="ml-10 list-inside list-disc">
        <li>Nom</li>
        <li>Prénom</li>
        <li>Courriel</li>
        <li>Montant</li>
      </ul>
      <p>{t("import_donation_step_instruction_guide2")}</p>
      <p>{t("import_donation_step_instruction_guide3")}</p>
      <div className="pt-4">
        <label className="text-lg font-semibold">
          {t("import_donation_step_instruction_valid_example_text")}
        </label>
        <div className="mt-4">
          <table className="w-full table-fixed [&_*]:w-full">
            <thead className="[&_*]:break-words">
              <tr className="[&>th]:border [&>th]:border-black [&>th]:pl-3">
                <th>Nom</th>
                <th>Prénom</th>
                <th>Courriel</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody className="flex-col [&_*]:break-words">
              <tr className="[&>td]:border [&>td]:border-black [&>td]:pl-3">
                <td>Fausto</td>
                <td>Alim</td>
                <td>FaustoAlim@email.com</td>
                <td>424.32</td>
              </tr>
              <tr className="[&>td]:border [&>td]:border-black [&>td]:pl-3">
                <td>Shaban</td>
                <td>Matti</td>
                <td>ShabanMatti@email.com</td>
                <td>199.21</td>
              </tr>
              <tr className="[&>td]:border [&>td]:border-black [&>td]:pl-3">
                <td>Mariana</td>
                <td>Hailwic</td>
                <td>MarianaHailwic@email.com</td>
                <td>27</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ImportingStep = ({
  dataToImport,
  setImportError,
  setImportMessage,
  nextStep,
  selectedCampaign,
}) => {
  const authContext = useContext(AuthContext);
  const { t } = useCustomTranslation("Import Donation Popup");

  useEffect(() => {
    handleImport();
  }, []);

  const handleImport = async () => {
    try {
      const response = await api_endpoints.importDonations(
        dataToImport,
        selectedCampaign,
        authContext.authContext.accessToken.organization_id,
        authContext.authContext.accessToken.id
      );

      if (response.status !== 200) {
        setImportError(true);
      }

      setImportMessage(t(response.data));

      nextStep();
    } catch (e) {
      setImportError(true);
      nextStep();
    }
  };
  return (
    <LoadingContainer
      message={t("import_donation_importInProgress_text")}
      loading={true}
    />
  );
};

const DoneStep = ({ importError, importMessage }) => {
  const theme = useTheme();
  return (
    <div
      className={`flex flex-1 flex-col items-center justify-center font-semibold`}
      style={{
        color: importError
          ? theme.palette.error.main
          : theme.palette.success.main,
      }}
    >
      <div>{importMessage}</div>
      <div>{importError ? "Veuillez réessayer plus tard." : ""}</div>
    </div>
  );
};

const DonationImportation = ({ open, onClose, onOpen }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [canNextStep, setCanNextStep] = useState(true);
  const [dataToImport, setDataToImport] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [importError, setImportError] = useState(false);
  const [importMessage, setImportMessage] = useState("");
  const { t } = useCustomTranslation("Import Donation Popup");

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
    }
  }, [open]);

  const nextStep = () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);

    if (nextStep >= steps.length) {
      onClose(false);
    }
  };

  const previousStep = () => {
    const previousStep = activeStep - 1;
    setActiveStep(previousStep);

    if (previousStep !== 1) {
      setCanNextStep(true);
    }
  };

  const handleContinueClick = () => {
    nextStep();
  };

  const handlePreviousClick = () => {
    previousStep();
  };

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle>{t("import_donation_title")}</DialogTitle>
      <DialogContent className="flex flex-1 flex-col">
        <Stepper activeStep={activeStep} className="mb-10">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? <InstructionsStep /> : <></>}
        {activeStep === 1 ? (
          <SelectFileStep
            setCanNextStep={setCanNextStep}
            setDataToImport={setDataToImport}
            setSelectedCampaign={setSelectedCampaign}
            selectedCampaign={selectedCampaign}
          />
        ) : (
          <></>
        )}
        {activeStep === 2 ? (
          <ImportingStep
            nextStep={nextStep}
            dataToImport={dataToImport}
            selectedCampaign={selectedCampaign}
            setImportError={setImportError}
            setImportMessage={setImportMessage}
          />
        ) : (
          <></>
        )}
        {activeStep === 3 ? (
          <DoneStep importError={importError} importMessage={importMessage} />
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="error"
          disabled={activeStep === 2 || activeStep === 3}
        >
          {t("import_donation_cancel_button")}
        </Button>
        <Button
          onClick={handlePreviousClick}
          variant="outlined"
          disabled={activeStep === 0 || activeStep === 2 || activeStep === 3}
        >
          {t("import_donation_return_button")}
        </Button>
        <Button
          variant="contained"
          onClick={handleContinueClick}
          disabled={activeStep === 2 || !canNextStep}
        >
          {activeStep === steps.length - 1
            ? t("import_donation_close_button")
            : t("import_donation_continue_button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DonationImportation;
