import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import PersonalizedPeriodPopup from "../../components/Dashboard/PersonalizedPeriodPopup";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const PredefinedPeriods = ({
  selectedPeriod = "",
  setSelectedPeriod,
  setpersonalizedDates,
}) => {
  const { t } = useCustomTranslation("Tableau de bord");
  const [modalOpen, setModalOpen] = useState(false);
  const today = dayjs(new Date());
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [startTime, setStartTime] = useState(dayjs().startOf("day"));
  const [endTime, setEndTime] = useState(dayjs().endOf("day"));
  const predefinedPeriods = [
    { name: t("dashboard_periode_today"), value: "today" },
    { name: t("dashboard_periode_yesterday"), value: "yesterday" },
    { name: t("dashboard_periode_thisweek"), value: "this_week" },
    { name: t("dashboard_periode_lastweek"), value: "last_week" },
    { name: t("dashboard_periode_thismonth"), value: "this_month" },
    { name: t("dashboard_periode_lastmonth"), value: "last_month" },
    { name: t("dashboard_periode_3months"), value: "last_3_months" },
    { name: t("dashboard_periode_5months"), value: "last_5_months" },
    { name: t("dashboard_periode_thisyear"), value: "this_year" },
    { name: t("dashboard_periode_lastyear"), value: "last_year" },
    { name: t("dashboard_periode_custom"), value: "personalized_period" },
  ];

  const getDateRange = (selectedPeriod) => {
    let startDate, endDate;
    let startTime = dayjs().startOf("day");
    let endTime = dayjs().endOf("day");
    switch (selectedPeriod) {
      case "today":
        startDate = dayjs().format("YYYY-MM-DD");
        endDate = dayjs().format("YYYY-MM-DD");
        break;
      case "yesterday":
        startDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        endDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "this_week":
        startDate = dayjs().startOf("week").format("YYYY-MM-DD");
        endDate = dayjs().endOf("week").format("YYYY-MM-DD");
        break;
      case "last_week":
        startDate = dayjs()
          .subtract(1, "week")
          .startOf("week")
          .format("YYYY-MM-DD");
        endDate = dayjs()
          .subtract(1, "week")
          .endOf("week")
          .format("YYYY-MM-DD");
        break;
      case "this_month":
        startDate = dayjs().startOf("month").format("YYYY-MM-DD");
        endDate = dayjs().format("YYYY-MM-DD");
        break;
      case "last_month":
        startDate = dayjs()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = dayjs()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "last_3_months":
        startDate = dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = dayjs()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "last_5_months":
        startDate = dayjs()
          .subtract(5, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = dayjs()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "this_year":
        startDate = dayjs().startOf("year").format("YYYY-MM-DD");
        endDate = dayjs().format("YYYY-MM-DD");
        break;
      case "last_year":
        startDate = dayjs()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        endDate = dayjs()
          .subtract(1, "year")
          .endOf("year")
          .format("YYYY-MM-DD");
        break;
      default:
        startDate = dayjs().format("YYYY-MM-DD");
        endDate = dayjs().format("YYYY-MM-DD");
    }

    return { startDate, endDate, startTime, endTime };
  };

  const handlePeriodChange = (event) => {
    const selectedPeriod = event.target.value;
    setSelectedPeriod(selectedPeriod); // Update selected period state

    if (selectedPeriod === "personalized_period") {
      setModalOpen(true); //  Open personalized period modal
    } else {
      const { startDate, endDate, startTime, endTime } =
        getDateRange(selectedPeriod);
      handleSubmitModal(startDate, endDate, startTime, endTime);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitModal = (startDate, endDate, starttime, endtime) => {
    setpersonalizedDates(dayjs(startDate), dayjs(endDate), starttime, endtime);
    setModalOpen(false);
  };

  return (
    <>
      <Select
        value={selectedPeriod}
        onChange={handlePeriodChange}
        size="small"
        variant="outlined"
        className="w-40"
        displayEmpty
        fullWidth
      >
        {predefinedPeriods.map((period, index) => {
          if (index !== predefinedPeriods.length - 1)
            return (
              <MenuItem key={period.value} value={period.value}>
                {period.name}
              </MenuItem>
            );
          else {
            return (
              <MenuItem
                key={period.value}
                value={period.value}
                onClick={() =>
                  handlePeriodChange({ target: { value: period.value } })
                }
              >
                {period.name}
              </MenuItem>
            );
          }
        })}
      </Select>
      {modalOpen && (
        <PersonalizedPeriodPopup
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          handleSubmitModal={handleSubmitModal}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
        />
      )}
    </>
  );
};

export default PredefinedPeriods;
