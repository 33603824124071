import { getPageFromName, PAGES } from "../../route_utils";
import {
  Divider,
  TextField,
  Tab,
  Tabs,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormController from "../../widgets/FormController/FormController";
import api_endpoints from "../../api";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useState, useEffect } from "react";
import OTPModal from "../../widgets/OTPModal/OTPModal";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import TabWrapper from "../../widgets/TabWrapper/TabWrapper";
import SwitchWrapper from "../SwitchWrapper/SwitchWrapper";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const CreateOrganization = () => {
  const { setShowSuccessAlert, setMessageSuccessAlert } = useSuccessAlert();
  const navigate = useNavigate();
  const [country, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryLoading, setCountryLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const { t } = useCustomTranslation("Créer une organisation");

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    setLoading(true);
    const postData = { ...formData };
    //if (postData.vopay.is_active === false) delete postData["vopay"];
    try {
      let response = await api_endpoints.createOrganization(
        postData,
        selectedCountry.id
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);

        let otpResponse = await api_endpoints.createUserOTP(
          postData.email,
          false,
          false,
          true
        );

        if (otpResponse.status === 200) {
          setOTPCode(otpResponse.data.otp);
          setModalOpen(true);
        }
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    navigate(getPageFromName("Organisations").path);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const fetchCountries = async () => {
    setCountryLoading(true);

    try {
      let response = await api_endpoints.getCountries();
      if (response.status === 200) {
        const formattedCountries = response.data.map((country) => ({
          id: country._id,
          label: `${country.name} (${country.code})`,
        }));
        setCountries(formattedCountries);
      }
    } catch (error) {
      console.error("Error fetching Countries", error);
    } finally {
      setCountryLoading(false);
    }
  };

  const handleCountrySelect = (event, value) => {
    setSelectedCountry(value);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <>
      <OTPModal
        title={t("org_opt_title")}
        message={t("org_opt_message")}
        OTPCode={OTPCode}
        open={modalOpen}
        onClose={handleCloseModal}
      />
      <div className="flex flex-1 flex-col mt-4">
        <FormController
          initialValues={{
            legal_name: "",
            address: "",
            phone: "",
            tax_receipt: "",
            federal_tax_number: "",
            payment_info: "",
            first_name: "",
            last_name: "",
            email: "",
            merchant_id: "",
            // is_same_location: true,
            is_surcharge_active: false,
            surcharge_rate: 0,
            // vopay: {
            //   account_id: "",
            //   api_key: "",
            //   shared_secret: "",
            //   url: "",
            //   is_active: false,
            // },
          }}
        >
          {({ value, handleChange, getFormFields }) => (
            <form onSubmit={(e) => handleSubmit(e, getFormFields())}>
              <div className="container-white flex-col">
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label={t("details_tab")} />
                  <Tab label={t("clover_settings_tab")} />
                  {/* <Tab label="Paramètres VoPay" /> */}
                </Tabs>
                <Divider />
                <TabWrapper
                  index={0}
                  value={tabValue}
                  className="flex flex-1 space-y-6 flex-col mt-4"
                >
                  {/* <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value.is_same_location}
                          onChange={handleChange}
                          name={"is_same_location"}
                        />
                      }
                      label={
                        "Créer un emplacement avec les coordonnées de l'organisation"
                      }
                    />
                  </FormGroup> */}
                  <TextField
                    fullWidth
                    required
                    autoComplete="off"
                    variant="outlined"
                    label={t("org_legalname")}
                    value={value.legal_name}
                    name="legal_name"
                    onChange={handleChange}
                  />
                  <Autocomplete
                    options={country}
                    getOptionLabel={(option) =>
                      option && option.label ? option.label : ""
                    }
                    onChange={handleCountrySelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          countryLoading
                            ? t("org_country_select_loading")
                            : t("org_country_select")
                        }
                        required
                      />
                    )}
                    value={selectedCountry}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : -1)
                    }
                    renderTags={() => null}
                    disableClearable
                    name="country"
                    disabled={countryLoading}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={t("org_adress")}
                    autoComplete="off"
                    value={value.address}
                    name="address"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={t("org_telephone")}
                    autoComplete="off"
                    value={value.phone}
                    name="phone"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("org_federal_tax_nbr")}
                    autoComplete="off"
                    value={value.federal_tax_number}
                    name="federal_tax_number"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("org_payment_info")}
                    autoComplete="off"
                    value={value.payment_info}
                    name="payment_info"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("org_tax_receipt")}
                    autoComplete="off"
                    value={value.tax_receipt}
                    name="tax_receipt"
                    onChange={handleChange}
                  />
                  <SwitchWrapper
                    label={t("org_is_surcharge_active")}
                    checked={value.is_surcharge_active}
                    required={value.is_surcharge_active}
                    name="is_surcharge_active"
                    onChange={handleChange}
                  >
                    <TextField
                      required
                      fullWidth
                      type="number"
                      variant="outlined"
                      label={t("org_surcharge_fee")}
                      placeholder={t("org_surcharge_fee")}
                      name="surcharge_rate"
                      onChange={(e) => {
                        e.target.value /= 100;
                        handleChange(e);
                      }}
                      value={value.surcharge_rate * 100}
                      InputProps={{
                        startAdornment: <div className="mr-4">%</div>,
                      }}
                    />
                  </SwitchWrapper>
                  <Divider />
                  <div className="text-lg">{t("org_contact_header")}</div>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={t("org_contact_firstname")}
                    autoComplete="off"
                    value={value.first_name}
                    name="first_name"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={t("org_contact_lastname")}
                    autoComplete="off"
                    value={value.last_name}
                    name="last_name"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={t("org_contact_email")}
                    autoComplete="off"
                    value={value.email}
                    name="email"
                    onChange={handleChange}
                  />
                </TabWrapper>
                <TabWrapper
                  index={1}
                  value={tabValue}
                  className="flex flex-1 space-y-6 flex-col mt-4"
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t("org_merchant_id")}
                    autoComplete="off"
                    value={value.merchant_id}
                    name="merchant_id"
                    onChange={handleChange}
                  />
                </TabWrapper>
                {/* <TabWrapper
                  index={2}
                  value={tabValue}
                  className="flex flex-1 space-y-6 flex-col mt-4"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value.vopay.is_active}
                          onChange={handleChange}
                          name="vopay.is_active"
                        />
                      }
                      label="Activer VoPay"
                    />
                  </FormGroup>
                  <Alert severity="info">
                    Les informations entrées ne seront pas visibles une fois
                    sauvegardé.
                  </Alert>
                  <PasswordInput
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    label="Account ID"
                    autoComplete="off"
                    value={value.vopay.account_id}
                    name="vopay.account_id"
                    onChange={handleChange}
                    disabled={!value.vopay.is_active}
                    autocomplete="off"
                  />
                  <PasswordInput
                    fullWidth
                    required
                    variant="outlined"
                    label="API Shared Secret"
                    autoComplete="off"
                    value={value.vopay.shared_secret}
                    name="vopay.shared_secret"
                    onChange={handleChange}
                    disabled={!value.vopay.is_active}
                    autocomplete="off"
                  />
                  <PasswordInput
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    label="API Key"
                    autoComplete="off"
                    value={value.vopay.api_key}
                    name="vopay.api_key"
                    onChange={handleChange}
                    disabled={!value.vopay.is_active}
                    autocomplete="off"
                  />
                  <PasswordInput
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    label="URL"
                    autoComplete="off"
                    value={value.vopay.url}
                    name="vopay.url"
                    onChange={handleChange}
                    disabled={!value.vopay.is_active}
                    autocomplete="off"
                  />
                </TabWrapper> */}
              </div>
              <div className="flex flex-1 mt-2 justify-end space-x-4">
                <CancelButton
                  disabled={loading}
                  label={t("org_cancel_button")}
                />
                <ButtonLoading
                  loading={loading}
                  variant="contained"
                  type="submit"
                >
                  {t("org_create_button")}
                </ButtonLoading>
              </div>
            </form>
          )}
        </FormController>
      </div>
    </>
  );
};

export default CreateOrganization;
