import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import AuthContext from "../../context/AuthContext/AuthContext";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";
import api_endpoints from "../../api";
import { NumericFormat } from "react-number-format";
import { formatDateTime } from "../../utils";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { PAYMENT_TYPE } from "../../enums/PromiseEnums";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const AddPromiseAmount = ({ onClose, promise_id, payment_type }) => {
  const { t } = useCustomTranslation("Create Promise");
  const authContext = useContext(AuthContext);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  const [formData, setFormData] = useState({
    payment_type: payment_type ?? "",
    organization_id: authContext.authContext.accessToken.organization_id,
    cheque_number: "",
    amount: "",
    donation_date: dayjs(new Date()),
  });

  const [errors, setErrors] = useState({
    amount: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      setErrors((prevErrors) => ({ ...prevErrors, amount: false }));
    }

    setFormData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const addPromiseCashMutation = useApiClientMutation(
    api_endpoints.addAmountToCampaignPromise,
    [`view_promise_transactions`],
    (data) => {
      setMessageSuccessAlert(data);
      setShowSuccessAlert(true);
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.amount) {
      setErrors((prevErrors) => ({ ...prevErrors, amount: true }));
      return;
    }

    addPromiseCashMutation.mutate({
      campaign_promise_id: promise_id,
      amount: formData.amount.replace(/[^0-9.]/g, "") ?? 0,
      inserted_by: authContext.authContext.accessToken.id,
      donation_date: formatDateTime(formData.donation_date),
      payment_type: formData.payment_type,
      organization_id: formData.organization_id,
      cheque_number: formData.cheque_number,
    });

    onClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-4 my-4">
        <FormControl fullWidth required>
          <InputLabel>{t("payment_type")}</InputLabel>
          <Select
            name="payment_type"
            value={formData.payment_type}
            onChange={handleChange}
            label={t("payment_type")}
          >
            <MenuItem value={PAYMENT_TYPE.CREDIT_CARD}>
              {t("credit_card")}
            </MenuItem>
            <MenuItem value={PAYMENT_TYPE.CHEQUE}>{t("cheque")}</MenuItem>
            <MenuItem value={PAYMENT_TYPE.BANK_TRANSFER}>
              {t("bank_transfer")}
            </MenuItem>
            <MenuItem value={PAYMENT_TYPE.CASH}>{t("cash")}</MenuItem>
          </Select>
        </FormControl>
        {formData.payment_type === PAYMENT_TYPE.CHEQUE && (
          <TextField
            fullWidth
            label={t("cheque_number")}
            required
            name="cheque_number"
            value={formData.cheque_number}
            onChange={handleChange}
          />
        )}
        <NumericFormat
          fullWidth
          value={formData.amount}
          onChange={handleChange}
          customInput={TextField}
          thousandSeparator
          valueIsNumericString
          name="amount"
          prefix="$ "
          label={t("amount")}
          decimalScale={2} // Limits to 2 decimal places
          error={errors.amount} // Show error state
          helperText={errors.amount ? "Amount is Required" : ""}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ca">
          <DatePicker
            value={formData.donation_date}
            onChange={(newValue) =>
              setFormData((prevValue) => ({
                ...prevValue,
                donation_date: newValue,
              }))
            }
            label={t("date_of_donation")}
            sx={{ width: "100%" }}
            maxDate={dayjs(new Date())}
            slotProps={{
              textField: {
                error: false,
                required: true,
              },
            }}
          />
        </LocalizationProvider>
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <Button variant="outlined" color="error" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button variant="contained" type="submit">
          {t("save")}
        </Button>
      </div>
    </form>
  );
};

export default AddPromiseAmount;
