import { getPageFromName } from "../../route_utils";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AddIcon from "@mui/icons-material/Add";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { NavigationButton } from "../../widgets/NavigationButton/NavigationButton";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import axios from "axios";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const ManageKiosks = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [loadingStatusChange, setLoadingStatusChange] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [kioskDialog, setKioskDialog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [rows, setRows] = useState([]);
  const cancelTokenSource = useRef(null);
  const { t } = useCustomTranslation("Kiosks");
  const [tableFilter, setTableFilter] = useState({
    organization_id: authContext.authContext.accessToken.organization_id,
    kioskname: "",
    location: "",
  });
  const columns = [
    {
      field: "kioskName",
      headerName: t("kiosks_table_name"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "location",
      headerName: t("kiosks_table_location"),
      sortable: true,
      numberSort: false,
    },
  ];

  const filters = {
    kioskName: (
      <CustomTextField
        value={tableFilter.kioskname || ""}
        onChange={(e) => changeTableFilter("kioskname", e.target.value)}
        clearFilter={() => clearTableFilter("kioskname")}
      />
    ),
    location: (
      <CustomTextField
        value={tableFilter.location || ""}
        onChange={(e) => changeTableFilter("location", e.target.value)}
        clearFilter={() => clearTableFilter("location")}
      />
    ),
  };

  useEffect(() => {
    getData();
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to component unmount."
        );
      }
    };
  }, [tableFilter, authContext.authContext.accessToken.organization_id]);

  const changeTableFilter = (option, value) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: value }));
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const fetchKiosks = async () => {
    setLoadingStatusChange(true);
    setLoading(true);
    let response = await api_endpoints.getKiosks(
      tableFilter.organization_id,
      tableFilter.kioskname,
      tableFilter.location
    );

    if (response.status === 200) {
      setLoadingStatusChange(false);
      setLoading(true);
      return response.data.map((kiosk) => ({
        id: kiosk._id,
        kioskName: kiosk.name,
        location: kiosk.location.location_name,
      }));
    }
  };
  const getData = async () => {
    setLoading(true); // Set loading state to true before making the request
    setLoadingError(false); // Reset error state to false before making the request
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const data = await fetchKiosks(cancelTokenSource.current.token);
      setRows(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error(error);
        setLoadingError(true);
      }
    } finally {
      setLoading(false); // Set loading state to false after request completes or fails
    }
  };

  const showKioskDetails = (e) => {
    navigate(getPageFromName("Modifier un Kiosk").path, {
      state: { kiosk_id: e.id },
    });
  };

  const handleKioskStatus = async (kioskData) => {
    setLoadingStatusChange(true);
    try {
      const response = await api_endpoints.setKioskActiveStatus(
        kioskData._id,
        !kioskData.is_active
      );

      if (response.status === 200) {
        fetchKiosks();
        setIsDialogOpen(false);
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
      }
    } catch (e) {
      console.error(e);
    }

    setLoadingStatusChange(false);
  };

  return (
    <>
      <div className={"flex justify-end"}>
        <NavigationButton
          startIcon={<AddIcon />}
          text={t("kiosks_addKiosk_button")}
          path={getPageFromName("Créer un Kiosk").path}
        />
      </div>
      <Dialog
        fullWidth
        label="Détails du Kiosk"
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogTitle>Détails du Kiosk</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <AccessControl allowedPermissions={["is_super_user"]}>
              <div className="flex justify-end">
                <FormGroup>
                  <FormControlLabel
                    label={kioskDialog?.is_active ? "Activé" : "Désactivé"}
                    control={
                      <Switch
                        disabled={loadingStatusChange}
                        checked={kioskDialog?.is_active}
                        onChange={() => handleKioskStatus(kioskDialog)}
                      />
                    }
                  />
                </FormGroup>
              </div>
            </AccessControl>

            <h3 style={{ marginBottom: 0 }}>ID : {kioskDialog?._id}</h3>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Kiosk Name :</span>{" "}
              {kioskDialog?.name}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>License Key :</span>{" "}
              {kioskDialog?.license_key}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Location Name :</span>{" "}
              {kioskDialog?.location?.location_name}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Address :</span>{" "}
              {kioskDialog?.location?.address}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Person responsible :</span>{" "}
              {kioskDialog?.location?.name_of_person_responsible}
            </label>
            <label style={{ marginTop: 10 }}>
              <span style={{ fontSize: 18 }}>Phone :</span>{" "}
              {kioskDialog?.location?.phone}
            </label>
          </div>
        </DialogContent>
      </Dialog>

      <div className="flex flex-1 h-full mt-5 flex-col">
        <DataGrid
          columns={columns}
          rows={rows}
          showDetails={showKioskDetails}
          filters={filters}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ManageKiosks;
