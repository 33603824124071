import styles from "./ViewCampaign.module.css";
import PageTitle from "../../widgets/PageTitle/PageTitle";
import { getPageFromName, PAGES } from "../../route_utils";
import { useEffect, useState, useRef, useContext } from "react";
import { viewCampaignChartOption } from "../../EchartsUtils/ViewCampaignChartOptionBar";
import { tableStyles } from "../../TableStyle";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import api_endpoints from "../../api";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AuthContext from "../../context/AuthContext/AuthContext";
import ReactECharts from "echarts-for-react";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import { Button, TextField, Chip } from "@mui/material";
import "dayjs/locale/fr-ca";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { dateTimeReadable, numberWithCommas } from "../../utils";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { BASE_URL } from "../../api/constants";
import QRCode from "qrcode";

const ViewCampaign = () => {
  const echartRef = useRef(null);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [transactions, setTransaction] = useState([]);
  const [addCashDialogOpen, setAddCashDialogOpen] = useState(false);
  const [bornesInfoDialogOpen, setBornesInfoDialogOpen] = useState(false);
  const [loadingDataDialog, setLoadingDataDialog] = useState(true);
  const [loadingErrorDataDialog, setLoadingErrorDataDialog] = useState(false);
  const [cashAmountInput, setCashAmountInput] = useState("");
  const [transactionsStats, setTransactionsStats] = useState({});
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [cashTableData, setCashTableData] = useState([]);
  const [bornesData, setBornesData] = useState([]);
  const [selectedBorneData, setSelectedBorneData] = useState({});
  const [locationsDialog, setLocationsDialog] = useState([]);
  const [donationDate, setDonationDate] = useState("");
  const [locationId, setLocationId] = useState("");
  const [totalCollected, setTotalCollected] = useState(0);
  const [dateFilter, setDateFilter] = useState(dayjs());
  const { t } = useCustomTranslation("View Campaign");

  const columns = [
    {
      name: t("cash_amount"),
      selector: (row) => row.cashAmount,
      sortable: true,
    },
    {
      name: t("inserted_on"),
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: t("inserted_by"),
      selector: (row) => row.inserted_by,
      sortable: true,
    },
    {
      name: t("date_of_donation"),
      selector: (row) => row.date_donated,
      sortable: true,
    },
  ];

  const columnsBornes = [
    {
      name: t("kiosk_name"),
      selector: (row) => row.kiosk_name,
      sortable: true,
    },
    {
      name: t("amount_raised"),
      selector: (row) => row.amount_collected,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (dateFilter) fetchCampaignData();
  }, [dateFilter]);

  useEffect(() => {
    fetchCampaignTransactionsData();
  }, [campaignData]);

  const fetchCampaignData = async () => {
    setLoading(true);

    try {
      const date = `${dateFilter.get("year")}-${(dateFilter.get("month") + 1)
        .toString()
        .padStart(2, "0")}-${dateFilter
        .get("date")
        .toString()
        .padStart(2, "0")}`;

      let response = await api_endpoints.getCampaign(state.campaign_id, date);

      if (response.status === 200) {
        setCampaignData(response.data.campaign);
        setBornesData(response.data.bornes);

        mapBorneData(response.data.bornes);
        mapCashDataTable(response.data.cashTransactions);
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }
  };

  const fetchCampaignTransactionsData = async () => {
    const date = `${dateFilter.get("year")}-${(dateFilter.get("month") + 1)
      .toString()
      .padStart(2, "0")}-${dateFilter.get("date").toString().padStart(2, "0")}`;

    let response = await api_endpoints.getCampaignsTransacitons(
      state.campaign_id,
      date
    );

    let responseTransactionsStats =
      await api_endpoints.getCampaignsTransacitonsStats(
        state.campaign_id,
        date
      );

    if (response.status === 200) {
      getTotalCollected(response.data);
      mapChartData(response.data);
      onResizeEvent();
    }

    if (responseTransactionsStats.status === 200) {
      setTransactionsStats(responseTransactionsStats.data);
    }

    setLoading(false);
  };

  const getTotalCollected = (transactions) => {
    let total = 0;

    for (const [key, value] of Object.entries(transactions)) {
      for (let transaction of transactions[key]) {
        total += transaction.total;
      }
    }

    setTotalCollected(total);
  };

  const mapChartData = (transactions) => {
    let dataCash = [];
    let dataTransactions = [];
    let option = { ...viewCampaignChartOption };

    for (const cardTransaction of transactions.transactions) {
      dataTransactions.push([cardTransaction._id.date, cardTransaction.total]);
    }

    for (const cashTransaction of transactions.cashDonations) {
      dataCash.push([cashTransaction._id.date, cashTransaction.total]);
    }

    option.title.text = `${t("fundes_raised_for")} ${campaignData?.name}`;
    option.series[0].data = dataTransactions;
    option.series[1].data = dataCash;

    setChartData({ ...option });
  };

  const mapBorneData = (bornes) => {
    let data = [];

    for (let borne of bornes) {
      data.push({
        id: borne._id,
        kiosk_name: borne.doc.borne.name,
        location: borne.doc.borne.location.address,
        amount_collected: borne.total,
      });
    }

    setTransactionData(data);
  };

  const mapCashDataTable = (tableData) => {
    let data = [];

    for (let transaction of tableData) {
      data.push({
        cashAmount: transaction.transaction.amount,
        date: dateTimeReadable(transaction.transaction.created_at),
        date_donated: dateTimeReadable(transaction.date_of_donation),
        inserted_by: `${transaction.user.last_name} ${transaction.user.first_name}`,
      });
    }

    setCashTableData(data);
  };

  const onResizeEvent = () => {
    window.addEventListener("resize", () => {
      echartRef?.current?.getEchartsInstance().resize();
    });
  };

  const handleAddCashToCampaign = async (e) => {
    e.preventDefault();

    let response = await api_endpoints.addCashToCampaign(
      state.campaign_id,
      cashAmountInput,
      authContext.authContext.accessToken.id,
      authContext.authContext.accessToken.organization_id,
      donationDate
    );

    if (response.status === 200) {
      setAddCashDialogOpen(false);
      setCashAmountInput("");
      setMessageSuccessAlert(response.data);
      setShowSuccessAlert(true);
      fetchCampaignData();
    }
  };

  const handleArchiveCampaign = async () => {
    let response = await api_endpoints.setArchiveCampaignStatus(
      campaignData._id,
      !campaignData?.is_archived
    );

    if (response.status === 200) {
      setMessageSuccessAlert(response.data);
      setShowSuccessAlert(true);
      fetchCampaignData();
    }
  };

  const handleDeleteCampaign = async () => {
    let response = await api_endpoints.deleteCampaign(state.campaign_id);

    if (response.status === 200) {
      setMessageSuccessAlert(t(response.data));
      setShowSuccessAlert(true);
      navigate(getPageFromName("Campagnes").path);
    }
  };

  const selectBorne = (e) => {
    for (let borne of bornesData) {
      if (borne._id.borne === e.id.borne) {
        setSelectedBorneData(borne);
        setBornesInfoDialogOpen(true);
        break;
      }
    }
  };

  const handleDateFilterChange = (value) => {
    setDateFilter(value);
  };

  const handleGenerateQRCode = async () => {
    const qrValue = `${BASE_URL}/fundraiser/${campaignData._id}`;

    try {
      const qrImage = await QRCode.toDataURL(qrValue, { width: 300 });

      openPrintPreview({
        message: "Fundraising for Campaign",
        campaign_name: campaignData.name || "",
        qrImage,
        qrSize: 350,
      });
    } catch (err) {
      console.error("Error generating QR Code", err);
    }
  };

  const openPrintPreview = ({ message, campaign_name, qrImage, qrSize }) => {
    const printWindow = window.open("", "", "width=1000,height=1000");

    printWindow?.document.write(`
        <html>
          <head>
            <style>
              @page {
                margin: 0; /* Remove browser header/footer */
                size: auto;
              }
    
              body {
                font-family: Arial, sans-serif;
                text-align: center;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100vh; /* Use full viewport height */
                box-sizing: border-box;
              }
    
              .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-grow: 1; /* Allow content to take up available space */
                padding: 40px;
              }
    
              h2 {
                margin-top: 100px;
                margin-bottom: 20px;
                font-size: 24px; /* Adjust for better readability */
              }
    
              h1 {
                margin-top: 0;
                margin-bottom: 20px;
                font-size: 36px;
              }
    
              .qr-code-container {
                margin-top: 20px;
                margin-bottom: 40px;
              }
    
              .footer {
                width: 100%;
                text-align: center;
                padding: 10px 0;
                background-color: white;
                position: absolute;
                bottom: 20px; /* Keep footer close to the bottom */
              }
    
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  display: block;
                  height: auto;
                  overflow: visible; /* Ensure no overflow */
                }
    
                .content {
                  padding: 0;
                  text-align: center;
                }
    
                .footer {
                  position: absolute;
                  bottom: 20px;
                  width: 100%;
                }
    
                .no-print {
                  display: none;
                }
              }
            </style>
          </head>
          <body>
            <div class="content">
            <div class="no-print">
              <button onclick="window.print()">Print or Save to PDF</button>
            </div>
              <h2>${message}</h2>
              <h1>${campaign_name}</h1>
              <div class="qr-code-container">
                <img src="${qrImage}" alt="QR Code" width="${qrSize}" height="${qrSize}" />
              </div>
            </div>
            <div class="footer">
              <img
                class="object-contain"
                src="${process.env.PUBLIC_URL}/Kiosk-Donations.svg"
                alt="Kiosk Donations"
                width="150"
              />
            </div>
          </body>
        </html>
      `);

    printWindow?.document.close();
  };

  return (
    <LoadingContainer loading={loading} error={loadingError}>
      <Dialog
        fullWidth
        open={addCashDialogOpen}
        onClose={() => setAddCashDialogOpen(false)}
      >
        <form onSubmit={handleAddCashToCampaign}>
          <DialogTitle>{t("addCash_text")}</DialogTitle>
          <DialogContent>
            <div className="m-2 flex flex-1 flex-col space-y-4">
              <TextField
                placeholder={t("insert_the_amount")}
                label={t("insert_the_amount")}
                type="number"
                value={cashAmountInput}
                onChange={(e) => setCashAmountInput(e.target.value)}
                required
              />
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr-ca"
              >
                <DatePicker
                  value={dayjs(donationDate)}
                  onChange={(value) => setDonationDate(value.$d.getTime())}
                  label={t("date_of_donations")}
                  sx={{ width: "100%" }}
                  maxDate={dayjs(new Date())}
                  slotProps={{
                    textField: {
                      error: false,
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit">
              {t("add_text")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SlDialog
        label="Information Kiosk"
        open={bornesInfoDialogOpen}
        onSlAfterHide={() => setBornesInfoDialogOpen(false)}
        style={{ "--width": "60vw" }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div>
              <span style={{ fontSize: 20 }}>Kiosk </span> -{" "}
              {selectedBorneData?.doc?.borne.name}
            </div>
            <div>
              <span style={{ fontSize: 20 }}> {t("located_at")} </span>
              {selectedBorneData?.doc?.borne.location.address}
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div>
              <span style={{ fontSize: 20 }}>
                {t("funds_collectedBy_Kiosk")}
              </span>{" "}
              : {selectedBorneData?.total}
            </div>
            <div>
              <span style={{ fontSize: 20 }}>{t("location")}</span> :{" "}
              {selectedBorneData?.doc?.borne.location.location_name}
            </div>
          </div>
        </div>
      </SlDialog>
      <div className="mb-4 flex flex-1 justify-end">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ca">
          <DatePicker
            value={dateFilter}
            onChange={(value) => handleDateFilterChange(value)}
            label={t("date_of_transactions")}
            disableFuture={true}
          />
        </LocalizationProvider>
      </div>
      <div className={styles.graph_container}>
        <ReactECharts
          ref={echartRef}
          option={chartData}
          style={{ height: "100%", width: "100%" }}
          lazyUpdate={true}
          notMerge={true}
          autoResize={true}
        />
      </div>
      <div className={styles.campaign_buttons}>
        <div></div>
        <div className="space-x-4">
          <Button
            disabled={!campaignData?.is_fundraiser}
            variant="contained"
            onClick={handleGenerateQRCode}
          >
            {t("print_qr")}
          </Button>
          <Button
            variant="contained"
            className={styles.add_cash_button}
            onClick={() => {
              setAddCashDialogOpen(true);
            }}
            disabled={campaignData?.is_archived}
          >
            {t("addCash_text")}
          </Button>
          <Button
            variant="outlined"
            className={styles.edit_campaign_button}
            onClick={() => {
              navigate(getPageFromName("Modifier la campagne").path, {
                state: { campaign_id: state.campaign_id },
              });
            }}
            disabled={campaignData?.is_archived}
          >
            {t("edit_campaign")}
          </Button>
          <Button
            color="error"
            variant="outlined"
            className={styles.delete_campaign_button}
            onClick={handleDeleteCampaign}
            disabled={campaignData?.is_archived}
          >
            {t("delete_campaign")}
          </Button>
        </div>
      </div>
      <div className="container-white space-x-20">
        <div className="flex flex-1 flex-col space-y-4 [&_label]:text-gray-500 [&>div]:justify-between [&>div]:flex">
          <div className="text-xl font-semibold">
            {campaignData?.name}{" "}
            {campaignData?.enabled ? (
              <Chip color="success" label={t("active_label")}></Chip>
            ) : campaignData?.is_archived ? (
              <Chip color="error" label={t("archive_label")}></Chip>
            ) : (
              <Chip color="error" label={t("deActive_label")}></Chip>
            )}
          </div>
          <div>
            {t("location")} :{" "}
            <label>{campaignData?.location?.location_name}</label>
          </div>
          <div>
            {t("duration")} :{" "}
            <label>
              {campaignData?.has_duration
                ? `${campaignData.date_start.split("T")[0]} à ${
                    campaignData.date_end.split("T")[0]
                  }`
                : t("unlimited")}
            </label>
          </div>
          <div>
            {t("objective")} :{" "}
            <label>
              {campaignData?.has_objective
                ? `${numberWithCommas(campaignData.objective)} $`
                : t("noObjective")}{" "}
            </label>
          </div>
          <div>
            {t("funds_raised")} :{" "}
            <label>
              {numberWithCommas(totalCollected) || Number(0).toFixed(2)} $
            </label>
          </div>
          <div className="flex flex-1 flex-col">
            {t("description")} :
            <p className="text-gray-500 mt-4">{campaignData?.description}</p>
          </div>
        </div>
        <div className="flex flex-1 flex-col space-y-4 [&_label]:text-gray-500 [&>div]:justify-between [&>div]:flex">
          <div>
            {t("no_of_cashDonations")}:{" "}
            <label>{transactionsStats?.cashDonationsStats?.count || 0}</label>
          </div>
          <div>
            {t("no_of_debitCredit")}:{" "}
            <label>{transactionsStats?.transactionsStats?.count || 0}</label>
          </div>
          <div>
            {t("average_cashDonations")}:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.cashDonationsStats?.avg)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
          <div>
            {t("average_donation_debitCredit")}:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.transactionsStats?.avg)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
          <div>
            {t("max_cashDonation")}:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.cashDonationsStats?.max)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
          <div>
            {t("max_donation_debitCredit")}:{" "}
            <label>
              {Number(
                numberWithCommas(transactionsStats?.transactionsStats?.max)
              ).toFixed(2) || Number(0).toFixed(2)}{" "}
              $
            </label>
          </div>
        </div>
      </div>
      <div className={styles.campaign_buttons}>
        <div></div>
        <div>
          <div>
            <Button
              color="warning"
              variant="contained"
              className={styles.add_to_archive_button}
              onClick={handleArchiveCampaign}
            >
              {campaignData?.is_archived
                ? t("remove_archives")
                : t("add_to_archives")}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col space-y-4">
        <div className="flex flex-1 flex-col">
          <div style={{ fontSize: 20 }}>{t("cash_donations")}</div>
          <DataTable
            highlightOnHover
            customStyles={tableStyles}
            columns={columns}
            data={cashTableData}
            pagination
            pointerOnHover
          />
        </div>
        <div className="flex flex-1 flex-col">
          <div style={{ fontSize: 20 }}>{t("kiosk_details")}</div>
          <DataTable
            highlightOnHover
            customStyles={tableStyles}
            columns={columnsBornes}
            data={transactionData}
            pagination
            pointerOnHover
            onRowClicked={selectBorne}
          />
        </div>
      </div>
    </LoadingContainer>
  );
};

export default ViewCampaign;
