import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const LocaleSelect = ({ className }) => {
  const { i18n } = useTranslation();

  const handleLanguageSelect = (event) => {
    const selectedLocale = event.target.value;
    i18n.changeLanguage(selectedLocale); // Update i18n language
  };

  return (
    <Select
      value={i18n.language} // Use i18n.language for the selected value
      onChange={handleLanguageSelect}
      sx={{
        boxShadow: "none",
        fontSize: 14,
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
        ".MuiSelect-icon": { width: 0 },
      }}
      inputProps={{ sx: { padding: "0 !important" } }}
      autoWidth
      IconComponent={() => <ExpandMoreIcon />}
      className={`text-[14px] ${className}`}
    >
      {i18n.languages &&
        i18n.languages.map((lang) => (
          <MenuItem key={lang} value={lang}>
            {lang.split("_").slice(-1)[0]} {/* Extract and display language */}
          </MenuItem>
        ))}
    </Select>
  );
};
