import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Tabs,
  Tab,
  Typography,
  Tooltip,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import api_endpoints from "../../api";
import AuthContext from "../../context/AuthContext/AuthContext";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const LOCALE_LIMIT = 5;

const recipients = [
  "marwan@kiosk360.ca",
  "samir@kiosk360.ca",
  "youness@kiosk360.ca",
];

const MyOrganization = () => {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [organizationData, setOrganizationData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalLoadingError, setModalLoadingError] = useState(false);
  const authContext = useContext(AuthContext);
  const [selectedLocales, setSelectedLocales] = useState([]);
  const [defaultLocales, setDefaultLocales] = useState([]);
  const [organizationLocales, setOrganizationLocales] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locales, setLocales] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const { setShowSuccessAlert, setMessageSuccessAlert } = useSuccessAlert();
  const pageTranslations = useRef(null);
  const { t } = useCustomTranslation("myorganization");

  const fetchPagesTranslations = async () => {
    try {
      const response = await api_endpoints.getPagesTranslations("kiosk");

      if (response.data.length === 0) throw new Error("No translations found");

      if (response.status === 200) {
        pageTranslations.current = response.data;
      }
    } catch (e) {
      setLoadingError(true);
    }
  };

  const fetchDefaultLocales = async () => {
    setLoading(true);
    try {
      // Fetch the organization data
      let response = await api_endpoints.getOrganization(
        authContext.authContext.accessToken.organization_id
      );
      if (response.status === 200) {
        const organizationData = response.data;
        // Check if organization data exists
        if (organizationData.country) {
          setOrganizationData(organizationData.country);
          // Fetch the locales for the country
          let localeResponse = await api_endpoints.getCountryLocales(
            organizationData.country._id
          );
          if (localeResponse.status === 200) {
            // Map the locale data to the desired format
            const locales = localeResponse.data.map((locale) => {
              return {
                id: locale.locale_id,
                name: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
              };
            });
            setDefaultLocales(locales);
          }
        }
      } else {
        setLoadingError(true);
      }
    } catch (error) {
      console.error("Error fetching organization data:", error);
      setLoadingError(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrganizationLocales = async () => {
    setLoading(true);
    try {
      // Fetch the organization data
      let organizationLocalesResponse =
        await api_endpoints.getOrganizationLocale(
          authContext.authContext.accessToken.organization_id
        );
      if (
        organizationLocalesResponse.status === 200 &&
        organizationLocalesResponse.data.length != 0
      ) {
        const locales = organizationLocalesResponse.data.map((locale) => {
          return {
            id: locale._id,
            name: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
          };
        });
        setOrganizationLocales(locales);
      } else {
        setOrganizationLocales([]);
      }
    } catch (error) {
      console.error("Error fetching Organization Locales data:", error);
      setLoadingError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const validLocaleSelection = async () => {
    const nonPresentLocales = [];
    let message = t("myorg_page_alert_language_add_attempt") + "\n";

    for (const selectedLocale of selectedLocales) {
      if (
        !pageTranslations.current
          .reduce((accumulator, pageTranslation) => {
            accumulator.push(pageTranslation.locale);
            return accumulator;
          }, [])
          .includes(selectedLocale.id)
      )
        nonPresentLocales.push(selectedLocale);
    }

    if (nonPresentLocales.length === 0) return true;

    for (const nonPresentLocale of nonPresentLocales) {
      message +=
        t("myorg_page_alert_language_list_item", {
          label: nonPresentLocale.label,
        }) + "\n";
    }

    message += "\n" + t("myorg_page_alert_language_not_supported") + "\n\n";
    message += t("myorg_page_alert_language_notification_prompt");

    if (window.confirm(message)) {
      try {
        let emailBody = `
        <div><strong>Date</strong>: ${new Date().toISOString()}</div>
        <br/>
        <div><strong>Utilisateur</strong>: ${
          authContext.authContext.accessToken.last_name
        } ${authContext.authContext.accessToken.first_name} (${
          authContext.authContext.accessToken.email
        })</div>
        <br/>
        <div><strong>Identifiant de l'organisation</strong>: ${
          authContext.authContext.accessToken.organization_id
        }</div>
        <br/>
        <br/>
        <div>Requête de l'ajout des langues suivantes:</div>
        `;

        for (const nonPresentLocale of nonPresentLocales) {
          emailBody += `<div>- ${nonPresentLocale.label}</div><br/>`;
        }

        // TODO ALERT USERS
        const response = await api_endpoints.sendMail(
          "Requête d'ajout d'une nouvelle langue - Kiosk360 Donation",
          emailBody,
          recipients
        );

        if (response.status === 200) {
          setShowSuccessAlert(true);
          setMessageSuccessAlert(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return false;
  };

  const handleSubmit = async () => {
    try {
      const validLocales = await validLocaleSelection();

      if (validLocales === false) {
        closeModal();
        return;
      }

      const selected = selectedLocales || [];
      const localeIds = selected.map((locale) => locale.id);

      let response = await api_endpoints.editOrganizationLocales(
        authContext.authContext.accessToken.organization_id,
        localeIds
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data.message);
        setShowSuccessAlert(true);
        await fetchOrganizationLocales();
        closeModal();
      } else {
        console.error("Failed to update Organization locales");
      }
    } catch (error) {
      console.error("Error updating Organization locales", error);
    }
  };

  const openModal = async (mode) => {
    setIsModalOpen(true);
    setModalLoading(true);

    try {
      let response = await api_endpoints.getAllLocales();
      if (response.status === 200) {
        const defaultLocaleIds = new Set(
          defaultLocales.map((locale) => locale.id)
        );
        const filteredLocales = response.data.locales
          .filter((locale) => !defaultLocaleIds.has(locale._id))
          .map((locale) => ({
            id: locale._id,
            label: `${locale.locale_translated} (${locale.locale_abbreviation})`,
          }));
        setLocales(filteredLocales);
      }
      let organizationLocalesResponse =
        await api_endpoints.getOrganizationLocale(
          authContext.authContext.accessToken.organization_id
        );

      if (organizationLocalesResponse.status === 200) {
        const selected = organizationLocalesResponse.data.map((locale) => ({
          id: locale.locale._id,
          label: `${locale.locale.locale} (${locale.locale.locale_abbreviation})`,
        }));
        setSelectedLocales(selected);
      }
    } catch (error) {
      console.error("Error fetching locales", error);
      setModalLoadingError(true);
    } finally {
      setModalLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLocales([]);
  };

  const handleChipDelete = (localeToDelete) => {
    setSelectedLocales((prevSelected) =>
      prevSelected.filter((locale) => locale.id !== localeToDelete.id)
    );
  };

  useEffect(() => {
    fetchPagesTranslations();
    fetchDefaultLocales();
    fetchOrganizationLocales();
  }, []);

  return (
    <>
      <Dialog
        open={isModalOpen} // Use unified modal state
        onClose={closeModal}
        sx={{ "& .MuiDialog-paper": { minHeight: 70, minWidth: 400 } }}
      >
        <DialogTitle>
          {modalLoading || t("myorg_page_popup_modify-lang")}
        </DialogTitle>
        <DialogContent>
          <LoadingContainer loading={modalLoading} error={modalLoadingError}>
            <div className="flex flex-1 flex-col space-y-4">
              <Autocomplete
                options={locales} // List of all available locales
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => setSelectedLocales(value)}
                value={selectedLocales || []}
                disabled={
                  (defaultLocales.length + selectedLocales?.length || 0) >=
                  LOCALE_LIMIT
                }
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                renderTags={() => null}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("myorg_page_popup_title")}
                    helperText={
                      <Typography variant="overline" gutterBottom>
                        {t("myorg_page_popup_dropdown", {
                          count:
                            LOCALE_LIMIT -
                            (defaultLocales.length + selectedLocales?.length ||
                              0),
                        })}
                      </Typography>
                    }
                  />
                )}
              />
              <div className="flex flex-wrap gap-2">
                {(selectedLocales || []).map((locale) => (
                  <>
                    <Chip
                      key={locale.id}
                      label={locale.label}
                      onDelete={() => handleChipDelete(locale)}
                    />
                  </>
                ))}
              </div>
            </div>
          </LoadingContainer>
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            loading={modalLoading}
            variant="contained"
            onClick={handleSubmit}
          >
            {t("myorg_page_popup_save_button")}
          </ButtonLoading>
          <Button
            variant="outlined"
            color="error"
            onClick={closeModal} // Correctly close the modal
          >
            {t("myorg_page_popup_cancel_button")}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingContainer loading={loading} error={loadingError}>
        <div className="text-2xl flex justify-between">
          <div className="text-gray-500 text-sm font-normal"></div>
        </div>
        <div>
          <div className="flex flex-1 h-full mt-5 flex-col">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="icon label tabs example"
            >
              <Tab
                icon={<LanguageIcon />}
                iconPosition="start"
                label={t("myorg_page_tab")}
              />
            </Tabs>
            {tabValue === 0 && (
              <>
                <div className="mt-2 container-white flex flex-col p-4 gap-4">
                  <Typography variant="h5" component="div" gutterBottom>
                    {organizationData.name}
                  </Typography>
                  <div className="w-full">
                    <Typography variant="h6" component="div" gutterBottom>
                      {t("myorg_page_default-lang")}
                    </Typography>
                    <div className="flex flex-wrap gap-2">
                      {defaultLocales.length > 0 ? (
                        defaultLocales.map((locale) => (
                          <Tooltip
                            key={locale.name}
                            title={`${locale.name} ${t(
                              "myorg_page_tooltip_isdefault_locale"
                            )}`}
                          >
                            <Chip label={locale.name} />
                          </Tooltip>
                        ))
                      ) : (
                        <Typography variant="body1" color="textSecondary">
                          {t("myorg_page_nodefault_lang_text")}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <Divider className="my-4" />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex flex-col">
                        <Typography variant="h6" component="div">
                          {t("myorg_page_org-lang")}
                        </Typography>
                        <Typography variant="overline" gutterBottom>
                          {t("myorg_page_popup_dropdown", {
                            count:
                              LOCALE_LIMIT -
                              (defaultLocales.length +
                                organizationLocales?.length ||
                                0 + selectedLocales?.length ||
                                0),
                          })}
                        </Typography>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => openModal("add")}
                        className="ml-2"
                      >
                        {t("myorg_page_button_settings")}
                      </Button>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {organizationLocales.length > 0 ? (
                        organizationLocales.map((locale) => (
                          <Chip
                            key={locale.name}
                            label={locale.name}
                            className="cursor-pointer"
                          />
                        ))
                      ) : (
                        <Typography variant="body1" color="textSecondary">
                          {t("myorg_page_nolocale_assigned_text")}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </LoadingContainer>
    </>
  );
};

export default MyOrganization;
