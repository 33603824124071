import Dashboard from "./components/Dashboard/Dashboard";
import ManageCampaigns from "./components/ManageCampaigns/ManageCampaigns";
import CreateCampaign from "./components/CreateCampaign/CreateCampaign";
import ViewCampaign from "./components/ViewCampaign/ViewCampaign";
import ManageUsers from "./components/ManageUsers/ManageUsers";
import CreateUser from "./components/CreateUser/CreateUser";
import ModifyUser from "./components/ModifyUser/ModifyUser";
import Settings from "./components/Settings/Settings";
import EditCampaign from "./components/EditCampaign/EditCampaign";
import ManageKiosks from "./components/ManageKiosks/ManageKiosks";
import KiosksMonitoring from "./components/KiosksMonitoring/KiosksMonitoring";
import ManageLocations from "./components/ManageLocations/ManageLocations";
import CreateLocation from "./components/CreateLocation/CreateLocation";
import CreateKiosk from "./components/CreateKiosk/CreateKiosk";
import ManageOrganizations from "./components/ManageOrganizations/ManageOrganizations";
import EditOrganizations from "./components/EditOrganizations/EditOrganizations";
import CreateOrganization from "./components/CreateOrganization/CreateOrganization";
import MyOrganization from "./components/MyOrganization/MyOrganization";
import ManageSuperUsers from "./components/ManageSuperUsers/ManageSuperUsers";
import CreateSuperUser from "./components/CreateSuperUser/CreateSuperUser";
import CloverConfiguration from "./components/CloverConfiguration/CloverConfiguration";
import Localization from "./components/Localization/Localization";
import EditLocation from "./components/EditLocation/EditLocation";
import EditKiosk from "./components/EditKiosk/EditKiosk";
import ManageTransactions from "./components/ManageTransactions/ManageTransactions";
import Donors from "./components/Donors/Donors";
import Donor from "./components/Donors/Donor";
import Payments from "./components/Payment/Payments";
import PaymentCreate from "./components/Payment/PaymentCreate";
import BusinessIcon from "@mui/icons-material/Business";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PinDropIcon from "@mui/icons-material/PinDrop";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CampaignIcon from "@mui/icons-material/Campaign";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PublicIcon from "@mui/icons-material/Public";
import TranslateIcon from "@mui/icons-material/Translate";
import { Translations } from "./components/Translations/Translations";
import { PromisesList } from "./components/CampaignPromise/PromisesList";
import { PromiseDetails } from "./components/CampaignPromise/PromisesDetails";

export const PAGES = {
  // root or category of pages
  organizations: {
    // the label for the root
    label: "org_tab",

    // icon of the root of pages
    icon: <BusinessIcon />,

    // top level permission for root page. this means this value supersedes the inner pages super_user. if
    // inner pages are super_user false but this value is true then it will be true and not be shown in case user is not
    // super user
    super_user: true,

    // same as super_user permission, this parameter overrides inner pages requiresOrgs
    requiresOrg: false,

    // inner pages of a category or root
    pages: [
      {
        // path of the inner page
        path: "/manageorganizations",

        // label of the inner page which can be an arbitrary value and not be exactly the same as the name
        // this is a display value and the user will be seeing this value in the UI
        label: "org_subtab_org_list",

        // name of the inner page which has to be unique to fetch the correct page translation
        name: "Organisations",

        // component of the inner page
        component: ManageOrganizations,

        // is this page available for super users only
        super_user: true,

        // do we show this page in the drawer or not
        showInDrawer: true,

        // does this page have a return button in the page title component. usually we set this to true
        // for pages taht can only be accessed from another page. for example, a list of campaign will be set to false
        // but editing a campaign will be set to true since we had to click on a table row to access that link
        canReturn: false,

        // does this page require an organization id or not
        requiresOrg: false,
      },
      {
        path: "/manageorganizations/editorganization",
        label: "Modifier l'organisation",
        name: "Modifier l'organisation",
        component: EditOrganizations,
        super_user: true,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: false,
      },
      {
        path: "/manageorganizations/createorganization",
        label: "org_subtab_org_create",
        name: "Créer une organisation",
        component: CreateOrganization,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: false,
      },
    ],
  },
  superUsers: {
    label: "super_users_tab",
    icon: <PeopleAltIcon />,
    super_user: true,
    requiresOrg: false,
    pages: [
      {
        path: "/managesuperusers",
        label: "super_users_subtab_superuser_list",
        name: "Super utilisateurs",
        component: ManageSuperUsers,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: false,
      },
      {
        path: "/managesuperusers/createsuperuser",
        label: "super_users_subtab_superuser_create",
        name: "Créer un super utilisateur",
        component: CreateSuperUser,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: false,
      },
    ],
  },
  cloverConfig: {
    label: "clover_settings_tab",
    icon: <PointOfSaleIcon />,
    super_user: true,
    requiresOrg: false,
    pages: [
      {
        path: "/cloverconfiguration",
        label: "clover_settings_tab",
        name: "Paramètres Clover",
        component: CloverConfiguration,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: false,
      },
    ],
  },
  localization: {
    label: "localization_tab",
    icon: <PublicIcon />,
    super_user: true,
    requiresOrg: false,
    pages: [
      {
        path: "/localization",
        label: "localization_tab",
        name: "Localization",
        component: Localization,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: false,
      },
    ],
  },
  translations: {
    label: "translations_tab",
    icon: <TranslateIcon />,
    super_user: true,
    requiresOrg: false,
    pages: [
      {
        path: "/translations",
        label: "translations_tab",
        name: "Translations",
        component: Translations,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: false,
      },
    ],
  },
  dashboard: {
    label: "dashboard_tab",
    icon: <DashboardIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/dashboard",
        label: "dashboard_tab",
        name: "Tableau de bord",
        component: Dashboard,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
    ],
  },
  manageorganization: {
    label: "my_organization_tab",
    icon: <BusinessIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/myorganization",
        label: "my_organization_tab",
        name: "myorganization",
        component: MyOrganization,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
    ],
  },
  campaigns: {
    label: "campaigns_tab",
    icon: <CampaignIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/managecampaigns",
        label: "campaigns_subtab_campaigns_list",
        name: "Campagnes",
        component: ManageCampaigns,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/managecampaigns/editcampaign",
        label: "Modifier la campagne",
        name: "Modifier la campagne",
        component: EditCampaign,
        super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
      {
        path: "/promises",
        label: "promises_list",
        name: "Promesses",
        component: PromisesList,
        super_user: false,
        showInDrawer: true,
        canReturn: true,
        requiresOrg: true,
      },
      {
        path: "/promise/details",
        label: "Voir la promesse",
        name: "Promesse",
        component: PromiseDetails,
        super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
      {
        path: "/managecampaigns/createcampaign",
        label: "campaigns_subtab_campaign_create",
        name: "Créer une campagne",
        component: CreateCampaign,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/managecampaigns/viewcampaign",
        label: "Voir la campagne",
        name: "Campagne",
        component: ViewCampaign,
        super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
    ],
  },
  locations: {
    label: "locations_tab",
    icon: <PinDropIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/managelocations",
        label: "locations_subtab_locations_list",
        name: "Emplacements",
        component: ManageLocations,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/createlocation",
        label: "locations_subtab_location_create",
        name: "Créer un emplacement",
        component: CreateLocation,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/editlocation",
        label: "Modifier l'emplacement",
        name: "Modifier l'emplacement",
        component: EditLocation,
        super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
    ],
  },
  kiosks: {
    label: "kiosks_tab",
    icon: <AdUnitsIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/managekiosks",
        label: "kiosks_subtab_kiosks_list",
        name: "Kiosks",
        component: ManageKiosks,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/createkiosk",
        label: "kiosks_subtab_kiosk_create",
        name: "Créer un Kiosk",
        component: CreateKiosk,
        super_user: true,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/kiosksmonitoring",
        label: "kiosks_subtab_kiosk_monitoring",
        name: "KiosksMonitoring",
        component: KiosksMonitoring,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/editkiosk",
        label: "Modifier un Kiosk",
        name: "Modifier un Kiosk",
        component: EditKiosk,
        super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
    ],
  },
  transactions: {
    label: "transactions_tab",
    icon: <ReceiptIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/managetransactions",
        label: "transactions_tab",
        name: "Transactions",
        component: ManageTransactions,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
    ],
  },
  users: {
    label: "users_tab",
    icon: <PeopleOutlineIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/manageusers",
        label: "users_subtab_users_list",
        name: "Utilisateurs",
        component: ManageUsers,
        is_super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/manageusers/viewuser",
        label: "Voir l'utilisateur",
        name: "Voir l'utilisateur",
        component: <></>,
        is_super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
      {
        path: "/manageusers/createuser",
        label: "users_subtab_user_create",
        name: "Créer un utilisateur",
        component: CreateUser,
        is_super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/manageusers/modifyuser",
        label: "Paramètres utilisateur",
        name: "Paramètres utilisateur",
        component: ModifyUser,
        is_super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
    ],
  },
  donors: {
    label: "donors_tab",
    icon: <AccountCircleIcon />,
    super_user: false,
    requiresOrg: true,
    pages: [
      {
        path: "/donors",
        label: "donors_tab",
        name: "Donateurs",
        component: Donors,
        super_user: false,
        showInDrawer: true,
        canReturn: false,
        requiresOrg: true,
      },
      {
        path: "/donors/:email",
        label: "Profil donateur",
        name: "Profil donateur",
        component: Donor,
        super_user: false,
        showInDrawer: false,
        canReturn: true,
        requiresOrg: true,
      },
    ],
  },

  // settings: {
  //   label: "Paramètres",
  //   icon: <></>,
  //   pages: [
  //     {
  //       path: "/settings",
  //       label: "Paramètres",
  //       name: "Paramètres",
  //       component: Settings,
  //       super_user: false,
  //       showInDrawer: true,
  //     },
  //   ],
  // },
};

// pages set aside for now
//   // payments: {
//   //   path: "/payments",
//   //   label: "Paiements",
//   //   component: Payments ,
//   // },
//   // paymentsCreate: {
//   //   path: "/payments/create/:organizationId",
//   //   label: "Créer un paiement",
//   //   component: PaymentCreate ,
//   // },

export const getPageFromName = (pageName) => {
  for (const root of Object.keys(PAGES)) {
    for (const page of PAGES[root].pages) {
      if (page.name === pageName) return page;
    }
  }
  return null;
};

const validateUniquePageNames = () => {
  const pageRoots = [];

  for (const root of Object.keys(PAGES)) {
    for (const page of PAGES[root]["pages"]) {
      if (!pageRoots.includes(page.name)) pageRoots.push(page.name);
      else
        throw new Error(
          "Page name duplication. Make sure all page roots are unique"
        );
    }
  }
};

validateUniquePageNames();
