import { useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Button,
} from "@mui/material";
import api_endpoints from "../../api";
import { NumericFormat } from "react-number-format";
import { formatDateTime } from "../../utils";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { useNavigate } from "react-router-dom";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";
import {
  PAYMENT_TYPE,
  PROMISE_TYPE,
  PAYMENT_FREQUENCY,
} from "../../enums/PromiseEnums";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

const FundraiserForm = () => {
  const [emailError, setEmailError] = useState(false);
  const [dueDateError, setDueDateError] = useState(false);
  const { t } = useCustomTranslation("Create Promise");
  const { setShowSuccessAlert, setMessageSuccessAlert } = useSuccessAlert();
  const navigate = useNavigate();
  const { id } = useParams(); // Extract 'id' from URL

  const [formData, setFormData] = useState({
    donor_id: "",
    campaign_id: id,
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    email: "",
    zip_code: "",
    payment_type: PAYMENT_TYPE.CASH,
    promise_type: PROMISE_TYPE.ONE_TIME_PAYMENT,
    promise_amount: "",
    payment_frequency: "",
    due_date: "",
  });

  const getCampaignAndOrganizationQuery = useApiClientQuery(
    () => api_endpoints.getCampaignAndOrganization(id ?? ""),
    {
      queryKey: ["campaign_and_organization_data"],
      enabled: !!id, // Only enable query when id exists
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  const createPromiseMutation = useApiClientMutation(
    api_endpoints.createPromiseByFundraiserForm,
    [`view_promise`],
    (data) => {
      setMessageSuccessAlert(data.message);
      setShowSuccessAlert(true);
      clearFilters();
    }
  );

  const clearFilters = () => {
    setFormData((prev) => ({
      ...prev,
      donor_id: "",
      first_name: "",
      last_name: "",
      phone: "",
      address: "",
      email: "",
      zip_code: "",
      payment_type: PAYMENT_TYPE.CASH,
      promise_type: PROMISE_TYPE.ONE_TIME_PAYMENT,
      promise_amount: "",
      payment_frequency: "",
      due_date: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
      setEmailError(!emailPattern.test(value));
    }

    if (name === "payment_type") {
      setFormData((prevValue) => ({
        ...prevValue,
        frequency: null,
        due_date: null,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (emailError) {
      console.log("Invalid email address");
      return;
    }

    if (
      formData.promise_type === PROMISE_TYPE.ONE_TIME_PAYMENT &&
      !formData.due_date
    ) {
      setDueDateError(true);
      return; // Stop further execution if validation fails
    }

    // Your submit logic
    const promiseData = {
      donor_id: formData.donor_id,
      campaign_id: formData.campaign_id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: formData.phone,
      address: formData.address,
      email: formData.email,
      zip_code: formData.zip_code,
      promise_amount: formData.promise_amount.replace(/[^0-9.]/g, "") ?? 0, // Ensure promise_amount is a number
      given_amount: 0, // Default value as per your backend schema
      due_date: formData.due_date ? formatDateTime(formData.due_date) : null, // Handle date conversion
      promise_type: formData.promise_type, // Map to promise_type
      payment_type: formData.payment_type, // Map to payment_type
      // payment_frequency:
      //   formData.promise_type === PROMISE_TYPE.RECURRING
      //     ? formData.payment_frequency
      //     : null, // Only set frequency if donation is recurring
    };

    createPromiseMutation.mutate(promiseData);
  };

  return (
    <div
      className="flex flex-1 min-h-screen bg-no-repeat bg-center bg-cover justify-center items-center p-2 sm:p-4 md:p-5 lg:p-6"
      style={{ backgroundImage: "url('/Kiosk-Background.png')" }}
    >
      <div className="flex flex-col rounded-3xl bg-white w-full sm:w-4/5 md:w-3/5 lg:w-1/2 xl:w-1/3 p-8 sm:p-12">
        <div className="flex flex-col items-center">
          <div className="flex flex-col text-center w-full mb-4">
            <div className="flex gap-y-2 flex-col">
              <label className="text-3xl">Fundraising by Campaign</label>
              <label className="text-2xl">
                {getCampaignAndOrganizationQuery.data?.campaign.name ??
                  "No Campaign Found"}
              </label>
              <label className="text-3xl">BY</label>
              <label className="text-2xl">
                {getCampaignAndOrganizationQuery.data?.organization
                  ?.legal_name ?? "No Organization Found"}
              </label>
            </div>
            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-y-4 mt-2">
                <div className="flex gap-x-4 flex-wrap">
                  <TextField
                    fullWidth
                    label={t("first_name")}
                    required
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </div>
                <TextField
                  fullWidth
                  label={t("last_name")}
                  required
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label={t("phone")}
                  required
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label={t("address")}
                  required
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
                <TextField
                  label={t("email")}
                  required
                  error={emailError}
                  helperText={emailError ? "Invalid email address" : ""}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  label={t("zip_code")}
                  required
                  name="zip_code"
                  value={formData.zip_code}
                  onChange={handleChange}
                />
                <FormControl fullWidth required>
                  <InputLabel>{t("payment_type")}</InputLabel>
                  <Select
                    name="payment_type"
                    value={formData.payment_type}
                    onChange={handleChange}
                    label={t("payment_type")}
                  >
                    <MenuItem value={PAYMENT_TYPE.CREDIT_CARD}>
                      {t("credit_card")}
                    </MenuItem>
                    <MenuItem value={PAYMENT_TYPE.CHEQUE}>
                      {t("cheque")}
                    </MenuItem>
                    <MenuItem value={PAYMENT_TYPE.BANK_TRANSFER}>
                      {t("bank_transfer")}
                    </MenuItem>
                    <MenuItem value={PAYMENT_TYPE.CASH}>{t("cash")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth required>
                  <InputLabel>{t("promise_type")}</InputLabel>
                  <Select
                    name="promise_type"
                    value={formData.promise_type}
                    onChange={handleChange}
                    label={t("promise_type")}
                  >
                    <MenuItem value="one_time_payment">
                      {t("one_time_payment")}
                    </MenuItem>
                    <MenuItem value={PROMISE_TYPE.SPLIT_PAYMENT}>
                      {t("split_payment")}
                    </MenuItem>
                    {/* <MenuItem value={PROMISE_TYPE.RECURRING}>
                      {t("recurring")}
                    </MenuItem> */}
                  </Select>
                </FormControl>
                {formData.promise_type === PROMISE_TYPE.ONE_TIME_PAYMENT && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr-ca"
                  >
                    <DatePicker
                      value={formData.due_date}
                      onChange={(newValue) => {
                        setFormData((prevValue) => ({
                          ...prevValue,
                          due_date: newValue,
                        }));
                        setDueDateError(false);
                      }}
                      label={t("due_date")}
                      sx={{ width: "100%" }}
                      minDate={dayjs(new Date())}
                      slotProps={{
                        textField: {
                          error: dueDateError,
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                {formData.promise_type === PROMISE_TYPE.RECURRING && (
                  <FormControl fullWidth required>
                    <InputLabel>{t("frequency")}</InputLabel>
                    <Select
                      name="payment_frequency"
                      value={formData.payment_frequency}
                      onChange={handleChange}
                      label={t("frequency")}
                    >
                      <MenuItem value={PAYMENT_FREQUENCY.DAILY}>
                        {t("daily")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.WEEKLY}>
                        {t("weekly")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.EVERY_2_WEEKS}>
                        {t("every_2_weeks")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.MONTHLY}>
                        {t("monthly")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.EVERY_2_MONTHS}>
                        {t("every_2_months")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.QUARTERLY}>
                        {t("quarterly")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.EVERY_4_MONTHS}>
                        {t("every_4_months")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.BIMONTHLY}>
                        {t("bimonthly")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.SEMI_ANNUALLY}>
                        {t("semi_annually")}
                      </MenuItem>
                      <MenuItem value={PAYMENT_FREQUENCY.ANNUALLY}>
                        {t("annually")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <NumericFormat
                  fullWidth
                  value={formData.promise_amount}
                  onChange={handleChange}
                  customInput={TextField}
                  thousandSeparator
                  valueIsNumericString
                  decimalScale={2}
                  required
                  name="promise_amount"
                  prefix="$ "
                  label={t("promise_amount")}
                />
                <FormGroup>
                  <FormControlLabel
                    required
                    name="consent"
                    control={<Checkbox onChange={() => {}} />}
                    label="When participating in a fundraiser, I consent to submit my data to this establishment which will also create a donor profile for future transaction done in this establishment"
                  />
                </FormGroup>
                <Button
                  loading={createPromiseMutation.isPending}
                  disabled={!getCampaignAndOrganizationQuery.data?.campaign._id}
                  type="submit"
                  variant="contained"
                >
                  {t("submit")}
                </Button>
              </div>
            </form>
          </div>
          <div className="flex w-[175px] mt-auto">
            <img
              className="object-contain"
              src={process.env.PUBLIC_URL + "/Kiosk-Donations.svg"}
              alt="Kiosk Donations"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraiserForm;
