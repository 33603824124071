export const PAYMENT_TYPE = Object.freeze({
  CASH: "cash",
  CREDIT_CARD: "credit_card",
  CHEQUE: "cheque",
  BANK_TRANSFER: "bank_transfer",
});

export const PROMISE_TYPE = Object.freeze({
  ONE_TIME_PAYMENT: "one_time_payment",
  //RECURRING: "recurring",
  SPLIT_PAYMENT: "split_payment",
});

export const PAYMENT_FREQUENCY = Object.freeze({
  DAILY: "daily",
  WEEKLY: "weekly",
  EVERY_2_WEEKS: "every_2_weeks",
  MONTHLY: "monthly",
  EVERY_2_MONTHS: "every_2_months",
  QUARTERLY: "quarterly",
  EVERY_4_MONTHS: "every_4_months",
  BIMONTHLY: "bimonthly",
  SEMI_ANNUALLY: "semi_annually",
  ANNUALLY: "annually",
});

export const STATUS_TYPE = Object.freeze({
  IN_PROGRESS: "in_progress",
  ABANDONED: "abandoned",
  CLOSED: "closed",
  OPEN: "open",
});
