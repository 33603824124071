import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import api_endpoints from "../api";

const fetchTranslations = async () => {
  try {
    const response = await api_endpoints.getLocaleBasedTranslations(
      i18next.language.split("_").slice(0, 2).join("_"), // Language code (e.g., "en_US")
      "web" // Namespace
    );

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      console.warn(
        "No translations found or invalid response:",
        response.status
      );
      return {}; // Return an empty object for invalid responses
    }
  } catch (error) {
    return {}; // Return an empty object on error
  }
};

i18next
  .use(LanguageDetector)
  .use(HttpBackend) // Use HttpBackend to fetch translations
  .use(initReactI18next)
  .init({
    fallbackLng: ["en_US_English", "fr_CA_Français"],
    supportedLngs: ["en_US_English", "fr_CA_Français"],
    debug: false,
    interpolation: { escapeValue: false }, // Prevent XSS attacks in translations
    initImmediate: false, // Ensure manual loading before rendering
  });

const loadTranslations = async () => {
  try {
    const translations = await fetchTranslations();

    if (Object.keys(translations).length > 0) {
      //console.log("translations", translations);
      // Directly add translations as a resource bundle for the current language
      const language = i18next.language; // E.g., "en_US_English"

      Object.keys(translations).forEach((page) => {
        i18next.addResourceBundle(
          language,
          page,
          translations[page],
          true,
          true
        );
      });
    } else {
      console.warn("No translations to load.");
    }
  } catch (error) {
    console.error("Error loading translations:", error);
  }
};

loadTranslations();

// Listen for language change events and reload translations
i18next.on("languageChanged", () => {
  loadTranslations();
});

export default i18next;
