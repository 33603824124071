import React, { useContext } from "react";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AuthContext from "../../context/AuthContext/AuthContext";
import { useHiddenView } from "../../context/HiddenViewContext/HiddenViewContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

export default function HiddenViewSwitch() {
  const authContext = useContext(AuthContext);
  const hiddenVueContext = useHiddenView();
  const { t } = useCustomTranslation("Sidebar");
  const isOrganizationSelected =
    authContext.authContext.accessToken.organization_id !== null &&
    authContext.authContext.accessToken.organization_id !== undefined;

  const toggleChange = () => {
    hiddenVueContext.setHidden(!hiddenVueContext.hidden);
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t("toggle_view_superuser")}</Typography>
        <Tooltip
          disableFocusListener={isOrganizationSelected}
          disableHoverListener={isOrganizationSelected}
          disableTouchListener={isOrganizationSelected}
          title={<div style={{ fontSize: 12 }}>{t("toggle_view_tooltip")}</div>}
        >
          <div>
            <Switch
              onChange={toggleChange}
              disabled={!isOrganizationSelected}
            />
          </div>
        </Tooltip>
        <Typography>{t("toggle_view_customer")}</Typography>
      </Stack>
    </FormGroup>
  );
}
