import { getPageFromName } from "../../route_utils";
import { useEffect, useState, useContext, useRef } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import api_endpoints from "../../api";
import { useNavigate, useLocation } from "react-router-dom";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import ReactECharts from "echarts-for-react";
import {
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  List,
  ListItem,
  Radio,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Divider,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr-ca";
import CancelButton from "../../widgets/CancelButton/CancelButton";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import ImageInputV2 from "../../widgets/ImageInput/ImageInputV2";
import ColorPicker from "../../widgets/ColorPicker/ColorPicker";
import dayjs from "dayjs";
import { kioskPingStatusChartOption } from "../../EchartsUtils/KioskPingStatusChartOption";
import { dateTimeReadable } from "../../utils";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const isToday = require("dayjs/plugin/isToday");
dayjs.extend(isToday);

const EditKiosk = () => {
  const navigate = useNavigate();
  const echartRef = useRef(null);
  const { state } = useLocation();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loadingStatusChange, setLoadingStatusChange] = useState(false);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [loadingCampaignsError, setLoadingCampaignsError] = useState(false);
  const [locations, setLocations] = useState([]);
  const [image, setImage] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [kioskPingStatusDate, setKioskPingStatusDate] = useState(dayjs());
  const intervalRef = useRef(null);
  const [lastUpdateKioskPingStatus, setLastUpdateKioskPingStatus] =
    useState(null);
  const { t } = useCustomTranslation("Créer un Kiosk");
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();
  const [formData, setFormData] = useState({
    organization: authContext.authContext.accessToken.organization_id,
    location: "",
    name: "",
    clover_device_id: "",
    terminal_position: "",
  });

  const [lightingModeData, setLightingModedata] = useState({
    is_active: false,
    lighting_mode_type: 0,
    date_start: null,
    date_end: null,
    time_start: null,
    time_end: null,
    time_start_monday: null,
    time_end_monday: null,
    time_start_tuesday: null,
    time_end_tuesday: null,
    time_start_wednesday: null,
    time_end_wednesday: null,
    time_start_thursday: null,
    time_end_thursday: null,
    time_start_friday: null,
    time_end_friday: null,
    time_start_saturday: null,
    time_end_saturday: null,
    time_start_sunday: null,
    time_end_sunday: null,
    is_monday_selected: false,
    is_tuesday_selected: false,
    is_wednesday_selected: false,
    is_thursday_selected: false,
    is_friday_selected: false,
    is_saturday_selected: false,
    is_sunday_selected: false,
    campaign_id: "",
    color_code: "",
  });

  useEffect(() => {
    fetchLocations();

    return () => removeFetchKioskPingInterval();
  }, []);

  useEffect(() => {
    if (kioskPingStatusDate && tabValue === 1) fetchKioskPingStatus();
  }, [kioskPingStatusDate]);

  useEffect(() => {
    if (tabValue === 0) {
      fetchKioskData();
      removeFetchKioskPingInterval();
    } else if (tabValue === 1) {
      fetchKioskPingStatus();
    }
  }, [tabValue]);

  const removeFetchKioskPingInterval = () => {
    setLastUpdateKioskPingStatus(null);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  const fetchKioskPingInterval = () => {
    intervalRef.current = setInterval(() => {
      fetchKioskPingStatus();
      setLastUpdateKioskPingStatus(dateTimeReadable(new Date()));
    }, 60 * 1000);
  };

  const onResizeEvent = () => {
    window.onresize = () => {
      echartRef?.current?.getEchartsInstance().resize();
    };
  };

  const mapKioskPingStatusData = (status) => {
    let seriesData = [];
    let option = { ...kioskPingStatusChartOption };

    for (let i = 0; i < status.length; i++) {
      const datetime = new Date(status[i].timestamp);
      const time = `${datetime
        .getHours()
        .toString()
        .padStart(2, "0")}:${datetime
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

      if (
        i > 0 &&
        datetime.getTime() - new Date(status[i - 1].timestamp).getTime() >
          120000
      ) {
        seriesData.push([time, t("edit_kiosk_graph_inActive_status_text")]);
      } else {
        seriesData.push([time, t("edit_kiosk_graph_active_status_text")]);
      }
    }

    option.xAxis.min = "00:00";
    option.xAxis.max = "23:59";
    option.series[0].data = seriesData;
    option.title.text = t("edit_kiosk_graph_status_text");
    echartRef?.current?.getEchartsInstance().setOption(option);
  };

  const fetchKioskPingStatus = async () => {
    try {
      if (kioskPingStatusDate.isToday() && intervalRef.current === null) {
        fetchKioskPingInterval();
      } else if (!kioskPingStatusDate.isToday()) {
        removeFetchKioskPingInterval();
      }

      const finalDate = `${kioskPingStatusDate.get("year")}-${(
        kioskPingStatusDate.get("month") + 1
      )
        .toString()
        .padStart(2, "0")}-${kioskPingStatusDate
        .get("date")
        .toString()
        .padStart(2, "0")}`;

      const response = await api_endpoints.getKioskPingStatus(
        state.kiosk_id,
        finalDate
      );

      if (response.status === 200) {
        onResizeEvent();
        mapKioskPingStatusData(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchKioskData = async () => {
    try {
      const response = await api_endpoints.getKioskByID(state.kiosk_id);

      if (response.status === 200) {
        setFormData(response.data.kiosk_data);

        if (response.data.lighting_mode_data)
          setLightingModedata(response.data.lighting_mode_data);

        if (response.data?.lighting_mode_data?.is_active) fetchCampaigns();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchLocations = async () => {
    setLoading(true);

    try {
      let response = await api_endpoints.getLocations(
        authContext.authContext.accessToken.organization_id,
        null
      );

      if (response.status === 200) {
        setLocations(response.data);
        await fetchKioskData();
      }
    } catch (e) {
      console.error(e);
      setLoadingError(true);
    }

    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setSubmitting(true);

    try {
      let response = await api_endpoints.editKiosk(setRequestData());

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        setSubmitting(false);
        navigate(getPageFromName("Kiosks").path);
      }
    } catch (e) {}

    setSubmitting(false);
  };

  const changeFormData = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleLocationSelect = (e) => {
    setFormData({ ...formData, location: e.target.value });
  };

  const handleKioskStatus = async () => {
    setLoadingStatusChange(true);
    try {
      const response = await api_endpoints.setKioskActiveStatus(
        formData._id,
        !formData.is_active
      );

      if (response.status === 200) {
        setMessageSuccessAlert(response.data);
        setShowSuccessAlert(true);
        fetchKioskData();
      }
    } catch (e) {
      console.error(e);
    }

    setLoadingStatusChange(false);
  };

  const fetchCampaigns = async () => {
    setLoadingCampaigns(true);

    try {
      const response = await api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id,
        null,
        true,
        null,
        null,
        false
      );

      if (response.status === 200 && response.data.length > 0) {
        setCampaigns(response.data);
      }
    } catch (e) {
      console.error(e);
      setLoadingCampaignsError(true);
    }

    setLoadingCampaigns(false);
  };

  const setRequestData = () => {
    const dataToSend = {
      ...formData,
      lighting_mode_data: { ...lightingModeData },
    };
    const data = new FormData();

    if (image) data.append("file", image, image.name);

    for (const [key, value] of Object.entries(dataToSend)) {
      if (key === "lighting_mode_data") {
        for (const [innerKey, innerValue] of Object.entries(dataToSend[key])) {
          data.append(`${key}[${innerKey}]`, innerValue);
        }
      } else {
        data.append(key, value);
      }
    }

    return data;
  };

  const changeKioskPingStatusDate = (value) => {
    setKioskPingStatusDate(value);
  };

  const changeLightingModeData = (key, value) => {
    setLightingModedata((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleLightingModeToggle = () => {
    changeLightingModeData("is_active", !lightingModeData.is_active);

    if (!lightingModeData.is_active) {
      fetchCampaigns();
    }
  };

  const handleLightingModeType = (e) => {
    changeLightingModeData("lighting_mode_type", e.target.value);
  };

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    document.getElementById("imageInput").value = null;
    setImage(null);
  };

  const handleCampaignSelect = (e) => {
    setLightingModedata({ ...lightingModeData, campaign_id: e.target.value });
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="flex flex-1">
      {/* <Breadcrumb
            routes={[PAGES.dashboard, PAGES.manageKiosks, PAGES.editKiosk]}
          /> */}
      <div className="container-white flex-col mt-4">
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={t("edit_kiosk_detailsTab_text")} />
          <Tab label={t("edit_kiosk_monitoringTab_text")} />
        </Tabs>
        <Divider />
        {tabValue === 0 ? (
          <LoadingContainer loading={loading} error={loadingError}>
            <form
              id="publish_campaign_form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="flex flex-1 flex-col mt-4">
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-1 items-center justify-between">
                    <h3>
                      {t("edit_kiosk_lincenseKey_text")}: {formData.license_key}
                    </h3>
                    <FormGroup>
                      <FormControlLabel
                        label={
                          formData?.is_active
                            ? t("edit_kiosk_graph_active_status_text")
                            : t("edit_kiosk_graph_inActive_status_text")
                        }
                        control={
                          <Switch
                            disabled={loadingStatusChange}
                            checked={formData?.is_active}
                            onChange={handleKioskStatus}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                  <FormControl>
                    <InputLabel required>
                      {t("create_kiosk_location")}
                    </InputLabel>
                    <Select
                      value={formData.location}
                      label={t("create_kiosk_location")}
                      onChange={handleLocationSelect}
                      fullWidth
                      required
                    >
                      {locations.map((location) => (
                        <MenuItem key={location._id} value={location._id}>
                          <div className="flex flex-col">
                            <label className="text-lg font-semibold">
                              {location.location_name}
                            </label>
                            <label>{location.address}</label>
                            <label>{location.phone}</label>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="flex w-full space-x-4">
                    <TextField
                      fullWidth
                      placeholder={t("create_kiosk_name")}
                      label={t("create_kiosk_name")}
                      required
                      value={formData.name}
                      onInput={(e) => changeFormData("name", e.target.value)}
                    />
                    <FormControl fullWidth>
                      <InputLabel>
                        {t("create_kiosk_payment_terminal_position_text")}{" "}
                      </InputLabel>
                      <Select
                        label={t("create_kiosk_clover_terminal_position_text")}
                        value={formData.terminal_position || "right"}
                        defaultValue="right"
                        onChange={(e) => {
                          changeFormData("terminal_position", e.target.value);
                        }}
                      >
                        <MenuItem value="right">
                          {t("create_kiosk_right_terminal")}{" "}
                        </MenuItem>
                        <MenuItem value="down">
                          {t("create_kiosk_down_terminal")}{" "}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <AccessControl allowedPermissions={["is_super_user"]}>
                    <TextField
                      placeholder={t("create_kiosk_cloverterminal")}
                      label={t("create_kiosk_cloverterminal")}
                      required
                      value={formData.clover_device_id}
                      onInput={(e) =>
                        changeFormData("clover_device_id", e.target.value)
                      }
                    />
                  </AccessControl>

                  <Divider />
                  <div>
                    {t("create_kiosk_quickmode")}{" "}
                    <Switch
                      checked={lightingModeData.is_active}
                      onChange={handleLightingModeToggle}
                    />
                  </div>
                  {lightingModeData.is_active ? (
                    <LoadingContainer
                      loading={loadingCampaigns}
                      error={loadingCampaignsError}
                    >
                      <div className="flex flex-1 space-x-4">
                        <div className="flex flex-1">
                          <FormControl>
                            <InputLabel required>
                              {t("create_kiosk_chooseCampaign_text")}
                            </InputLabel>
                            <Select
                              defaultValue={lightingModeData.campaign_id}
                              value={lightingModeData.campaign_id}
                              label={t("create_kiosk_chooseCampaign_text")}
                              onChange={handleCampaignSelect}
                              fullWidth
                              required
                            >
                              {campaigns.map((campaign) => (
                                <MenuItem
                                  key={campaign._id}
                                  value={campaign._id}
                                >
                                  {campaign.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>
                              {t("create_kiosk_campaign_helper_text")}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col pt-5">
                        <FormLabel>
                          {t("create_kiosk_choose_color_text")}
                        </FormLabel>
                        <ColorPicker
                          width={"100px"}
                          height={"30px"}
                          defaultColor={lightingModeData.color_code}
                          onColorChange={(color) => {
                            changeLightingModeData("color_code", color);
                          }}
                        />
                      </div>

                      <div className="flex flex-1 flex-col pt-5">
                        <FormLabel>
                          {" "}
                          {t("create_kiosk_kiosk_backgroundImage_text")}
                        </FormLabel>
                        <ImageInputV2
                          defaultImage={lightingModeData.image_url}
                          image={image}
                          onChange={handleSelectImage}
                          removeImage={removeImage}
                          removeDefaultImage={() =>
                            changeLightingModeData("image_url", "")
                          }
                          placeholder={t("create_kiosk_imageForQuickMode_text")}
                          helperText={t(
                            "create_kiosk_imageForQuickMode_helper_text"
                          )}
                        />
                      </div>

                      <div className="flex w-full flex-col pt-5">
                        <div>
                          <FormControl>
                            <FormLabel>
                              {t("create_kiosk_quickMode_type__text")}
                            </FormLabel>
                            <RadioGroup
                              row
                              value={lightingModeData.lighting_mode_type}
                              onChange={handleLightingModeType}
                            >
                              <FormControlLabel
                                value={0}
                                label={t(
                                  "create_kiosk_quickMode_temporary_type_text"
                                )}
                                control={<Radio />}
                              />
                              <FormControlLabel
                                value={1}
                                label={t(
                                  "create_kiosk_quickMode_recurrent_type_text"
                                )}
                                control={<Radio />}
                              />
                              <FormControlLabel
                                value={2}
                                label={t(
                                  "create_kiosk_quickMode_permanent_type_text"
                                )}
                                control={<Radio />}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr-ca"
                      >
                        <div className="flex flex-1">
                          {lightingModeData.lighting_mode_type == 0 ? (
                            <div className="flex flex-col space-y-4 items-center">
                              <div className="flex flex-1">
                                {t("create_kiosk_temporaryMode_guide_text")}
                              </div>
                              <div className="flex flex-1 space-x-4 items-center">
                                <DatePicker
                                  value={dayjs(lightingModeData.date_start)}
                                  required
                                  disablePast
                                  label={t("create_kiosk_start_date_text")}
                                  onChange={(value) =>
                                    changeLightingModeData("date_start", value)
                                  }
                                  slotProps={{
                                    textField: { required: true },
                                  }}
                                  maxDate={dayjs(lightingModeData.date_end)}
                                />
                                <div>-</div>
                                <DatePicker
                                  value={dayjs(lightingModeData.date_end)}
                                  disablePast
                                  label={t("create_kiosk_end_date_text")}
                                  onChange={(value) =>
                                    changeLightingModeData("date_end", value)
                                  }
                                  slotProps={{
                                    textField: { required: true },
                                  }}
                                  minDate={dayjs(lightingModeData.date_start)}
                                />
                              </div>
                              <div className="flex flex-1 space-x-4 items-center">
                                <TimePicker
                                  required
                                  label={t("create_kiosk_start_time_text")}
                                  onChange={(value) =>
                                    changeLightingModeData("time_start", value)
                                  }
                                  slotProps={{
                                    textField: { required: true },
                                  }}
                                  maxTime={dayjs(lightingModeData.time_end)}
                                  value={dayjs(lightingModeData.time_start)}
                                />{" "}
                                <div>-</div>
                                <TimePicker
                                  required
                                  label={t("create_kiosk_end_time_text")}
                                  onChange={(value) =>
                                    changeLightingModeData("time_end", value)
                                  }
                                  slotProps={{
                                    textField: { required: true },
                                  }}
                                  minTime={dayjs(lightingModeData.time_start)}
                                  value={dayjs(lightingModeData.time_end)}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {lightingModeData.lighting_mode_type == 1 ? (
                            <>
                              <div className="flex flex-1 space-x-4 w-full flex-col">
                                <div>
                                  {t(
                                    "create_kiosk_quickMode_dateandtime_helper_text"
                                  )}
                                </div>
                                <FormGroup className="w-full">
                                  <List>
                                    <ListItem>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              lightingModeData.is_sunday_selected
                                            }
                                            onChange={(e) =>
                                              changeLightingModeData(
                                                "is_sunday_selected",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label={t("create_kiosk_sunday_text")}
                                        className="w-1/6"
                                      />
                                      <div className="flex flex-1 items-center space-x-4">
                                        <TimePicker
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_start_sunday",
                                              value
                                            )
                                          }
                                          value={dayjs(
                                            lightingModeData.time_start_sunday
                                          )}
                                          maxTime={dayjs(
                                            lightingModeData.time_end_sunday
                                          )}
                                        />{" "}
                                        <div>-</div>{" "}
                                        <TimePicker
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_end_sunday",
                                              value
                                            )
                                          }
                                          value={dayjs(
                                            lightingModeData.time_end_sunday
                                          )}
                                          minTime={dayjs(
                                            lightingModeData.time_start_sunday
                                          )}
                                        />
                                      </div>
                                    </ListItem>
                                    <ListItem>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              lightingModeData.is_monday_selected
                                            }
                                            onChange={(e) =>
                                              changeLightingModeData(
                                                "is_monday_selected",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label={t("create_kiosk_monday_text")}
                                        className="w-1/6"
                                      />
                                      <div className="flex flex-1 items-center space-x-4">
                                        <TimePicker
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_start_monday",
                                              value
                                            )
                                          }
                                          value={dayjs(
                                            lightingModeData.time_start_monday
                                          )}
                                          maxTime={dayjs(
                                            lightingModeData.time_end_monday
                                          )}
                                        />{" "}
                                        <div>-</div>{" "}
                                        <TimePicker
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_end_monday",
                                              value
                                            )
                                          }
                                          value={dayjs(
                                            lightingModeData.time_end_monday
                                          )}
                                          minTime={dayjs(
                                            lightingModeData.time_start_monday
                                          )}
                                        />
                                      </div>
                                    </ListItem>
                                    <ListItem>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              lightingModeData.is_tuesday_selected
                                            }
                                            onChange={(e) =>
                                              changeLightingModeData(
                                                "is_tuesday_selected",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label={t("create_kiosk_tuesday_text")}
                                        className="w-1/6"
                                      />
                                      <div className="flex flex-1 items-center space-x-4">
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_start_tuesday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_start_tuesday",
                                              value
                                            )
                                          }
                                          maxTime={dayjs(
                                            lightingModeData.time_end_tuesday
                                          )}
                                        />{" "}
                                        <div>-</div>{" "}
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_end_tuesday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_end_tuesday",
                                              value
                                            )
                                          }
                                          minTime={dayjs(
                                            lightingModeData.time_start_tuesday
                                          )}
                                        />
                                      </div>
                                    </ListItem>
                                    <ListItem>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              lightingModeData.is_wednsday_selected
                                            }
                                            onChange={(e) =>
                                              changeLightingModeData(
                                                "is_wednsday_selected",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label={t("create_kiosk_wednesday_text")}
                                        className="w-1/6"
                                      />
                                      <div className="flex flex-1 items-center space-x-4">
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_start_wednsday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_start_wednsday",
                                              value
                                            )
                                          }
                                          maxTime={dayjs(
                                            lightingModeData.time_end_wednsday
                                          )}
                                        />{" "}
                                        <div>-</div>{" "}
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_end_wednsday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_end_wednsday",
                                              value
                                            )
                                          }
                                          minTime={dayjs(
                                            lightingModeData.time_start_wednsday
                                          )}
                                        />
                                      </div>
                                    </ListItem>
                                    <ListItem>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              lightingModeData.is_thursday_selected
                                            }
                                            onChange={(e) =>
                                              changeLightingModeData(
                                                "is_thursday_selected",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label={t("create_kiosk_thursday_text")}
                                        className="w-1/6"
                                      />
                                      <div className="flex flex-1 items-center space-x-4">
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_start_thursday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_start_thursday",
                                              value
                                            )
                                          }
                                          maxTime={dayjs(
                                            lightingModeData.time_end_thursday
                                          )}
                                        />{" "}
                                        <div>-</div>{" "}
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_end_thursday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_end_thursday",
                                              value
                                            )
                                          }
                                          minTime={dayjs(
                                            lightingModeData.time_start_thursday
                                          )}
                                        />
                                      </div>
                                    </ListItem>
                                    <ListItem>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              lightingModeData.is_friday_selected
                                            }
                                            onChange={(e) =>
                                              changeLightingModeData(
                                                "is_friday_selected",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label={t("create_kiosk_friday_text")}
                                        className="w-1/6"
                                      />
                                      <div className="flex flex-1 items-center space-x-4">
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_start_friday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_start_friday",
                                              value
                                            )
                                          }
                                          maxTime={dayjs(
                                            lightingModeData.time_end_friday
                                          )}
                                        />{" "}
                                        <div>-</div>{" "}
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_end_friday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_end_friday",
                                              value
                                            )
                                          }
                                          minTime={dayjs(
                                            lightingModeData.time_start_friday
                                          )}
                                        />
                                      </div>
                                    </ListItem>
                                    <ListItem>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              lightingModeData.is_saturday_selected
                                            }
                                            onChange={(e) =>
                                              changeLightingModeData(
                                                "is_saturday_selected",
                                                e.target.checked
                                              )
                                            }
                                          />
                                        }
                                        label={t("create_kiosk_saturday_text")}
                                        className="w-1/6"
                                      />
                                      <div className="flex flex-1 items-center space-x-4">
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_start_saturday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_start_saturday",
                                              value
                                            )
                                          }
                                          maxTime={dayjs(
                                            lightingModeData.time_end_saturday
                                          )}
                                        />{" "}
                                        <div>-</div>{" "}
                                        <TimePicker
                                          value={dayjs(
                                            lightingModeData.time_end_saturday
                                          )}
                                          onChange={(value) =>
                                            changeLightingModeData(
                                              "time_end_saturday",
                                              value
                                            )
                                          }
                                          minTime={dayjs(
                                            lightingModeData.time_start_saturday
                                          )}
                                        />
                                      </div>
                                    </ListItem>
                                  </List>
                                </FormGroup>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {lightingModeData.lighting_mode_type == 2 ? (
                            <>
                              <div className="flex flex-1">
                                {t("create_kiosk_permanentMode_guide_text")}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </LocalizationProvider>
                    </LoadingContainer>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="flex justify-end space-x-4 mt-4">
                <CancelButton disabled={submitting} label="Annuler" />
                <ButtonLoading
                  loading={submitting}
                  variant="contained"
                  type="submit"
                >
                  {t("edit_kiosk_saveButton_text")}
                </ButtonLoading>
              </div>
            </form>
          </LoadingContainer>
        ) : (
          <></>
        )}
        {tabValue === 1 ? (
          <div className="flex flex-1 flex-col">
            <div className="container-main">
              <div className="flex w-full justify-end">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr-ca"
                >
                  <DatePicker
                    value={kioskPingStatusDate}
                    required
                    label={t("edit_kiosk_chooseDate_text")}
                    onChange={(value) => changeKioskPingStatusDate(value)}
                    maxDate={dayjs()}
                  />
                </LocalizationProvider>
              </div>

              <div className="flex h-[450px] justify-center">
                <ReactECharts
                  ref={echartRef}
                  option={{}}
                  style={{ height: "100%", width: "100%" }}
                  notMerge={true}
                />
              </div>
              <div>
                {kioskPingStatusDate.isToday() ? (
                  <div className="text-gray-500">
                    {t("edit_kiosk_automatic_update_text")}
                  </div>
                ) : (
                  <></>
                )}
                {lastUpdateKioskPingStatus ? (
                  <div className="text-gray-500">
                    {t("edit_kiosk_lastUpdate_text")}{" "}
                    <span className="font-semibold">
                      {lastUpdateKioskPingStatus}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default EditKiosk;
