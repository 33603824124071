import React, { useEffect, useState, useRef, useContext } from "react";
import KioskStatusChart from "../../widgets/KioskStatusChart/KioskStatusChart";
import api_endpoints from "../../api";
import dayjs from "dayjs";
import PredefinedPeriods from "../../widgets/CustomDatePicker/PredefinedPeriod";
import { dateTimeReadable } from "../../utils";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import { Chip } from "@mui/material";
import AuthContext from "../../context/AuthContext/AuthContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const KiosksMonitoring = () => {
  const [kiosksData, setKiosksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdateKioskPingStatus, setLastUpdateKioskPingStatus] =
    useState(null);
  const intervalRef = useRef(null);
  const authContext = useContext(AuthContext);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const { t } = useCustomTranslation("Kiosk Monitoring");
  const [pageFilter, setpageFilter] = useState({
    start_date: dayjs(new Date()),
    end_date: dayjs(new Date()),
    start_time: dayjs().startOf("day").format("HH:mm"),
    end_time: dayjs().endOf("day").format("HH:mm"),
  });

  const setpersonalizedDates = (startDate, endDate, starttime, endtime) => {
    setpageFilter({
      ...pageFilter,
      start_date: startDate,
      end_date: endDate,
      start_time: starttime.format("HH:mm"),
      end_time: endtime.format("HH:mm"),
    });
  };

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setSelectedPeriod(value);
  };

  const formatDate = (dateString, timeString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day} ${timeString}`;
  };

  const fetchKiosksPingStatus = async () => {
    setLoading(true);
    try {
      const startDate = formatDate(
        pageFilter.start_date,
        pageFilter.start_time
      );
      const endDate = formatDate(pageFilter.end_date, pageFilter.end_time);
      const response = await api_endpoints.getKiosksPingStatus(
        authContext.authContext.accessToken.organization_id,
        startDate,
        endDate
      );
      if (response.status === 200) {
        setKiosksData(response.data);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const fetchKiosksPingInterval = () => {
    intervalRef.current = setInterval(() => {
      fetchKiosksPingStatus();
      setLastUpdateKioskPingStatus(dateTimeReadable(new Date()));
    }, 60 * 1000); // Fetch every 60 seconds
  };

  const removeFetchKiosksPingInterval = () => {
    setLastUpdateKioskPingStatus(null);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  const kiosksWithStatus = kiosksData.filter(
    (kiosk) => kiosk.statuses.length > 0
  );

  const kiosksWithoutStatus = kiosksData.filter(
    (kiosk) => kiosk.statuses.length === 0
  );

  useEffect(() => {
    fetchKiosksPingStatus();
  }, [pageFilter, authContext.authContext.accessToken.organization_id]);

  useEffect(() => {
    if (selectedPeriod === "today" && kiosksWithStatus.length > 0) {
      fetchKiosksPingInterval();
    } else {
      removeFetchKiosksPingInterval();
    }
    return () => removeFetchKiosksPingInterval();
  }, [selectedPeriod, kiosksWithStatus.length]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center self-end">
        <div className="mr-8">
          {lastUpdateKioskPingStatus ? (
            <div className="text-gray-500">
              {t("kiosk_monitoring_lastUpdate_text")}{" "}
              <span className="font-semibold">{lastUpdateKioskPingStatus}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="self-end mb-4" style={{ width: "300px" }}>
          {t("kiosk_monitoring_period_filter_text")}
          <PredefinedPeriods
            selectedPeriod={selectedPeriod}
            handlePeriodChange={handlePeriodChange}
            setSelectedPeriod={setSelectedPeriod}
            setpersonalizedDates={setpersonalizedDates}
          />
        </div>
      </div>
      <LoadingContainer
        loading={loading}
        message={t("kiosk_monitoring_fetchingData_text")}
        blur
      >
        {kiosksData.length === 0 && (
          <p className="text-gray-500 text-center mt-10 font-semibold">
            {t("kiosk_monitoring_noKiosk_available_text")}
          </p>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          {kiosksWithStatus.map((kiosk, index) => (
            <div
              key={index}
              className={`bg-white p-3 rounded-md shadow-md transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg mt-4 mr-4 ${
                kiosksWithStatus.length === 1 ||
                (kiosksWithStatus.length === 3 && index === 2)
                  ? "col-span-1 sm:col-span-2"
                  : ""
              }`}
            >
              <KioskStatusChart
                kioskName={kiosk.kiosk.name}
                kioskActiveStatus={
                  <Chip
                    color={kiosk.kiosk.is_active ? "success" : "error"}
                    label={
                      kiosk.kiosk.is_active
                        ? t("kiosk_monitoring_active_status_text")
                        : t("kiosk_monitoring_inActive_status_text")
                    }
                    size="small"
                  />
                }
                status={kiosk.statuses}
                showDateTime={
                  selectedPeriod === "today" || selectedPeriod === "yesterday"
                    ? false
                    : true
                }
              />
            </div>
          ))}
        </div>
        {kiosksWithStatus.length > 0 && kiosksWithoutStatus.length > 0 && (
          <hr className="my-10 border-t-4 border-gray-300" />
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          {kiosksWithoutStatus.map((kiosk, index) => (
            <div
              key={index}
              className="bg-white p-3 rounded-md shadow-md mt-4 mr-4 flex flex-col justify-between"
              style={{ height: "100px" }} // Adjust height as needed
            >
              <div className="flex items-center">
                <div className="flex-1 flex justify-center">
                  <p className="text-gray-900 font-semibold text-center">
                    {kiosk.kiosk.name}
                  </p>
                </div>
                <div className="ml-4 w-24">
                  {" "}
                  {/* Set a fixed width for Chip container */}
                  <Chip
                    color={kiosk.kiosk.is_active ? "success" : "error"}
                    label={
                      kiosk.kiosk.is_active
                        ? t("kiosk_monitoring_active_status_text")
                        : t("kiosk_monitoring_inActive_status_text")
                    }
                    size="small"
                  />
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-1 flex justify-center">
                  <p className="text-gray-500 mt-2 text-center">
                    {t("kiosk_monitoring_noData_available_text")}
                  </p>
                </div>
                <div className="ml-4 w-24"></div>{" "}
                {/* Match the width to the Chip container above */}
              </div>
            </div>
          ))}
        </div>
      </LoadingContainer>
    </div>
  );
};

export default KiosksMonitoring;
