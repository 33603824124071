import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import api_endpoints from "../../api";
import PredefinedPeriods from "../../widgets/CustomDatePicker/PredefinedPeriod";
import DataGrid from "../../widgets/DataGrid/DataGrid";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import {
  Divider,
  Select,
  MenuItem,
  useTheme,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  useApiClientQuery,
  useApiClientMutation,
} from "../../hooks/useApiClient";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AuthContext from "../../context/AuthContext/AuthContext";
import {
  dateTimeReadable,
  numberWithCommas,
  dateTimeReadableSimple,
  formatDateTime,
} from "../../utils";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableVirtuoso from "../../widgets/TableVirtuoso/TableVirtuoso";
import CustomTextField from "../../widgets/CustomTextField/CustomTextField";
import CustomSelect from "../../widgets/CustomSelect/CustomSelect";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const Donor = () => {
  const authContext = useContext(AuthContext);
  const params = useParams();
  const theme = useTheme();
  const [addCashDialogOpen, setAddCashDialogOpen] = useState(false);
  const [campaignsMenuOption, setCampaignsMenuOption] = useState([]);
  const [activeDonorCampaignsMenuOption, setActiveDonorCampaignsMenuOption] =
    useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tabValue, setTabValue] = useState(0);
  const { t } = useCustomTranslation("Donor Profile");

  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  const [tableFilter, setTableFilter] = useState({
    organization_id: authContext.authContext.accessToken.organization_id,
    amount: "",
    campaignId: "",
    date: dayjs(new Date()),
    start_date: dayjs(new Date()),
    end_date: dayjs(new Date()),
    start_time: dayjs().startOf("day").format("HH:mm"),
    end_time: dayjs().endOf("day").format("HH:mm"),
  });

  const [addCashData, setAddCashData] = useState({
    amount: "",
    campaignId: "",
    donationDate: dayjs(new Date()),
  });

  const columns = [
    {
      field: "campaignName",
      headerName: t("donor_profile_transactions_table_campaign"),
      sortable: true,
      numberSort: false,
    },
    {
      field: "amount",
      headerName: t("donor_profile_transactions_table_amount"),
      sortable: true,
      numberSort: true,
    },
    {
      field: "date",
      headerName: t("donor_profile_transactions_table_period"),
      sortable: true,
      numberSort: false,
    },
  ];

  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setSelectedPeriod(value);
  };

  const setpersonalizedDates = (startDate, endDate, starttime, endtime) => {
    setTableFilter({
      ...tableFilter,
      start_date: startDate,
      end_date: endDate,
      start_time: starttime.format("HH:mm"),
      end_time: endtime.format("HH:mm"),
    });
  };

  const filters = {
    amount: (
      <CustomTextField
        value={tableFilter.amount || ""}
        onChange={(e) => changeTableFilter("amount", e.target.value)}
        clearFilter={() => clearTableFilter("amount")}
      />
    ),
    campaignName: (
      <CustomSelect
        value={tableFilter.campaignId}
        onChange={(e) => {
          changeTableFilter("campaignId", e.target.value);
        }}
        options={campaignsMenuOption}
      />
    ),
    date: (
      <PredefinedPeriods
        selectedPeriod={selectedPeriod}
        handlePeriodChange={handlePeriodChange}
        setSelectedPeriod={setSelectedPeriod}
        setpersonalizedDates={setpersonalizedDates}
      />
    ),
  };

  useEffect(() => {
    createYearOptionsReceipt();
  }, []);

  const changeTableFilter = (option, value) => {
    if (option === "amount") {
      // Allow only numbers and ignore any non-numeric input
      const numericValue = value.replace(/[^0-9]/g, "");

      setTableFilter((prevFilter) => ({
        ...prevFilter,
        [option]: numericValue,
      }));
    } else {
      // For other options, set the value as it is
      setTableFilter((prevFilter) => ({
        ...prevFilter,
        [option]: value,
      }));
    }
  };

  const clearTableFilter = (option) => {
    setTableFilter((prevFilter) => ({ ...prevFilter, [option]: "" }));
  };

  const changeAddCashData = (option, value) => {
    if (option === "amount") {
      const numericValue = value
        .replace(/[^0-9.]/g, "") // Allow only numbers and a decimal point
        .replace(/(\..*?)\..*/g, "$1") // Ensure only one decimal point
        .replace(/(\.\d{2})\d+/, "$1"); // Limit to 2 decimal places

      setAddCashData((prevFilter) => ({
        ...prevFilter,
        [option]: numericValue,
      }));
    } else {
      // For other options, set the value as it is
      setAddCashData((prevFilter) => ({
        ...prevFilter,
        [option]: value,
      }));
    }
  };

  const donorQuery = useApiClientQuery(
    () => api_endpoints.getDonor(params.email),
    {
      queryKey: ["donor_data", params.email],
    }
  );

  const donorLastReceiptStatusQuery = useApiClientQuery(
    () =>
      api_endpoints.getDonorLastReceiptStatus(
        donorQuery.data._id,
        authContext.authContext.accessToken.organization_id,
        selectedYear
      ),
    {
      queryKey: [
        "donor_last_receipt_status",
        donorQuery.data?._id,
        selectedYear,
      ],
      enabled: !!donorQuery.data && !!selectedYear, // Only runs when donor data is available and a year is selected
    }
  );

  const lastCurrentYearReceiptStatus = donorLastReceiptStatusQuery.data?.find(
    (receipt) => selectedYear === receipt.year
  );

  const lastReceiptStatus = donorLastReceiptStatusQuery.data?.map(
    (receipt) => ({
      year: receipt.year,
      status: (
        <div
          key={receipt.year}
          style={{
            color: receipt.success
              ? theme.palette.success.main
              : theme.palette.error.main,
            fontWeight: 600,
          }}
        >
          {receipt.success
            ? t("donor_profile_receipt_send_status")
            : t("donor_profile_receipt_failed_send_status")}
        </div>
      ),
    })
  );

  const donorActiveCampaignsQuery = useApiClientQuery(
    () =>
      api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id,
        "",
        "",
        "",
        true,
        "",
        false,
        false
      ),
    {
      queryKey: [
        `donor_activeCampaign_organizationid_donorEmail${tableFilter.organization_id}_${params.email}`,
      ],
      enabled: addCashDialogOpen,
    },
    (data) => {
      const options = data.map((campaign) => ({
        value: campaign._id,
        label: (
          <div
            key={campaign._id}
            className="flex flex-1 items-center justify-between"
          >
            <span>{campaign.name}</span>
            <div>
              <Chip
                color={campaign.enabled ? "success" : "error"}
                label={
                  campaign.enabled
                    ? t("donor_profile_active_status")
                    : t("donor_profile_inActive_status")
                }
                size="small"
              />
            </div>
          </div>
        ),
      }));

      // Set addCashData.campaignId to the first option if not already set
      if (!addCashData.campaignId && options.length > 0) {
        changeAddCashData("campaignId", options[0].value);
      }

      // Update state
      setActiveDonorCampaignsMenuOption(options);
    }
  );

  const campaignFilterQuery = useApiClientQuery(
    () =>
      api_endpoints.getCampaigns(
        authContext.authContext.accessToken.organization_id
      ),
    {
      queryKey: [
        `donor_campaignFilter_organizationid_${tableFilter.organization_id}`,
      ],
    },
    (data) => {
      const options = data.map((campaign) => ({
        value: campaign._id,
        label: (
          <div
            key={campaign._id}
            className="flex flex-1 items-center justify-between"
          >
            <span>{campaign.name}</span>
            <div>
              <Chip
                color={campaign.enabled ? "success" : "error"}
                label={
                  campaign.enabled
                    ? t("donor_profile_active_status")
                    : t("donor_profile_inActive_status")
                }
                size="small"
              />
            </div>
          </div>
        ),
      }));

      // Add "Tout" option at the beginning
      options.unshift({ value: "", label: t("donor_profile_all_option") });

      // Update state
      setCampaignsMenuOption(options);
    }
  );

  const transactionsQuery = useApiClientQuery(
    () =>
      api_endpoints.getDonorTransactions(
        authContext.authContext.accessToken.organization_id,
        params.email,
        selectedYear,
        tableFilter.amount,
        tableFilter.campaignId,
        formatDateTime(tableFilter.start_date, tableFilter.start_time),
        formatDateTime(tableFilter.end_date, tableFilter.end_time)
      ),
    {
      queryKey: [
        "donor_transactions",
        tableFilter,
        selectedYear,
        authContext.authContext.accessToken.organization_id,
      ],
    }
  );

  const generateReceiptMutation = useApiClientMutation(
    api_endpoints.generateReceipt,
    [`donor_last_receipt_status`],
    (data) => {
      setMessageSuccessAlert(t(data));
      setShowSuccessAlert(true);
      donorLastReceiptStatusQuery.refetch();
    }
  );

  const addDonorCashMutation = useApiClientMutation(
    api_endpoints.addCashToCampaign,
    [`donor_transactions`],
    (data) => {
      setMessageSuccessAlert(data);
      setShowSuccessAlert(true);
      transactionsQuery.refetch();
      handleCloseModal();
    }
  );

  const handleAddCashToCampaign = async (e) => {
    e.preventDefault();
    addDonorCashMutation.mutate({
      campaign_id: addCashData.campaignId,
      cash_amount: addCashData.amount,
      inserted_by: authContext.authContext.accessToken.id,
      organization_id: authContext.authContext.accessToken.organization_id,
      date_donated: formatDateTime(addCashData.donationDate),
      donor_id: donorQuery.data._id,
    });
  };

  const generateReceipt = async () => {
    const receiptData = {
      donor_id: donorQuery.data._id,
      organization_id: authContext.authContext.accessToken.organization_id,
      year: selectedYear,
    };
    generateReceiptMutation.mutate(receiptData);
  };

  const createYearOptionsReceipt = () => {
    let data = [];
    const minYear = 2024;
    let yearOptions = Number(new Date().getFullYear());
    const iterations = yearOptions - minYear;

    for (let i = 0; i <= iterations; i++) {
      data.push(yearOptions--);
    }

    setYearOptions(data);
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseModal = () => {
    setAddCashDialogOpen(false);

    setAddCashData({
      amount: "",
      campaignId: "",
      donationDate: dayjs(new Date()),
    });
    addDonorCashMutation.reset(); //rest error state, if any
  };

  return (
    <LoadingContainer loading={donorQuery.isLoading} error={donorQuery.isError}>
      <Dialog fullWidth open={addCashDialogOpen} onClose={handleCloseModal}>
        <form onSubmit={handleAddCashToCampaign}>
          <DialogTitle>{t("donor_profile_addCash_button")}</DialogTitle>
          <LoadingContainer
            loading={
              addDonorCashMutation.isPending ||
              donorActiveCampaignsQuery.isLoading
            }
            error={
              addDonorCashMutation.isError || donorActiveCampaignsQuery.isError
            }
          >
            <DialogContent>
              <div className="m-2 flex flex-1 flex-col space-y-4">
                <CustomSelect
                  value={addCashData.campaignId}
                  onChange={(e) => {
                    changeAddCashData("campaignId", e.target.value);
                  }}
                  label={"Campaigns"}
                  options={activeDonorCampaignsMenuOption}
                  required={true}
                />
                <TextField
                  placeholder={t("donor_profile_insertAmount_text")}
                  label={t("donor_profile_insertAmount_text")}
                  type="text"
                  value={addCashData.amount}
                  onChange={(e) => {
                    changeAddCashData("amount", e.target.value);
                  }}
                  required
                />
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr-ca"
                >
                  <DatePicker
                    value={addCashData.donationDate}
                    onChange={(value) => {
                      changeAddCashData("donationDate", value.$d.getTime());
                    }}
                    label={t("donor_profile_donationDate_text")}
                    sx={{ width: "100%" }}
                    maxDate={dayjs(new Date())}
                    slotProps={{
                      textField: {
                        error: false,
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </DialogContent>
          </LoadingContainer>
          <DialogActions>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseModal} // Correctly close the modal
            >
              {t("donor_profile_cancel_button_text")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={addDonorCashMutation.isPending}
            >
              {t("donor_profile_add_button_text")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <div className="container-main space-y-4">
        <div className="flex flex-1 justify-between">
          {donorQuery.data && (
            <div className="[&>div]:space-x-2 [&>div]:text-xl space-y-1">
              <div className="!text-2xl font-semibold">
                <EmailOutlinedIcon />
                <label>{donorQuery.data.email}</label>
              </div>

              {[
                {
                  icon: <PersonOutlinedIcon />,
                  label:
                    donorQuery.data.first_name && donorQuery.data.last_name
                      ? `${donorQuery.data.first_name} ${donorQuery.data.last_name}`
                      : null,
                },
                { icon: <PhoneOutlinedIcon />, label: donorQuery.data.phone },
                { icon: <HomeOutlinedIcon />, label: donorQuery.data.adresse },
                {
                  icon: <WorkOutlineOutlinedIcon />,
                  label: donorQuery.data.company,
                },
              ].map(
                ({ icon, label }, index) =>
                  label && (
                    <div key={index} className="text-xl">
                      {icon}
                      <label>{label}</label>
                    </div>
                  )
              )}
            </div>
          )}

          <div className="flex flex-col space-y-4">
            <div className="flex space-x-4 w-full justify-end">
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                size="small"
              >
                {yearOptions.map((year, index) => (
                  <MenuItem value={year} key={index}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              <ButtonLoading
                loading={generateReceiptMutation.isPending}
                variant="contained"
                onClick={generateReceipt}
              >
                {t("donor_profile_generateReceipt_text")}
              </ButtonLoading>
            </div>
            {lastCurrentYearReceiptStatus?.created_at ? (
              <div className="w-[250px] items-end">
                {t("donor_profile_generatedOn_text")}{" "}
                <span className="font-semibold">
                  {dateTimeReadable(
                    new Date(lastCurrentYearReceiptStatus?.created_at)
                  )}
                </span>{" "}
                {t("donor_profile_with_text")}{" "}
                <span
                  className="font-semibold"
                  style={{
                    color: lastCurrentYearReceiptStatus.success
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  }}
                >
                  {lastCurrentYearReceiptStatus.success
                    ? t("donor_profile_success_text")
                    : t("donor_profile_error_text")}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Divider />
        <div>
          <LoadingContainer>
            <div className={"flex flex-col flex-1"}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={t("donor_profile_donationHistory_text")} />
                <Tab label={t("donor_profile_listOfReceipts_text")} />
              </Tabs>
              {tabValue === 0 ? (
                <>
                  <div className="flex flex-1 h-full flex-col space-y-4">
                    <div className={"flex justify-end"}>
                      <Tooltip title={""} arrow>
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setAddCashDialogOpen(true);
                            }}
                            //disabled={rows.length === 0}
                          >
                            {t("donor_profile_addCash_button")}{" "}
                          </Button>
                        </span>
                      </Tooltip>
                    </div>
                    <DataGrid
                      columns={columns}
                      rows={
                        transactionsQuery.data
                          ? transactionsQuery.data.map((transaction) => ({
                              id: transaction._id,
                              amount: `${numberWithCommas(
                                transaction.amount
                              )}$`,
                              campaignName: transaction.campaign?.name,
                              date: dateTimeReadableSimple(
                                transaction.transactionDate
                              ),
                            }))
                          : []
                      }
                      filters={filters}
                      loading={
                        transactionsQuery.isLoading ||
                        campaignFilterQuery.isLoading
                      }
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {tabValue === 1 ? (
                <>
                  <div className="flex flex-1 h-screen mt-4">
                    <TableVirtuoso
                      rows={lastReceiptStatus}
                      columns={[
                        { key: "year", label: t("donor_profile_year_text") },
                        {
                          key: "status",
                          label: t("donor_profile_status_text"),
                        },
                      ]}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </LoadingContainer>
        </div>
      </div>
    </LoadingContainer>
  );
};

export default Donor;
