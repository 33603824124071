import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import AuthContext from "../../context/AuthContext/AuthContext";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { useApiClientMutation } from "../../hooks/useApiClient";
import api_endpoints from "../../api";
import { NumericFormat } from "react-number-format";
import { useSuccessAlert } from "../../context/SuccessAlertContext/SuccessAlertContext";
import { STATUS_TYPE } from "../../enums/PromiseEnums";

const EditPromise = ({ onClose, promise_id, status, promise_amount }) => {
  const { t } = useCustomTranslation("Promise Details");
  const authContext = useContext(AuthContext);
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    messageSuccessAlert,
    setMessageSuccessAlert,
  } = useSuccessAlert();

  const [formData, setFormData] = useState({
    status: status ?? "",
    promise_amount: promise_amount ?? "",
  });

  const [errors, setErrors] = useState({
    promise_amount: false,
  });

  const handleSuccessfulMutation = (successMessage) => {
    setMessageSuccessAlert(successMessage);
    setShowSuccessAlert(true);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "promise_amount") {
      setErrors((prevErrors) => ({ ...prevErrors, promise_amount: false }));
    }

    setFormData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const editPromiseMutation = useApiClientMutation(
    api_endpoints.editPromise,
    ["edit_Promise"],
    (data) => {
      handleSuccessfulMutation(data);
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.promise_amount) {
      setErrors((prevErrors) => ({ ...prevErrors, promise_amount: true }));
      return;
    }

    const requestData = {
      campaign_promise_id: promise_id,
      promise_amount:
        formData.promise_amount.toString().replace(/[^0-9.]/g, "") ?? 0,
      status: formData.status,
    };

    editPromiseMutation.mutate(requestData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-4 my-4">
        <NumericFormat
          fullWidth
          value={formData.promise_amount}
          onChange={handleChange}
          customInput={TextField}
          thousandSeparator
          valueIsNumericString
          name="promise_amount"
          prefix="$ "
          label={t("promised_amount")}
          decimalScale={2} // Limits to 2 decimal places
          error={errors.promise_amount} // Show error state
          helperText={errors.promise_amount ? "Promise Amount is Required" : ""}
        />
        <FormControl fullWidth required>
          <InputLabel>{t("status")}</InputLabel>
          <Select
            name="status"
            value={formData.status}
            onChange={handleChange}
            label={t("status")}
          >
            <MenuItem value={STATUS_TYPE.IN_PROGRESS}>
              {t("in_progress")}
            </MenuItem>
            <MenuItem value={STATUS_TYPE.ABANDONED}>{t("abandoned")}</MenuItem>
            <MenuItem value={STATUS_TYPE.CLOSED}>{t("closed")}</MenuItem>
            <MenuItem value={STATUS_TYPE.OPEN}>{t("open")}</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <Button color="error" variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button variant="contained" type="submit">
          {t("save")}
        </Button>
      </div>
    </form>
  );
};

export default EditPromise;
