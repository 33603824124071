import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext/AuthContext";
import { useLocation } from "react-router-dom";
import { PAGES } from "../../route_utils";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import api_endpoints from "../../api";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingContainer from "../LoadingContainer/LoadingContainer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Modal from "@mui/material/Modal";
import { Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccessControl from "../../widgets/AccessControl/AccessControl";
import DownloadIcon from "@mui/icons-material/Download";
import ButtonLoading from "../../widgets/ButtonLoading/ButtonLoading";
import { decode } from "html-entities";
import CollapsibleTree from "../../widgets/CollapsibleTree/CollapsibleTree";
import HiddenViewSwitch from "../../widgets/HiddenViewSwitch/HiddenViewSwitch";
import { useHiddenView } from "../../context/HiddenViewContext/HiddenViewContext";
import HiddenViewContainer from "../../widgets/HiddenViewContainer/HiddenViewContainer";
import { LocaleSelect } from "../../widgets/LocaleSelect/LocaleSelect";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";

const drawerWidth = 220;
const appBarHeight = 50;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    flex: 1,
    top: appBarHeight,
    position: "relative",
    height: "100vh",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    backgroundColor: "transparent",
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    height: appBarHeight,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  height: appBarHeight,
  display: "flex",
  justifyContent: "center",
  backgroundColor: "white",
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
}));

const Header = (props) => {
  const theme = useTheme();
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [superUserCode, setSuperUserCode] = useState(false);
  const [loadingCode, setLoadingCode] = useState(true);
  const [loadingCodeError, setLoadingCodeError] = useState(false);
  const [showSuperUserCode, setShowSuperUserCode] = useState(false);
  const [organisationFilterField, setOrganisationFilterField] = useState("");
  const [modalOrgSelectOpen, setModalOrgSelectOpen] = useState(false);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);
  const [loadingOrganizationsError, setLoadingOrganizationsError] =
    useState(false);
  const [organisations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [kioskAppVersionNumber, setKioskAppVersionNumber] = useState("");
  const [downloadingKioskAppFiles, setDownloadingKioskAppFiles] =
    useState(false);
  const hiddenView = useHiddenView();
  const { t } = useCustomTranslation("Sidebar");

  useEffect(() => {
    if (authContext.authContext.accessToken.is_super_user) {
      fetchSuperUserCode();
      fetchKioskAppVersion();
    }
  }, []);

  useEffect(() => {
    searchRouteLabel();
  }, [location]);

  useEffect(() => {
    if (authContext.authContext.accessToken.is_super_user) fetchOrgnizations();
  }, [organisationFilterField]);

  useEffect(() => {
    if (modalOrgSelectOpen) fetchOrgnizations();
  }, [modalOrgSelectOpen]);

  const fetchSuperUserCode = async () => {
    setLoadingCode(true);
    let response = await api_endpoints.getSuperUserCode();

    if (response.status === 200) {
      setSuperUserCode(response.data.code);
      setLoadingCode(false);
    } else {
      setLoadingCodeError(true);
    }
  };

  const fetchOrgnizations = async () => {
    setLoadingOrganizations(true);
    let response = await api_endpoints.getOrganizations(
      organisationFilterField
    );

    if (response.status === 200) {
      setOrganizations(response.data);
      setLoadingOrganizations(false);
    } else {
      setLoadingOrganizationsError(true);
    }
  };

  const fetchKioskAppVersion = async () => {
    try {
      const response = await api_endpoints.getKioskAppVersion("production");

      if (response.status === 200) setKioskAppVersionNumber(response.data);
    } catch (e) {}
  };

  const searchRouteLabel = () => {
    for (const [key, value] of Object.entries(PAGES)) {
      if (value.path === location.pathname) {
        setTitle(value.label);
        break;
      }
    }
  };

  const toggleSuperUserCodeShow = () => {
    setShowSuperUserCode(!showSuperUserCode);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    navigate("/");
    authContext.authContext.logoutUser();
  };

  const handleSetModalOrgSelectOpen = () => {
    setModalOrgSelectOpen(true);
  };

  const handleSetModalOrgSelectClose = () => {
    setModalOrgSelectOpen(false);
  };

  const selectOrganisation = (organisation_id, organization_name) => {
    authContext.authContext.setSuperUserOrganization(organisation_id);
    setSelectedOrganization(organization_name);
    handleSetModalOrgSelectClose();
  };

  const downloadKioskAppFiles = async () => {
    setDownloadingKioskAppFiles(true);
    try {
      const response = await api_endpoints.getKioskAppLink("production");

      if (response.status === 200) {
        const url = response.data;
        const temporaryLink = document.createElement("a");
        temporaryLink.href = url;
        temporaryLink.download = url.split("/").pop();
        document.body.appendChild(temporaryLink);
        temporaryLink.click();
        document.body.removeChild(temporaryLink);
      }
    } catch (e) {}

    setDownloadingKioskAppFiles(false);
  };

  return (
    <div className="flex">
      <Modal
        onClose={handleSetModalOrgSelectClose}
        open={modalOrgSelectOpen}
        className="flex flex-1 justify-center"
        style={{ backdropFilter: "blur(5px)" }}
      >
        <div className="flex w-1/2 h-5/6 bg-white mt-10 rounded-xl p-3 justify-start flex-col">
          <div className="flex w-full items-center border-b">
            <SearchIcon fontSize="large" />
            <TextField
              fullWidth
              autoFocus
              value={organisationFilterField}
              onChange={(e) => setOrganisationFilterField(e.target.value)}
              sx={{
                "& fieldset": { border: "none" },
              }}
              variant="outlined"
              placeholder={t("org_choice")}
              inputProps={{
                style: { fontSize: 28 },
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </div>
          <div className="flex flex-1 overflow-y-auto">
            <LoadingContainer
              loading={loadingOrganizations}
              error={loadingOrganizationsError}
            >
              <List className="flex flex-1 flex-col mt-2 space-y-3">
                {organisations.map((organisation) => (
                  <ListItemButton
                    onClick={() =>
                      selectOrganisation(
                        organisation._id,
                        organisation.legal_name
                      )
                    }
                    className="hover:bg-black"
                    key={organisation._id}
                    sx={{
                      m: 0,
                      p: 2,
                      minWidth: 0,
                      flexGrow: 0,
                      backgroundColor: "rgba(26,57,60, 0.05)",
                      border: `2px solid lightgray`,
                      borderRadius: 2,
                      "&:hover": {
                        border: `2px solid ${theme.palette.primary.main}`,
                      },
                    }}
                  >
                    <div className="flex [&>*]:cursor-pointer flex-1">
                      <div className="[&>*]:cursor-pointer flex flex-col flex-1">
                        <label className="text-lg">
                          {organisation.legal_name}
                        </label>
                        <label className="text-gray-500 text-sm">
                          {organisation.owner_name}
                        </label>
                        <label className="text-gray-500 text-sm">
                          {organisation.address}
                        </label>
                        <label className="text-gray-500 text-sm">
                          {organisation.phone}
                        </label>
                        <label className="text-gray-500 text-sm">
                          {organisation.federal_tax_number}
                        </label>
                      </div>
                      <div className="[&>*]:cursor-pointer flex flex-col flex-1 justify-end items-end [&>*]:text-sm text-gray-500">
                        <label>{organisation.owner?.first_name}</label>
                        <label>{organisation.owner?.last_name}</label>
                        <label>{organisation.owner?.email}</label>
                      </div>
                    </div>
                  </ListItemButton>
                ))}
              </List>
            </LoadingContainer>
          </div>
        </div>
      </Modal>
      <AppBar open={open}>
        <Toolbar>
          <div className="h-[90%] w-[150px] ml-4 box-border flex justify-center items-center">
            <object
              className="w-full box-content object-cover"
              data={process.env.PUBLIC_URL + "/Kiosk-Donations.svg"}
            ></object>
          </div>
          <div className="ml-auto flex space-x-4 !text-black items-center">
            {authContext.authContext.accessToken.is_super_user && (
              <>
                <HiddenViewSwitch />
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderRightWidth: 2,
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
              </>
            )}
            <AccessControl allowedPermissions={["is_super_user"]}>
              <HiddenViewContainer show={!hiddenView.hidden}>
                <>
                  <div className={"ml-5"}>
                    <ButtonLoading
                      loading={downloadingKioskAppFiles}
                      color="secondary"
                      sx={{
                        borderRadius: "50px",
                      }}
                      onClick={downloadKioskAppFiles}
                      variant="contained"
                    >
                      <div className="flex items-center">
                        <DownloadIcon className={"mr-2"} />
                        App Kiosk
                        <div className="text-[12px] ml-2">
                          {kioskAppVersionNumber
                            ? `(${kioskAppVersionNumber})`
                            : ""}
                        </div>
                      </div>
                    </ButtonLoading>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderRightWidth: 2,
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </>
              </HiddenViewContainer>
            </AccessControl>
            <LocaleSelect />
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderRightWidth: 2,
                marginTop: "10px",
                marginBottom: "10px",
              }}
            />
            <Button
              id="basic-button"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleMenuClick}
              sx={{ color: "black" }}
            >
              {`${authContext.authContext.accessToken?.last_name} ${authContext.authContext.accessToken?.first_name}`}
              <ExpandMoreIcon className="mr-2" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {/* <MenuItem
                onClick={() => handleDrawerNavigation(PAGES.settings.path)}
              >
                <SettingsIcon className="mr-2" />
                Paramètres
              </MenuItem> */}
              <Divider />
              <MenuItem
                onClick={handleLogOut}
                sx={{ color: theme.palette.error.main }}
              >
                <LogoutIcon className="mr-2" />
                Déconnexion
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            top: appBarHeight,
            width: drawerWidth,
            boxSizing: "border-box",
            border: 0,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <AccessControl allowedPermissions={["is_super_user"]}>
          <HiddenViewContainer show={!hiddenView.hidden}>
            <div className="flex flex-col w-full mt-6">
              <label className="ml-2 mt-2 text-center">{t("super_code")}</label>
              <div className="flex items-center justify-center">
                <LoadingContainer
                  loading={loadingCode}
                  error={loadingCodeError}
                >
                  <label className="text-3xl">
                    {showSuperUserCode ? superUserCode : "********"}
                  </label>
                  <IconButton onClick={toggleSuperUserCodeShow} edge="end">
                    {!showSuperUserCode ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </LoadingContainer>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-center p-4">
                <Button
                  onClick={handleSetModalOrgSelectOpen}
                  variant="contained"
                  color="secondary"
                  sx={{ fontSize: 12 }}
                >
                  {selectedOrganization
                    ? selectedOrganization
                    : t("org_choice")}
                  <ChevronRightIcon />
                </Button>
              </div>
            </div>
          </HiddenViewContainer>
        </AccessControl>
        <List className={"space-y-1 text-[14px]"}>
          {Object.keys(PAGES).map((category, index) => {
            // checks permission for root page first. root page super_user property supersedes the individual pages
            // super_user properties. meaining if the category is super user only, all inner pages will not be available
            // for super users only
            if (
              PAGES[category].super_user &&
              !authContext.authContext.accessToken.is_super_user
            )
              return;

            return (
              <CollapsibleTree
                key={PAGES[category] + index}
                pageRoot={PAGES[category]}
              />
            );
          })}
        </List>
      </Drawer>
      <Main open={open}>{props.children}</Main>
    </div>
  );
};

export default Header;
